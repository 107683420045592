import React from 'react'
import { Container, Row, Col, Image, Card, Form } from "react-bootstrap";
import HelloText from "../../assets/images/hello-text.svg";

function AddSupportUserCard (props) {
    const {userName,userEmail,brand, variant,inputValue,buttonClick, ...rest } = props
    return (
        <div>
            <Card className={`story-card add-support-card ${variant}-cards`}>
              <Card.Body className="p-1">                  
                
                <label>
                    <span className="float-left">
                        <input type="radio" name="su_id"  {...rest} className="card-input-element" />
                    </span>
                    <span className="float-right">{brand}</span>
                    <span className="panel panel-default card-input">
                        {/* <p className="panel-heading card-right">{last4digit}</p> */}
                        <p className="panel-heading card-right my-0"><strong>{userName}</strong></p>
                        <p className="panel-body card-right"><small>{userEmail}</small></p>
                    </span>
                </label>                    
               
              </Card.Body>
            </Card>           
        </div>
    )
}
export default AddSupportUserCard