import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Container,
  Dropdown,
  Nav,
  Navbar,
  Image,
  Offcanvas,
  Modal,
  Form,
  Button,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import AuthService from "../../services/auth.service";
import UserService from "../../services/user.service";
import Logo from "../../assets/images/logo.svg";
import Avatar from "../../assets/images/avatar.svg";
import ToastBlockContainer from "../../common/ToastBlockContainer";

const NavBar = (props) => {
  var splitUrl = window.location.href.split("/");
  var parentUserUUId = localStorage.getItem("parentUser.uuid");
  var navbarClass = props.isOpen ? "open pb-0" : "pb-0";
  let history = useHistory();
  const [showFamilyNav, setShowFamilyNav] = useState(false);
  let userType = localStorage.getItem("userType");
  const [familyName, setFamilyName] = useState("");
  var RedFamDash = localStorage.getItem("RedFamDash");
  const [familyDetails, setFamilyDetails] = useState({
    FamilyId: "",
    UserId: "",
    createdAt: "",
    id: "",
    inviteStatus: "",
    invitedBy: "",
    isAo: "",
    isAu: userType ? userType.includes("AU") : false,
    isSu: true,
    settings1: "",
    settings2: "",
    settings3: "",
    status: "",
    updatedAt: "",
  });

  const isFamilyDashboardActive = window.location.href.includes("/dashboard");
  const isSupportingDashboardActive = window.location.href.includes(
    "/supporting_user_dashboard"
  );
  const [isSubsActive, setIsSubsActive] = useState("");
  const [isTasksActive, setIsTasksActive] = useState("");
  const [isBenefitsActive, setIsBenefitsActive] = useState("");
  const [isGiftCardsActive, setIsGiftCardsActive] = useState("");
  const [isWarrantyActive, setIsWarrantyActive] = useState("");
  const [isCompareActive, setIsCompareActive] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  let items = localStorage.getItem("UserDetails");
  let uuidDetails = localStorage.getItem("uuid");
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));

  const [uservalue, setUserValue] = useState({});
  const [familyLengthVal, setfamilyLengthVal] = useState([]);

  const initialFmlyNameValues = {
    family_name: "",
  };
  const [updateFmlyFormValues, setUpdateFmlyFormValues] = useState(
    initialFmlyNameValues
  );

  const handleUpdateFmlyChange = (e) => {
    const { name, value } = e.target;
    setUpdateFmlyFormValues({ ...updateFmlyFormValues, [name]: value });
  };

  const handleeventlog = () => {
    history.push(uuidDetails ? `/family_notification/${splitUrl[4]}` : "#");
  };

  const handleSwitchGroup = () => {
    history.push("/family_selection");
  };

  const handleInvites = () => {
    // if (splitUrl[4]) {
    //   history.push(`/myInvites/${uuidDetails}`);
    // } else {
    //   history.push(`/myInvites/not-found`);
    // }
    history.push(`/myInvites`);
  };

  const handleLogout = () => {
    AuthService.logout();
    history.push("/");
  };

  const handleChangePlan = () => {
    if (splitUrl[4] !== undefined) {
      history.push(`/manageSubscription/${splitUrl[4]}`);
    } else {
      history.push(`/manageSubscription/${uuidDetails}`);
    }
  };

  useEffect(() => {
    UserService.getUserAccountPlan(UserDetails.uuid).then((resp) => {
      let newData = [];
      for (let i = 0; i < resp.usersFamilyData.length; i++) {
        if (resp.usersFamilyData[i].Family.status !== "InActive") {
          newData.push(resp.usersFamilyData[i]);
        }
      }
      setfamilyLengthVal(newData);
      if (newData.length !== 0) {
        setShowFamilyNav(true);
      }
    });
    if (splitUrl[4]) {
      UserService.getUserSingleFamilyDetails(splitUrl[4], UserDetails.id).then(
        (response) => {
          if (response.FamilyData) {
            setUserValue(response.FamilyData);
          }
          const initialFmlyNameValues = {
            family_name:
              response.FamilyData != null
                ? response.FamilyData.Family.name
                : "",
          };
          setUpdateFmlyFormValues(initialFmlyNameValues);
        }
      );
    }
  }, []);

  if (!items) {
    history.push("/");
  }
  let { uuid } = useParams();
  useEffect(() => {
    if (uuid) {
      if (uuid.includes("elf-")) {
        setShowFamilyNav(true);

        // get family details
        UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
          (response) => {
            if (response.message && response.message === "Family not Found !") {
              window.location.href = "/404";
            } else if (response.FamilyData === null) {
              window.location.href = "/404";
            } else {
              setFamilyDetails(response.FamilyData);
              setFamilyName(response.FamilyData.Family.name);
            }
          },
          (error) => {
            window.location.href = "/404";
          }
        );
      }
    }

    if (window.location.href.indexOf("warranties") > -1) {
      setIsWarrantyActive("warranties");
    } else if (window.location.href.indexOf("view_warranty_details") > -1) {
      setIsWarrantyActive("warranties");
    } else if (window.location.href.indexOf("add_warranty") > -1) {
      setIsWarrantyActive("warranties");
    } else if (window.location.href.indexOf("edit_warranty") > -1) {
      setIsWarrantyActive("warranties");
    }

    if (window.location.href.indexOf("discounts") > -1) {
      setIsBenefitsActive("discounts");
    }

    if (window.location.href.indexOf("compare_and_save") > -1) {
      setIsCompareActive("CompareAndSave");
    }

    if (window.location.href.indexOf("myoutgoings") > -1) {
      setIsSubsActive("myoutgoings");
    } else if (window.location.href.indexOf("view_outgoing_details") > -1) {
      setIsSubsActive("myoutgoings");
    } else if (window.location.href.indexOf("add_outgoing") > -1) {
      setIsSubsActive("myoutgoings");
    } else if (window.location.href.indexOf("edit_outgoing") > -1) {
      setIsSubsActive("myoutgoings");
    }

    // Gift cards
    if (window.location.href.indexOf("gift_cards") > -1) {
      setIsGiftCardsActive("GiftCards");
    }
    if (window.location.href.indexOf("add_gift_card") > -1) {
      setIsGiftCardsActive("GiftCards");
    }
    if (window.location.href.indexOf("edit_gift_card") > -1) {
      setIsGiftCardsActive("GiftCards");
    }
    if (window.location.href.indexOf("view_gift_card") > -1) {
      setIsGiftCardsActive("GiftCards");
    }

    // Task
    if (window.location.href.indexOf("todos") > -1) {
      setIsTasksActive("To-do");
    } else if (window.location.href.indexOf("view_todos_details") > -1) {
      setIsTasksActive("To-do");
    } else if (window.location.href.indexOf("add_todos") > -1) {
      setIsTasksActive("To-do");
    }
  }, [UserDetails.id, showFamilyNav, uuid]);

  let userDetails = JSON.parse(localStorage.getItem("UserDetails"));

  const noImgError = (e) => {
    e.target.setAttribute("src", Avatar);
  };

  const [shown, setshown] = useState(false);
  const [shown1, setshown1] = useState(false);
  const [shown2, setshown2] = useState(false);
  const [shown3, setshown3] = useState(false);
  const [shown4, setshown4] = useState(false);
  const [shown5, setshown5] = useState(false);

  const handleSubscription = () => {
    if (userType) {
      history.push(`/subscription/${splitUrl[4]}`);
    } else {
      history.push(`/subscription`);
    }
  };

  const handleProfile = () => {
    history.push(`/profile/${splitUrl[4]}`);
  };

  const [showUpdateFmlyModal, setShowUpdateFmlyModal] = useState(false);
  const [updateFmlyFormErrors, setUpdateFmlyFormErrors] = useState("");
  const [isUpdateFmlySubmit, setIsUpdateFmlySubmit] = useState(false);

  const handleChangeGroupName = () => {
    setShowUpdateFmlyModal(!showUpdateFmlyModal);
  };

  const handleFamilyNameSubmit = (e) => {
    e.preventDefault();
    setUpdateFmlyFormErrors(validateFamilyName(updateFmlyFormValues));
    setIsUpdateFmlySubmit(true);
  };

  const validateFamilyName = (values) => {
    const errors = {};
    if (!values.family_name) {
      errors.msg = "Please enter group name";
      errors.family_name = "Yes";
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(updateFmlyFormErrors).length === 0 && isUpdateFmlySubmit) {
      // setFormLoading(true);
      let data = {
        name: updateFmlyFormValues.family_name,
      };

      const urlData = uuid ? uuid : uuidDetails;
      // api call
      UserService.updateFamilyName(urlData, data).then(
        (res) => {
          if (res.status === 400) {
            // setFormLoading(false);
            setShowUpdateFmlyModal(false);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(res.data.message);
          } else {
            // setFormLoading(false);
            setShowUpdateFmlyModal(false);
            window.scrollTo(0, 0);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("Group Name have been successfully updated");
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          }
        },
        (error) => {
          // setFormLoading(false);
        }
      );
    }
  }, [updateFmlyFormErrors]);

  useEffect(() => {
    if (familyLengthVal.length > 0) {
      if (userType) {
        if (
          userType === "AO + AU" ||
          userType === "AO + AU + SU" ||
          userType === "AO" ||
          userType === "AO + SU"
        ) {
          setshown(true);
          setshown1(false);
          setshown2(false);
          setshown3(false);
          setshown4(false);
          setshown5(false);
        } else {
          setshown1(true);
          setshown(false);
          setshown2(false);
          setshown3(false);
          setshown4(false);
          setshown5(false);
        }
      } else {
        if (splitUrl[3] === "family_selection") {
          setshown(false);
          setshown1(false);
          setshown2(true);
          setshown3(false);
          setshown4(false);
          setshown5(false);
        } else if (splitUrl[3] === "subscription") {
          setshown(false);
          setshown1(false);
          setshown2(false);
          setshown3(true);
          setshown4(false);
          setshown5(false);
        }
      }
    } else {
      if (splitUrl[3] === "subscription") {
        setshown(false);
        setshown1(false);
        setshown2(false);
        setshown3(false);
        setshown4(true);
        setshown5(false);
      }
      if (splitUrl[3] === "myInvites") {
        setshown(false);
        setshown1(false);
        setshown2(false);
        setshown3(false);
        setshown4(false);
        setshown5(true);
      }
    }
  }, [splitUrl, userType]);

  if (splitUrl[3] !== "confirmation" && splitUrl[3] !== "onboardUser") {
    return (
      <>
        <Navbar className={navbarClass} collapseOnSelect expand="xl">
          <ToastBlockContainer
            successToast={successToast}
            showToast={showToast}
            setShowToast={setShowToast}
            toastMessage={toastMessage}
          />
          <Container fluid>
            {RedFamDash === "RedFamDash" && (
              <>
                {parentUserUUId === null ? (
                  <Link
                    className="navbar-brand order-xl-first"
                    to={`/dashboard/${splitUrl[4]}`}
                  >
                    <img src={Logo} alt="logo" />
                  </Link>
                ) : (
                  <Link
                    className="navbar-brand order-xl-first"
                    to={`/supporting_user_dashboard/${splitUrl[4]}/${parentUserUUId}`}
                  >
                    <img src={Logo} alt="logo" />
                  </Link>
                )}
              </>
            )}
            {RedFamDash === null && (
              <Link
                className="order-xl-first a1"
                to={
                  splitUrl[3] !== "family_selection" &&
                  splitUrl[4] !== undefined &&
                  splitUrl[4] !== "not-found"
                    ? `/dashboard/${splitUrl[4]}`
                    : `/family_selection`
                }
              >
                <img src={Logo} alt="logo" />
              </Link>
            )}
            <Navbar.Toggle
              className="order-last ms-2 ms-md-3 ms-xl-0"
              aria-controls="navbar-nav"
            />

            <div className="navbar-new-header" style={{ fontSize: "14px" }}>
              <Nav className="align-items-xl-center flex-grow-1">
                {showFamilyNav && (
                  <>
                    {(userType === "AO + AU + SU" ||
                      userType === "AO + AU" ||
                      userType === "AU + SU" ||
                      userType === "AU" ||
                      splitUrl[3] === "supporting_user_dashboard" ||
                      parentUserUUId) && (
                      <>
                        <div className="nav-item">
                          <Link
                            className={`nav-link ${
                              isFamilyDashboardActive ||
                              isSupportingDashboardActive
                                ? "active"
                                : ""
                            }`}
                            to={
                              !parentUserUUId
                                ? `/dashboard/${splitUrl[4]}`
                                : `/supporting_user_dashboard/${splitUrl[4]}/${parentUserUUId}`
                            }
                          >
                            Dashboard
                          </Link>
                        </div>
                        <div className="nav-item">
                          <Link
                            className={`nav-link ${
                              isSubsActive ? "active" : ""
                            }`}
                            to={
                              !parentUserUUId
                                ? `/myoutgoings/${splitUrl[4]}`
                                : `/user_myoutgoings/${splitUrl[4]}/${parentUserUUId}`
                            }
                          >
                            Outgoings
                          </Link>
                        </div>
                        <div className="nav-item">
                          <Link
                            className={`nav-link ${
                              isTasksActive ? "active" : ""
                            }`}
                            to={
                              !parentUserUUId
                                ? `/todos/${splitUrl[4]}`
                                : `/user_todos/${splitUrl[4]}/${parentUserUUId}`
                            }
                          >
                            To-do
                          </Link>
                        </div>
                        <div className="nav-item">
                          <Link
                            className={`nav-link ${
                              isBenefitsActive ? "active" : ""
                            }`}
                            to={`/discounts/${splitUrl[4]}`}
                          >
                            Discounts
                          </Link>
                        </div>
                        <div className="nav-item">
                          <Link
                            className={`nav-link ${
                              isGiftCardsActive ? "active" : ""
                            }`}
                            to={
                              !parentUserUUId
                                ? `/vouchers_&_gift_cards/${splitUrl[4]}`
                                : `/user_vouchers_&_gift_cards/${splitUrl[4]}/${parentUserUUId}`
                            }
                          >
                            Vouchers & Gift cards
                          </Link>
                        </div>
                        <div className="nav-item">
                          <Link
                            className={`nav-link ${
                              isWarrantyActive ? "active" : ""
                            }`}
                            to={
                              !parentUserUUId
                                ? `/warranties/${splitUrl[4]}`
                                : `/user_warranties/${splitUrl[4]}/${parentUserUUId}`
                            }
                          >
                            Warranties
                          </Link>
                        </div>
                        <div className="nav-item">
                          <Link
                            className={`nav-link ${
                              isCompareActive ? "active" : ""
                            }`}
                            to={`/compare_and_save/${splitUrl[4]}`}
                          >
                            Compare & save
                          </Link>
                        </div>
                      </>
                    )}
                    {(userType === "AO" ||
                      userType === "SU" ||
                      userType === "AO + SU") &&
                      splitUrl[3] !== "supporting_user_dashboard" &&
                      !parentUserUUId && (
                        <>
                          <div className="nav-item">
                            <Link
                              className={`nav-link ${
                                isFamilyDashboardActive ||
                                isSupportingDashboardActive
                                  ? "active"
                                  : ""
                              }`}
                              to={
                                !parentUserUUId
                                  ? `/dashboard/${splitUrl[4]}`
                                  : `/supporting_user_dashboard/${splitUrl[4]}/${parentUserUUId}`
                              }
                            >
                              Dashboard
                            </Link>
                          </div>
                          <div className="nav-item">
                            <Link
                              className={`nav-link ${
                                isBenefitsActive ? "active" : ""
                              }`}
                              to={`/discounts/${splitUrl[4]}`}
                            >
                              Discounts
                            </Link>
                          </div>
                          <div className="nav-item">
                            <Link
                              className={`nav-link ${
                                isCompareActive ? "active" : ""
                              }`}
                              to={
                                uuid ? `/compare_and_save/${splitUrl[4]}` : "#"
                              }
                            >
                              Compare & save
                            </Link>
                          </div>
                        </>
                      )}
                  </>
                )}
              </Nav>
            </div>

            <Dropdown className="ms-auto">
              <Dropdown.Toggle
                className="navbar-dropdown-toggle p-0 d-flex align-items-center navbar-username"
                variant="Warning"
                id="dropdown-basic"
              >
                <div class="overflow-hidden text-end">
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip" {...props}>
                        {userDetails.firstname === "null" ||
                        userDetails.firstname === "" ||
                        userDetails.lastname === "null" ||
                        userDetails.lastname === ""
                          ? userDetails.email
                          : userDetails.lastname + " " + userDetails.lastname}
                      </Tooltip>
                    }
                  >
                    <div className="fw-bold text-dark text-truncate mb-0">
                      {userDetails.firstname === "null" ||
                      userDetails.firstname === "" ||
                      userDetails.lastname === "null" ||
                      userDetails.lastname === ""
                        ? userDetails.email
                        : userDetails.firstname + " " + userDetails.lastname}
                    </div>
                  </OverlayTrigger>
                  <div className="small fw-medium text-bod-secondary lh-sm text-truncate mb-0">
                    {userType === "AO"
                      ? "Admin"
                      : userType === "AO + AU"
                      ? "Admin + Main user"
                      : userType === "AO + SU"
                      ? "Admin + Support user"
                      : userType === "AO + AU + SU"
                      ? "Admin + Main + Support user"
                      : userType === "AU"
                      ? "Main user"
                      : userType === "AU + SU"
                      ? "Main + Support user"
                      : userType === "SU"
                      ? "Support user"
                      : ""}
                  </div>
                </div>
                <div className="avatar flex-shrink-0 ms-3 ms-md-4">
                  <Image
                    className=""
                    src={userDetails.avatar}
                    onError={noImgError}
                    alt="Profile Avatar"
                    id="dropdown-basic"
                  />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-end">
                {shown && (
                  <>
                    {familyLengthVal.length > 1 && (
                      <Dropdown.Item onClick={handleSwitchGroup}>
                        Switch Group
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item onClick={handleSubscription}>
                      Create New Group
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleChangeGroupName}>
                      Change Group Name
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleChangePlan}>
                      Manage Account Plan
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        history.push(`/manage_users/${splitUrl[4]}`)
                      }
                    >
                      {userType === "AO" ||
                      userType === "AU" ||
                      userType === "AU + SU" ||
                      userType === "SU"
                        ? "All Users"
                        : "Manage Users"}
                    </Dropdown.Item>
                    <div
                      style={{
                        height: "1px",
                        width: "85%",
                        backgroundColor: "#e0e0e0", // Light color for the line
                        margin: "auto",
                      }}
                    ></div>
                    <Dropdown.Item onClick={handleProfile}>
                      My Profile
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleInvites}>
                      My Invites
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleeventlog}>
                      Event Logs
                    </Dropdown.Item>
                    <Dropdown.Item href="#" onClick={handleLogout}>
                      Log off
                    </Dropdown.Item>{" "}
                  </>
                )}
                {shown1 && (
                  <>
                    {familyLengthVal.length > 1 && (
                      <Dropdown.Item onClick={handleSwitchGroup}>
                        Switch Group
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item onClick={handleSubscription}>
                      Create New Group
                    </Dropdown.Item>
                    <div
                      style={{
                        height: "1px",
                        width: "85%",
                        backgroundColor: "#e0e0e0", // Light color for the line
                        margin: "auto",
                      }}
                    ></div>
                    <Dropdown.Item onClick={handleProfile}>
                      My Profile
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        history.push(`/manage_users/${splitUrl[4]}`)
                      }
                    >
                      {userType === "AO" ||
                      userType === "AU" ||
                      userType === "AU + SU" ||
                      userType === "SU"
                        ? "All Users"
                        : "Manage Users"}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleInvites}>
                      My Invites
                    </Dropdown.Item>

                    <Dropdown.Item href="#" onClick={handleLogout}>
                      Log off
                    </Dropdown.Item>
                  </>
                )}
                {shown2 && (
                  <>
                    <Dropdown.Item href="#" onClick={handleLogout}>
                      Log off
                    </Dropdown.Item>
                  </>
                )}
                {shown3 && (
                  <>
                    {familyLengthVal.length > 1 && (
                      <Dropdown.Item onClick={handleSwitchGroup}>
                        Switch Group
                      </Dropdown.Item>
                    )}

                    <div
                      style={{
                        height: "1px",
                        width: "85%",
                        backgroundColor: "#e0e0e0", // Light color for the line
                        margin: "auto",
                      }}
                    ></div>
                    <Dropdown.Item onClick={handleInvites}>
                      My Invites
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        history.push(`/manage_users/${splitUrl[4]}`)
                      }
                    >
                      {userType === "AO" ||
                      userType === "AU" ||
                      userType === "AU + SU" ||
                      userType === "SU"
                        ? "All Users"
                        : "Manage Users"}
                    </Dropdown.Item>

                    <Dropdown.Item href="#" onClick={handleLogout}>
                      Log off
                    </Dropdown.Item>
                  </>
                )}

                {shown4 && (
                  <>
                    {familyLengthVal.length > 1 && (
                      <>
                        <Dropdown.Item onClick={handleSwitchGroup}>
                          Switch Group
                        </Dropdown.Item>
                        <div
                          style={{
                            height: "1px",
                            width: "85%",
                            backgroundColor: "#e0e0e0", // Light color for the line
                            margin: "auto",
                          }}
                        ></div>
                      </>
                    )}

                    <Dropdown.Item onClick={handleInvites}>
                      My Invites
                    </Dropdown.Item>
                    <Dropdown.Item href="#" onClick={handleLogout}>
                      Log off
                    </Dropdown.Item>
                  </>
                )}

                {shown5 && (
                  <>
                    <Dropdown.Item onClick={handleSubscription}>
                      Create New Group
                    </Dropdown.Item>
                    <Dropdown.Item href="#" onClick={handleLogout}>
                      Log off
                    </Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <Navbar.Offcanvas
              id="offcanvasNavbar-expand-xl"
              aria-labelledby="offcanvasNavbarLabel-expand-xl"
              className="order-last order-xl-first"
            >
              <Offcanvas.Header closeButton>
                <Navbar.Brand href="/home">
                  <img src={Logo} alt="Eldery Life logo" />
                </Navbar.Brand>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="align-items-xl-center flex-grow-1">
                  {showFamilyNav ? (
                    <>
                      {((uservalue.isAo && uservalue.isAu && uservalue.isSu) ||
                        (uservalue.isAo && uservalue.isAu && !uservalue.isSu) ||
                        (!uservalue.isAo && uservalue.isAu && uservalue.isSu) ||
                        (!uservalue.isAo &&
                          uservalue.isAu &&
                          !uservalue.isSu) ||
                        splitUrl[3] === "supporting_user_dashboard" ||
                        parentUserUUId) && (
                        <>
                          <div className="nav-item">
                            <Link
                              className={`nav-link ${
                                isFamilyDashboardActive ||
                                isSupportingDashboardActive
                                  ? "active"
                                  : ""
                              }`}
                              to={
                                uuid
                                  ? !parentUserUUId
                                    ? `/dashboard/${splitUrl[4]}`
                                    : `/supporting_user_dashboard/${splitUrl[4]}/${parentUserUUId}`
                                  : "#"
                              }
                            >
                              Dashboard
                            </Link>
                          </div>
                          <div className="nav-item">
                            <Link
                              className={`nav-link ${
                                isSubsActive ? "active" : ""
                              }`}
                              to={
                                uuid
                                  ? !parentUserUUId
                                    ? `/myoutgoings/${uuid}`
                                    : `/user_myoutgoings/${uuid}/${parentUserUUId}`
                                  : "#"
                              }
                            >
                              Outgoings
                            </Link>
                          </div>
                          <div className="nav-item">
                            <Link
                              className={`nav-link ${
                                isTasksActive ? "active" : ""
                              }`}
                              to={
                                uuid
                                  ? !parentUserUUId
                                    ? `/todos/${uuid}`
                                    : `/user_todos/${uuid}/${parentUserUUId}`
                                  : "#"
                              }
                            >
                              To-do
                            </Link>
                          </div>
                          <div className="nav-item">
                            <Link
                              className={`nav-link ${
                                isBenefitsActive ? "active" : ""
                              }`}
                              to={uuid ? `/discounts/${uuid}` : "#"}
                            >
                              Discounts
                            </Link>
                          </div>
                          <div className="nav-item">
                            <Link
                              className={`nav-link ${
                                isGiftCardsActive ? "active" : ""
                              }`}
                              to={
                                uuid
                                  ? !parentUserUUId
                                    ? `/vouchers_&_gift_cards/${uuid}`
                                    : `/user_vouchers_&_gift_cards/${uuid}/${parentUserUUId}`
                                  : "#"
                              }
                            >
                              Vouchers & Gift cards
                            </Link>
                          </div>
                          <div className="nav-item">
                            <Link
                              className={`nav-link ${
                                isWarrantyActive ? "active" : ""
                              }`}
                              to={
                                uuid
                                  ? !parentUserUUId
                                    ? `/warranties/${uuid}`
                                    : `/user_warranties/${uuid}/${parentUserUUId}`
                                  : "#"
                              }
                            >
                              Warranties
                            </Link>
                          </div>
                          <div className="nav-item">
                            <Link
                              className={`nav-link ${
                                isCompareActive ? "active" : ""
                              }`}
                              to={uuid ? `/compare_and_save/${uuid}` : "#"}
                            >
                              Compare & save
                            </Link>
                          </div>
                        </>
                      )}
                      {((uservalue.isAo &&
                        !uservalue.isAu &&
                        !uservalue.isSu) ||
                        (!uservalue.isAo &&
                          !uservalue.isAu &&
                          uservalue.isSu) ||
                        (uservalue.isAo &&
                          !uservalue.isAu &&
                          uservalue.isSu)) &&
                        splitUrl[3] !== "supporting_user_dashboard" &&
                        !parentUserUUId && (
                          <>
                            <div className="nav-item">
                              <Link
                                className={`nav-link ${
                                  isBenefitsActive ? "active" : ""
                                }`}
                                to={uuid ? `/discounts/${uuid}` : "#"}
                              >
                                Discounts
                              </Link>
                            </div>
                            <div className="nav-item">
                              <Link
                                className={`nav-link ${
                                  isCompareActive ? "active" : ""
                                }`}
                                to={uuid ? `/compare_and_save/${uuid}` : "#"}
                              >
                                Compare & save
                              </Link>
                            </div>
                          </>
                        )}
                    </>
                  ) : (
                    ""
                  )}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>

          {/* modal  */}
          <Modal
            show={showUpdateFmlyModal}
            onHide={() => setShowUpdateFmlyModal(!showUpdateFmlyModal)}
            className="choose_plan_modal"
          >
            <Modal.Header closeButton>
              {/* <Modal.Title><h3>Confirm Account plan</h3></Modal.Title>*/}
              <Modal.Title>
                <h3>Update Group Name</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form className="family-form" onSubmit={handleFamilyNameSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label className={"mb-2"}>Group Name</Form.Label>
                  <Form.Control
                    className={
                      updateFmlyFormValues.family_name ? "invalid-input" : ""
                    }
                    type="text"
                    placeholder="Enter Group Name"
                    name="family_name"
                    id="family_name"
                    value={updateFmlyFormValues.family_name}
                    onChange={handleUpdateFmlyChange}
                  />

                  {Object.keys(updateFmlyFormErrors).length !== 0 &&
                  isUpdateFmlySubmit ? (
                    <p className="text-danger form-text">
                      {updateFmlyFormErrors.msg}{" "}
                    </p>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <div className="text-center mt-5 d-flex align-items-center justify-content-center">
                  <Button className="" variant="primary" type="submit">
                    <i className="fa-solid fa-sm fa-add lh-1 align-middle me-2"></i>
                    Update Group Name
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        </Navbar>
      </>
    );
  } else {
    return "";
  }
};

export default NavBar;
