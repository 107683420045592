import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import UserService from "../../services/user.service";
import moment from "moment";
import FileViewer from "react-file-viewer";
import PDFViewer from "pdf-viewer-reactjs";
import Modal from "react-bootstrap/Modal";
import Loading from "../../common/Loading";
import ToastBlockContainer from "../../common/ToastBlockContainer";

const ViewGiftCardsDetails = (props) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [successToast, setSuccessToast] = useState(false);
  const [familyDetails, setFamilyDetails] = useState([]);
  const [giftCardDetails, setGiftCardDetails] = useState([]);
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const [giftcardsUrl, setGiftcardsUrl] = useState("");
  const [giftcardEditUrl, setGiftcardEditUrl] = useState("");
  const [backText, setBackText] = useState("Back to My Vouchers & Gift Cards");
  let { uuid, giftId, userUUID } = useParams();
  const [showDocSub, setShowDocSub] = useState(false);
  const [loader, setLoader] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [parsedDoc, setParsedDoc] = useState(false);
  const [fileName, setFileName] = useState("");

  const history = useHistory();

  useEffect(() => {
    // get family details
    UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
      (response) => {
        setFamilyDetails(response);
      },
      (error) => {}
    );

    if (userUUID) {
      setGiftcardsUrl("/user_vouchers_&_gift_cards/" + uuid + "/" + userUUID);
      UserService.userProfile(userUUID).then((response) => {
        setBackText(
          response.userData.firstName === "null"
            ? response.userData.email
            : response.userData.firstName + " " + response.userData.lastName ===
              "null"
            ? ""
            : response.userData.firstName
        );
      });
    } else {
      setGiftcardsUrl("/vouchers_&_gift_cards/" + uuid);
    }

    // get single subscription details by ID
    UserService.getGiftcardDetailsById(giftId).then(
      (response) => {
        setGiftCardDetails(response.data);
        // Convert back to array
        const parsedUrls = response.data.documentLink
          ? JSON.parse(response.data.documentLink)
          : response.data.documentLink;

        setParsedDoc(parsedUrls);
        if (response.data) {
          if (userUUID) {
            setGiftcardEditUrl(
              "/edit_user_vouchers_&_gift_cards/" +
                uuid +
                "/" +
                response.data.id +
                "/" +
                userUUID
            );
          } else {
            setGiftcardEditUrl(
              "/edit_vouchers_&_gift_cards/" + uuid + "/" + response.data.id
            );
          }
        } else {
          setGiftcardEditUrl("#");
        }
        setLoader(false);
      },
      (error) => {}
    );
  }, []);

  // show doc model
  const handleShowDocSubShow = (docUrl) => {
    setShowDocSub(true);
    setFileName(docUrl);
  };

  const get_url_extension = (url) => {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  };

  // Close doc model
  const handleShowDocSubClose = () => {
    setShowDocSub(false);
  };

  // open delete confirmation
  const handleShowDelete = () => {
    setShowDelete(true);
  };

  // Close delete confirmation
  const handleShowDeleteClose = () => {
    setShowDelete(false);
  };

  // Delete giftcard
  const handleDeleteGiftCard = (giftId) => {
    setShowDelete(false);

    UserService.deleteGiftcard(giftId, giftCardDetails.cardType).then(
      (response) => {
        setShowToast(true);
        setSuccessToast(true);
        window.scrollTo(0, 0);

        // setToastMessage("Giftcard deleted successfully");
        setToastMessage(
          giftCardDetails.cardType === "Gift Card"
            ? "Gift Card deleted successfully"
            : "Voucher deleted successfully"
        );
        setTimeout(() => {
          history.push(`/vouchers_&_gift_cards/${uuid}`);
        }, 2500);
      },
      (error) => {}
    );
  };

  return (
    <Container>
      <ToastBlockContainer
        successToast={successToast}
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <Row className="justify-content-center gy-0 mb-6rem">
        <Col xl="21" xxl="18">
          <h1 className="lh-base mb-4">
            {giftCardDetails.cardType === "Voucher" ? "Voucher" : "Gift card"}
          </h1>
          <section className="bg-white p-4 p-md-6 p-lg-8 shadow rounded-4">
            {loader ? (
              <>
                <Loading />
              </>
            ) : (
              <>
                <section>
                  <Row className="gy-3 gy-md-4 gy-lg-0 mb-3 mb-md-4">
                    <Col xs lg="15">
                      <h2 className="mb-1 mb-md-2">
                        {giftCardDetails.name ? giftCardDetails.name : "--"}
                      </h2>
                      <p className="mb-4">
                        {giftCardDetails.comments
                          ? giftCardDetails.comments
                          : "--"}
                      </p>
                      <div className="small fw-medium mb-1">Type</div>
                      <div className="badge badge-info-light">
                        <i className="fa-solid fa-sm fa-gift me-2"></i>
                        {giftCardDetails.cardType}
                      </div>
                    </Col>
                    <Col
                      xs="auto"
                      className="order-lg-last ms-auto"
                      style={{ height: "0" }}
                    >
                      <Link
                        to={giftcardEditUrl}
                        className="btn btn-white btn-sm btn-square d-inline-flex d-md-none"
                      >
                        <i className="fa-solid fa-edit"></i>
                      </Link>
                      <Link
                        to={giftcardEditUrl}
                        className="btn btn-white btn-sm d-none d-md-inline-block"
                      >
                        <i className="fa-solid fa-edit me-2"></i>Edit
                      </Link>
                      <button
                        className="btn btn-danger btn-sm btn-square d-inline-flex ms-2"
                        onClick={() => handleShowDelete()}
                      >
                        <i className="fa-solid fa-trash text-white"></i>
                      </button>
                    </Col>
                    {giftCardDetails.cardType === "Voucher" ? (
                      <>
                        <Col lg="auto" className="ms-auto d-lg-none">
                          <div className="small fw-medium mb-1">Total</div>
                          <p className="h2 text-primary-dark mb-0">
                            £
                            {parseFloat(giftCardDetails.totalAmount).toFixed(2)}
                          </p>
                        </Col>
                        <Col
                          lg="auto"
                          className="ms-auto d-none d-lg-block"
                          style={{ height: "0" }}
                        >
                          <div className="small fw-medium mb-1">Total</div>
                          <p className="h2 text-primary-dark mb-0">
                            £
                            {parseFloat(giftCardDetails.totalAmount).toFixed(2)}
                          </p>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col lg="auto" className="ms-auto d-lg-none">
                          <div className="small fw-medium mb-1">Remaining</div>
                          <p className="h2 text-primary-dark mb-0">
                            £
                            {parseFloat(
                              giftCardDetails.totalAmount -
                                giftCardDetails.usedAmount
                            ).toFixed(2)}
                          </p>
                        </Col>
                        <Col
                          lg="auto"
                          className="ms-auto d-none d-lg-block"
                          style={{ height: "0" }}
                        >
                          <div className="small fw-medium mb-1">Remaining</div>
                          <p className="h2 text-primary-dark mb-0">
                            £
                            {parseFloat(
                              giftCardDetails.totalAmount -
                                giftCardDetails.usedAmount
                            ).toFixed(2)}
                          </p>
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row className="gy-3 gy-md-0 mb-3 mb-md-4">
                    <Col xs="16" md="8">
                      <div className="small fw-medium mb-1">Supplier</div>
                      <div className="d-flex align-items-center gap-2">
                        <img
                          src={
                            giftCardDetails.Supplier.logo === null
                              ? "https://el-backenddata.s3.eu-west-2.amazonaws.com/adminassets/Default_logo.jpg"
                              : giftCardDetails.Supplier.logo
                          }
                          alt="logo"
                          className="img-third-party-logo"
                        />
                        <p className="text-dark fw-medium mb-0">
                          {giftCardDetails.Supplier &&
                          giftCardDetails.Supplier.name
                            ? giftCardDetails.Supplier.name === "Others"
                              ? giftCardDetails.otherSupplierName
                              : giftCardDetails.Supplier.name
                            : "--"}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="gy-3 gy-md-0">
                    {giftCardDetails.cardType === "Voucher" ? (
                      <></>
                    ) : (
                      <>
                        <Col md="8">
                          <div className="small fw-medium mb-1">
                            Total amount
                          </div>
                          <p className="text-dark fw-medium mb-0">
                            £{giftCardDetails.totalAmount}
                          </p>
                        </Col>
                        <Col md="8">
                          <div className="small fw-medium mb-1">
                            Used amount
                          </div>
                          <p className="text-dark fw-medium mb-0">
                            £{giftCardDetails.usedAmount}
                          </p>
                        </Col>
                      </>
                    )}
                    <Col md="12" className="mt-4">
                      <div className="small fw-medium mb-1">Expiry date</div>
                      <div className="d-flex">
                        <p className="text-danger fw-semibold me-2 mb-0">
                          {moment(giftCardDetails.expiryDate).format(
                            "DD/MM/YYYY"
                          )}
                        </p>
                        {giftCardDetails.reminder && (
                          <div className="d-flex ms-2">
                            <span className="badge badge-xs d-inline-flex align-items-center badge-primary-light">
                              <i className="fa-solid fa-sm fa-bell"></i>
                              <span className="ms-1">Reminder</span>
                            </span>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </section>
                <hr className="my-6 my-md-7" />
                <section>
                  <h3 className="mb-3 mb-md-4">
                    {parsedDoc && parsedDoc.length > 1
                      ? "Uploaded attachments"
                      : "Uploaded attachment"}
                  </h3>
                  <Row className="gy-0">
                    {parsedDoc && parsedDoc.length > 0 ? (
                      parsedDoc.map((data, index) => (
                        <Col md="12" lg="8">
                          <p
                            className="btn btn-white btn-sm d-flex p-3"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleShowDocSubShow(data)}
                          >
                            <span className="fw-medium text-black">
                              <i className="fa-solid fa-file me-1"></i>{" "}
                              {data.split("/").pop().substring(0, 20) + "..."}
                            </span>
                          </p>
                        </Col>
                      ))
                    ) : (
                      <p className="view-txt fw-medium">
                        No documents available
                      </p>
                    )}
                  </Row>
                </section>
              </>
            )}
          </section>
        </Col>
      </Row>

      {/* Show documents */}
      <Modal
        show={showDocSub}
        onHide={handleShowDocSubClose}
        className=""
        size="lg"
      >
        <Modal.Header
          closeButton
          className="d-flex align-items-center justify-content-between"
        >
          <Modal.Title className="mx-1">
            <h3 className="mb-0">View Attachment</h3>
          </Modal.Title>

          <Link
            to={{
              pathname: fileName,
            }}
            target="_blank"
            className="ms-auto position-absolute"
            style={{
              right: "4rem",
              color: "black",
              height: "20px",
            }}
          >
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="button-tooltip">Download this file</Tooltip>
              }
            >
              <i class="fa-solid fa-download"></i>
            </OverlayTrigger>
          </Link>
        </Modal.Header>
        <Modal.Body
          className="mx-1 overflow-auto"
          style={{ maxHeight: "75vh" }}
        >
          <div className="document-container mb-5">
            {get_url_extension(fileName) === "pdf" ? (
              <PDFViewer
                document={{
                  url: fileName,
                }}
                className="w-100 h-100"
              />
            ) : get_url_extension(fileName) === "png" ||
              get_url_extension(fileName) === "jpg" ||
              get_url_extension(fileName) === "jpeg" ? (
              <img src={fileName} alt={`Document`} className="w-100 h-100" />
            ) : get_url_extension(fileName) === "doc" ||
              get_url_extension(fileName) === "docx" ? (
              <FileViewer
                fileType="docx"
                filePath={fileName}
                className="w-100 h-100"
              />
            ) : (
              <p>Unsupported file type: {get_url_extension(fileName)}</p>
            )}
          </div>
        </Modal.Body>
      </Modal>

      {/* // Delete Gift cards */}
      <Modal
        show={showDelete}
        onHide={handleShowDeleteClose}
        className="invite_au_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="h3 mb-0">
              Delete{" "}
              {giftCardDetails.cardType === "Voucher" ? "Voucher" : "Gift Card"}
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            All the{" "}
            {giftCardDetails.cardType === "Voucher" ? "Vouchers" : "Gift Cards"}{" "}
            related details will be deleted from the family
          </p>
          <p className="mb-5">Do you want to continue?</p>
          <Button variant="white" onClick={handleShowDeleteClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => handleDeleteGiftCard(giftId)}
            className="ms-4"
          >
            Yes
          </Button>
        </Modal.Body>
      </Modal>
    </Container>
  );
};
export default ViewGiftCardsDetails;
