import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import Loading from "../../common/Loading";
import InputGroup from "react-bootstrap/InputGroup";
import moment from "moment";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import Select from "react-select";
import imageX from "../../assets/images/x.svg";

const WarrantyEdit = (props) => {
  let history = useHistory();
  const myRef = useRef(null);
  const [familyDetails, setFamilyDetails] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [Loadingdata, setLoadingdata] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [browserLoading, setBrowserLoading] = useState(false);
  const executeScroll = () => myRef.current.scrollIntoView();
  const [browserMsg, setBrowserMsg] = useState("");
  const [browserErrMsg, setBrowserErrMsg] = useState("");
  const [uploadedDocUrl, setUploadedDocUrl] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [warrantyDetails, setWarrantyDetails] = useState([]);
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const [AmountVal, setAmountVal] = useState();
  const [addUpdate, setaddUpdate] = useState(false);
  const [fileLoader, setFileLoader] = useState(false);
  // State to manage uploaded files
  const [uploadedDocArray, setUploadedDocArray] = useState([]);
  const [otherSupErr, setOtherSupErr] = useState("");
  const [otherSupplier, setOtherSupplier] = useState("");
  const initialSupplierOption = [{ value: "", label: "Select a supplier" }];
  const [supplierOption, setSupplierOption] = useState(initialSupplierOption);
  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));

  // select error style
  const customStylesError = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      backgroundColor: "#ecf6ff 0% 0% no-repeat padding-box",
      fontSize: "16px",
      borderRadius: " 0.75rem",
      borderStyle: "none",
      cursor: "pointer",
      padding: "2px 0",
      border: "0.0625rem solid red",
      marginBottom: "4px",
      color: "#475569",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "0.75rem",
      backgroundColor: "#fff",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      zIndex: 10,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "4px 0",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "0.5rem",
      fontSize: "16px",
      width: "100%",
      cursor: "pointer",
      color: "#475569",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#475569",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#9ca3af",
      fontSize: "16px",
      fontStyle: "italic",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#007bff" : "#9ca3af",
      "&:hover": {
        color: "#007bff",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "#dfe8f1",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: "#9ca3af",
      "&:hover": {
        color: "#007bff",
      },
    }),
  };

  // select style
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      backgroundColor: "#ecf6ff 0% 0% no-repeat padding-box",
      fontSize: "16px",
      borderRadius: " 0.75rem",
      borderStyle: "none",
      cursor: "pointer",
      padding: "2px 0",
      border: "0.0625rem solid #aabccf",
      marginBottom: "4px",
      color: "#475569",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "0.75rem",
      backgroundColor: "#fff",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      zIndex: 10,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "4px 0",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "0.5rem",
      fontSize: "16px",
      width: "100%",
      cursor: "pointer",
      color: "#475569",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#475569",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#9ca3af",
      fontSize: "16px",
      fontStyle: "italic",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#007bff" : "#9ca3af",
      "&:hover": {
        color: "#007bff",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "#dfe8f1",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: "#9ca3af",
      "&:hover": {
        color: "#007bff",
      },
    }),
  };

  const [selectedSupplier, setSelectedSupplier] = useState();
  const [selectedTenure, setSelectedTenure] = useState();
  const [successToast, setSuccessToast] = useState(false);
  const initialWarrantyOption = [
    { value: "", label: "Warranty Term" },
    { value: "6M", label: "6 months" },
    { value: "12M", label: "12 months" },
    { value: "24M", label: "24 months" },
    { value: "36M", label: "36 months" },
    { value: "48M", label: "48 months" },
  ];
  const [warrantyOption, setWarrantyOption] = useState(initialWarrantyOption);
  const [showInvoiceFile, setShowInvoiceFile] = useState("");
  const [userCreateUpdate, setUserCreateUpdate] = useState();
  const [userCreateUpdateFor, setUserCreateUpdateFor] = useState();
  const [warrantiesUrl, setWarrantiesUrl] = useState("");
  const [backText, setBackText] = useState("Back to My Warranties");
  let { uuid, warId, userUUID } = useParams();

  const handleChange = (e, action) => {
    let targetData = { name: "", value: "" };

    if (action) {
      targetData = { name: action.name, value: e.value };

      switch (action.name) {
        case "supplier":
          if (e.label === "Others") {
            setOtherModal(true); // Open the modal
          }
          setSelectedSupplier(e);
          setFormErrors((prevErrors) => ({ ...prevErrors, supplier: "" }));
          break;

        case "warranty_tenure":
          setSelectedTenure(e);
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            warranty_tenure: "",
          }));
          break;

        default:
          break;
      }
    } else if (e.target.name === "price") {
      targetData = {
        name: e.target.name,
        value: formatPrice(e.target.value),
      };
    } else {
      targetData = {
        name: e.target.name,
        value: e.target.value,
      };
    }

    setAmountVal(targetData.value);
    setFormValues((prevValues) => ({
      ...prevValues,
      [targetData.name]: targetData.value,
    }));

    if (targetData.name === "uploaded_doc") {
      setFileLoader(true);
      handleFileUpload(e.target.files[0]);
    }
  };

  const formatPrice = (price) => {
    if (price.indexOf(".") >= 0) {
      return (
        price.substr(0, price.indexOf(".")) +
        price.substr(price.indexOf("."), 3)
      );
    }
    return price;
  };

  const handleFileUpload = (file) => {
    setaddUpdate(true);
    setBrowserLoading(true);
    setBrowserErrMsg("");

    if (!file) {
      setBrowserLoading(false);
      setFileLoader(false);
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const MAX_FILE_SIZE = 2; // 2MB
      const allowedTypes = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/msword", // .doc
      ];

      if (!allowedTypes.includes(file.type)) {
        setBrowserErrMsg("OOPS! The file format doesn't support");
        setBrowserLoading(false);
        setFileLoader(false);
        return;
      }

      const fileSize = file.size / 1024 / 1024;
      if (fileSize > MAX_FILE_SIZE) {
        setBrowserErrMsg("OOPS! File size is above 2MB.");
        setBrowserLoading(false);
      } else {
        uploadFile(file);
      }
    };

    reader.readAsDataURL(file);
  };

  const uploadFile = (file) => {
    setBrowserErrMsg("");

    const formData = new FormData();
    formData.append("file", file, file.name.replace(/ /g, ""));

    UserService.uploadUserDocuments(formData).then(
      (response) => {
        setShowInvoiceFile(file.name.substr(0, 65));
        setBrowserLoading(false);
        setBrowserMsg("Successfully uploaded");
        setUploadedDocUrl(response.link);
        setUploadedDocArray((prevArray) => [...prevArray, response.link[0]]); // Push to the array
        setaddUpdate(false);
        setFileLoader(false);
      },
      () => {
        setBrowserLoading(false);
        setBrowserErrMsg("Something went wrong");
        setaddUpdate(false);
        setFileLoader(false);
      }
    );
  };

  // Function to delete a file
  const deleteFile = (fileUrl) => {
    setUploadedDocArray((prevFiles) =>
      prevFiles.filter((file) => file !== fileUrl)
    );
  };

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    setLoading(true);
  };
  // validation
  const validate = (values) => {
    const errors = {};
    if (!values.warranty_tenure) {
      errors.msg = "Please enter the product name";
      errors.warranty_tenure = "Yes";
    }
    if (!values.price || values.price === 0 || values.price < 0) {
      errors.msg = "Please enter the amount";
      errors.price = "Yes";
    }
    if (!values.productName) {
      errors.msg = "Please enter the product type";
      errors.productName = "Yes";
    }
    if (!values.supplier) {
      errors.msg = "Please enter the supplier";
      errors.supplier = "Yes";
    }
    if (!values.date) {
      errors.msg = "Please enter the start date";
      errors.date = "Yes";
    }
    return errors;
  };

  const getFamilyDetails = (userId) => {
    // get family details
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);
        setUserCreateUpdateFor(response.FamilyData.id);

        // SU Family details
        UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
          (response) => {
            setUserCreateUpdate(response.FamilyData.id);
          },
          (error) => {}
        );
      },
      (error) => {}
    );
  };

  const getAUFamilyDetails = (userId) => {
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);
        setUserCreateUpdate(response.FamilyData.UserId);
        setUserCreateUpdateFor(response.FamilyData.id);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    if (userUUID) {
      UserService.userProfile(userUUID).then((response) => {
        getFamilyDetails(response.userData.id);
        setWarrantiesUrl("/user_warranties/" + uuid + "/" + userUUID);
        setBackText(
          response.userData.firstName === "null"
            ? response.userData.email
            : response.userData.firstName + " " + response.userData.lastName ===
              "null"
            ? ""
            : response.userData.firstName
        );
      });
    } else {
      getAUFamilyDetails(UserDetails.id);
      setWarrantiesUrl("/warranties/" + uuid);
    }

    // get supplier data
    UserService.getSupplier({
      moduleName: "Warranties",
    }).then((resp) => {
      setSupplierOption(resp.data.data);
    });

    // get single warranty details by ID
    UserService.getWarrantyDetailsById(warId).then((response) => {
      setWarrantyDetails(response.data);

      const initialValues = {
        productName: response.data.rows[0].productName,
        supplier: response.data.rows[0].SupplierId,
        additional_details: response.data.rows[0].additionalDetails,
        price: !formErrors.price ? response.data.rows[0].price : AmountVal,
        date: moment(response.data.rows[0].purchaseDate).format("YYYY-MM-DD"),
        warranty_tenure: response.data.rows[0].warrantyTenure,
        item_code: response.data.rows[0].itemCode,
        serial_number: response.data.rows[0].serialNumber,
        invoice_doc: "",
        uploaded_doc: response.data.rows[0].invoiceDocument,
        otherSupplierName: response.data.rows[0].otherSupplierName,
      };
      setFormValues(initialValues);
      let docParams = response.data.rows[0].invoiceDocument
        ? response.data.rows[0].invoiceDocument.split("/")
        : "";
      setShowInvoiceFile(docParams[docParams.length - 1]);
      setUploadedDocUrl(response.data.rows[0].invoiceDocument);
      setIsSwitchOn(response.data.rows[0].reminder);

      // Convert back to array
      const parsedUrls = response.data.rows[0].invoiceDocument
        ? JSON.parse(response.data.rows[0].invoiceDocument)
        : response.data.rows[0].invoiceDocument;

      setUploadedDocArray((prevArray) => [...prevArray, ...parsedUrls]); // Push to the array

      var wtValue = "";
      if (response.data.rows[0].warrantyTenure === "6M") {
        wtValue = "6 Months";
      } else if (response.data.rows[0].warrantyTenure === "12M") {
        wtValue = "12 Months";
      } else if (response.data.rows[0].warrantyTenure === "24M") {
        wtValue = "24 Months";
      } else if (response.data.rows[0].warrantyTenure === "36M") {
        wtValue = "36 Months";
      } else if (response.data.rows[0].warrantyTenure === "48M") {
        wtValue = "48 Months";
      }

      setSelectedTenure({
        value: response.data.rows[0].warrantyTenure,
        label: wtValue,
      });

      setSelectedSupplier({
        label: response.data.rows[0].Supplier.name,
        value: response.data.rows[0].Supplier.id,
      });

      setOtherSupplier(
        response.data.rows[0].otherSupplierName
          ? response.data.rows[0].otherSupplierName
          : ""
      );

      setTimeout(() => {
        setLoadingdata(false);
      }, 500);
    });
  }, []);

  useEffect(() => {
    // edit warranty
    if (
      Object.keys(formErrors).length === 0 &&
      formValues.price > 0 &&
      isSubmit
    ) {
      setLoading(true);

      var tenureMonth = "";
      if (selectedTenure.value === "6M") {
        tenureMonth = "6";
      } else if (selectedTenure.value === "12M") {
        tenureMonth = "12";
      } else if (selectedTenure.value === "24M") {
        tenureMonth = "24";
      } else if (selectedTenure.value === "36M") {
        tenureMonth = "36";
      } else if (selectedTenure.value === "48M") {
        tenureMonth = "48";
      }

      UserService.getCatfromSup({
        supplierId: formValues.supplier,
      }).then((resp) => {
        // Convert to string
        // Convert array to a single string (comma-separated)
        const urlsAsString =
          uploadedDocArray.length > 0 ? JSON.stringify(uploadedDocArray) : "";

        let data = {
          CategoryId: resp.data.data[0].CategoryId,
          SubCategoryId: resp.data.data[0].sub_category_id,
          SupplierId: formValues.supplier,
          productName: formValues.productName,
          additionalDetails: formValues.additional_details,
          price: formValues.price,
          purchaseDate: formValues.date,
          warrantyTenure: selectedTenure.value,
          itemCode: formValues.item_code,
          serialNumber: formValues.serial_number,
          invoiceDocument: urlsAsString,
          reminder: isSwitchOn ? 1 : 0,
          FamilyId: familyDetails.FamilyData.FamilyId,
          expiryDate: addMonth(formValues.date, tenureMonth),
          FamilyUserId: familyDetails.FamilyData.id,
          updatedBy: userCreateUpdate,
          createdUpdatedFor: userCreateUpdateFor,
          otherSupplierName: formValues.otherSupplierName,
        };

        const date = new Date();
        if (moment(date).format("YYYY-MM-DD") >= data.expiryDate) {
          setFormErrors({
            warranty_tenure: "warranty_tenure",
            newErr: "newErr",
          });
        } else {
          // api call
          UserService.updateWarranty(data, warId).then(
            (response) => {
              setLoading(false);
              setSuccessToast(true);
              setShowToast(true);
              executeScroll();
              window.scrollTo(0, 0);
              window.scrollTo(0, 0);
              setToastMessage("Warranty updated successfully");
              setTimeout(() => {
                history.push(warrantiesUrl);
              }, 2000);
            },
            (error) => {}
          );
        }
      });
      setIsSwitchOn(true);
    } else {
      setLoading(false);
    }
  }, [formErrors]);
  // d - is a moment() call
  const addMonth = (d, n) => {
    let fm = moment(d).add(n, "M");
    //let fmEnd = moment(fm).endOf('month');

    return fm.format("YYYY-MM-DD");
  };

  const [otherModal, setOtherModal] = useState(false);
  // Reset dropdown when modal closes
  const handleCloseModal = () => {
    setOtherModal(false); // Close the modal
    setSelectedSupplier(initialSupplierOption); // Reset the selected supplier
    setFormValues((prevValues) => ({
      ...prevValues,
      supplier: "",
      otherSupplierName: "",
    }));
    setOtherSupErr("");
  };

  const submitModal = () => {
    // Check if the input field is empty
    if (!formValues.otherSupplierName) {
      setOtherSupErr("otherSupErr");
      return; // Do not proceed if the input field is empty
    }
    setOtherSupErr("");
    setOtherSupplier(formValues.otherSupplierName);
    setSelectedSupplier(initialSupplierOption); // Reset the selected supplier
    setTimeout(() => {
      setOtherModal(false); // Close the modal
    }, 100);
  };

  return (
    <Container ref={myRef}>
      <ToastBlockContainer
        successToast={successToast}
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <Row className="justify-content-center gy-0 mb-6rem">
        <Col xl="21" xxl="18">
          <h1 className="lh-base mb-4">Edit warranty</h1>
          <section className="bg-white p-4 p-md-6 p-lg-8 shadow rounded-4">
            {Loadingdata ? (
              <>
                <Loading />
              </>
            ) : (
              <>
                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                  <section>
                    <h2 className="mb-1 mb-md-2">Product</h2>
                    <p className="mb-4">
                      Edit all your warranties and service plans here – anything
                      covered by a guarantee​
                    </p>
                    <Row className="gy-0 mb-4">
                      <Col xs="18" sm="14" md="12" lg="10">
                        <Form.Label
                          className={
                            formErrors.productName ? "invalid-text" : " "
                          }
                        >
                          Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          maxLength={255}
                          placeholder="e.g. My new iPhone"
                          name="productName"
                          className={formErrors.productName ? "is-invalid" : ""}
                          value={formValues.productName}
                          onChange={handleChange}
                        />
                        <Form.Text
                          className={
                            formErrors.productName ? "invalid-text" : " "
                          }
                        >
                          Please enter the product name
                        </Form.Text>
                      </Col>
                    </Row>

                    <Row className="gy-0 mb-4">
                      <Col xs="18" sm="14" md="12" lg="10">
                        <Form.Label
                          className={formErrors.supplier ? "invalid-text" : " "}
                        >
                          Supplier
                        </Form.Label>
                        {otherSupplier ? (
                          <div className="form-control d-flex align-items-center gap-3 justify-content-between">
                            {otherSupplier}{" "}
                            <button
                              className="rounded-circle bg-white border-1 p-1 border-black d-flex align-items-center justify-content-center"
                              type="button"
                              onClick={() => {
                                setOtherSupplier("");
                                setFormValues((prevValues) => ({
                                  ...prevValues,
                                  otherSupplierName: "",
                                }));
                                setSelectedSupplier(initialSupplierOption); // Reset the selected supplier
                              }}
                            >
                              <img
                                src={imageX}
                                alt=""
                                style={{ width: "16px", height: "16px" }}
                              />
                            </button>
                          </div>
                        ) : (
                          <Select
                            styles={
                              formErrors.supplier
                                ? customStylesError
                                : customStyles
                            }
                            options={supplierOption}
                            defaultValue={{
                              label: "Select a supplier",
                              value: "",
                            }}
                            value={selectedSupplier}
                            onChange={handleChange}
                            name="supplier"
                          />
                        )}
                        <Form.Text
                          className={formErrors.supplier ? "invalid-text" : " "}
                        >
                          Please select your supplier
                        </Form.Text>
                      </Col>
                    </Row>
                    <Row className="gy-4 gx-md-4 gy-md-0 mb-4">
                      <Col xs="18" sm="14" md="12" lg="10">
                        <Form.Label>
                          Item code
                          <span className="small text-muted fw-normal ms-1">
                            Optional
                          </span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          maxlength="30" // Item Code Usual Max Length: 20–30 characters
                          placeholder="e.g. AB1234-UK"
                          name="item_code"
                          value={formValues.item_code}
                          onChange={handleChange}
                        />
                        <Form.Text>Please enter the item code</Form.Text>
                      </Col>
                      <Col xs="18" sm="14" md="12" lg="10">
                        <Form.Label>
                          Serial number
                          <span className="small text-muted fw-normal ms-1">
                            Optional
                          </span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          maxlength="50" //Serial number Usual Max Length: 30-50 characters
                          placeholder="e.g. 20230522 98765"
                          name="serial_number"
                          value={formValues.serial_number}
                          onChange={handleChange}
                        />
                        <Form.Text>Please enter the serial number</Form.Text>
                      </Col>
                    </Row>
                    <Row className="gy-0">
                      <Col xs="24">
                        <Form.Label>
                          Comments
                          <span className="small text-muted fw-normal ms-1">
                            Optional
                          </span>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          cols={5}
                          name="additional_details"
                          placeholder="Add any extra details or special notes here​"
                          value={formValues.additional_details}
                          onChange={handleChange}
                          maxLength={255}
                        />
                        <Form.Text>
                          Provide any extra information or specific notes about
                          your warranty.
                        </Form.Text>
                      </Col>
                    </Row>
                  </section>
                  <hr className="my-6 my-md-7" />
                  <section>
                    <h3 className="mb-1 mb-md-2">Warranty</h3>
                    <p className="mb-4">
                      Add the warranty or service plan's price, purchase date,
                      and term.​
                    </p>
                    <Row className="gy-0 mb-4">
                      <Col xs="18" sm="14" md="12" lg="8">
                        <Form.Label
                          className={formErrors.price ? "text-danger" : " "}
                        >
                          Price
                        </Form.Label>
                        <InputGroup
                          className={formErrors.price ? "is-invalid" : ""}
                        >
                          <InputGroup.Text>
                            <i className="fa-solid fa-pound-sign fa-sm"></i>
                          </InputGroup.Text>
                          <Form.Control
                            type="number"
                            className={
                              formErrors.price
                                ? "is-invalid invalid-border-right"
                                : ""
                            }
                            onWheel={() => document.activeElement.blur()}
                            name="price"
                            value={formValues.price < 0 ? "" : formValues.price}
                            onBlur={() => {
                              if (formValues.price && formValues.price > 0) {
                                const formattedValue = parseFloat(
                                  formValues.price
                                ).toFixed(2); // Format to 2 decimal places
                                setFormValues((prevValues) => ({
                                  ...prevValues,
                                  price: formattedValue, // Update the price with the formatted value
                                }));
                              }
                            }}
                            onChange={handleChange}
                          />
                        </InputGroup>
                        <Form.Text
                          className={formErrors.price ? "text-danger" : " "}
                        >
                          Please enter the price
                        </Form.Text>
                      </Col>
                    </Row>
                    <Row className="gy-0 mb-4">
                      <Col xs="18" sm="14" md="12" lg="8">
                        <Form.Label
                          className={formErrors.date ? "text-danger" : " "}
                        >
                          Purchase date
                        </Form.Label>
                        <InputGroup
                          className={formErrors.date ? "is-invalid" : " "}
                        >
                          <InputGroup.Text id="basic-addon1">
                            <i className="fa-solid fa-calendar fa-sm"></i>
                          </InputGroup.Text>
                          <Form.Control
                            className={
                              formErrors.date
                                ? "is-invalid invalid-border-right"
                                : " "
                            }
                            type="date"
                            placeholder="e.g. 20/10/2022"
                            name="date"
                            value={formValues.date}
                            onChange={handleChange}
                          />
                        </InputGroup>
                        <Form.Text
                          className={formErrors.date ? "text-danger" : " "}
                        >
                          Please enter the purchase date
                        </Form.Text>
                      </Col>
                    </Row>
                    <Row className="gy-0 mb-4">
                      <Col xs="18" sm="14" md="12" lg="8">
                        <Form.Label
                          className={
                            formErrors.warranty_tenure ? "text-danger" : " "
                          }
                        >
                          Warranty term
                        </Form.Label>

                        <Select
                          styles={
                            formErrors.warranty_tenure
                              ? customStylesError
                              : customStyles
                          }
                          options={warrantyOption}
                          defaultValue={{
                            label: "Select a warranty term",
                            value: "",
                          }}
                          value={selectedTenure}
                          onChange={handleChange}
                          name="warranty_tenure"
                        />
                        <Form.Text
                          className={
                            formErrors.warranty_tenure ? "text-danger" : " "
                          }
                        >
                          {formErrors.warranty_tenure && !formErrors.newErr
                            ? "Please select the warranty term"
                            : formErrors.warranty_tenure && formErrors.newErr
                            ? "Expiry date must be greater than Today's date"
                            : "Please select the warranty term"}
                        </Form.Text>
                      </Col>
                    </Row>
                    <Row className="gy-0">
                      <Col md="24">
                        <Form.Label className="mb-0">Reminder</Form.Label>
                        <p className="mb-2">
                          Standard reminder settings will be applied to this
                          warranty.
                        </p>
                        <Form.Switch
                          onChange={onSwitchAction}
                          id="custom-switch"
                          label=""
                          checked={isSwitchOn}
                        />
                      </Col>
                    </Row>
                  </section>
                  <hr className="my-6 my-md-7" />
                  <section class="mb-4">
                    <h3 className="mb-1 mb-md-2">
                      Upload attachment
                      <span className="small text-muted fw-normal ff-body ms-2">
                        Optional
                      </span>
                    </h3>
                    <p className="mb-4">
                      Upload any key documents that support your warranties or
                      service plans, such as receipts or guarantee certificates​
                    </p>
                    <Row className="gy-0">
                      <Col xl="21" xxl="24" className="mb-3">
                        <Form.Control
                          className="d-none"
                          type="file"
                          id="upload"
                          name="uploaded_doc"
                          onClick={(e) => {
                            e.target.value = null;
                          }}
                          // value={formValues.uploaded_doc}
                          onChange={handleChange}
                          accept="image/png, image/jpg, image/jpeg, application/pdf,  application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"
                        />
                        {fileLoader ? (
                          <div
                            className=" btn-upload mb-0 form-label"
                            style={{ cursor: "auto" }}
                          >
                            <Loading />
                          </div>
                        ) : (
                          <Form.Label
                            className="btn btn-upload mb-0"
                            for="upload"
                          >
                            <i className="fa-solid fa-upload fa-2xl lh-1"></i>
                            <div className="h6 lh-base mt-4 mb-1">
                              Upload an attachment
                            </div>
                            <p className="small fw-normal text-muted mb-0">
                              We accept PNG, JPG, JPEG, PDF & DOC. (Maximum file
                              size 2MB)
                            </p>
                            <p className="mt-3 text-danger">{browserErrMsg}</p>
                          </Form.Label>
                        )}
                      </Col>
                      {/* uploaded files  */}
                      {uploadedDocArray && uploadedDocArray.length > 0 && (
                        <Col xl="21" xxl="24">
                          <div className="fs-18 bold-colour mb-2 fw-medium">
                            Files selected for upload
                          </div>
                          <div>
                            {uploadedDocArray.map((file, index) => (
                              <div
                                key={index}
                                className="file-item p-3 mb-3 d-flex align-items-center justify-content-between"
                                style={{
                                  boxShadow:
                                    "rgba(0, 123, 255, 0.35) 0px 5px 15px",
                                  borderRadius: "12px",
                                }}
                              >
                                <div>{file.split("/").pop()}</div>
                                <button
                                  className="btn btn-danger py-1 px-2"
                                  onClick={() => deleteFile(file)} // This function will handle image removal
                                >
                                  <i className="fa-solid fa-trash text-white"></i>
                                </button>
                              </div>
                            ))}
                          </div>
                        </Col>
                      )}
                    </Row>
                  </section>
                  <div className="d-flex justify-content-end">
                    <Button
                      className="d-flex align-items-center"
                      variant="primary"
                      type="submit"
                      isLoading={loading ? "true" : "false"}
                      isDisabled={loading ? "true" : "false"}
                    >
                      <i className="fa-solid fa-sm fa-save lh-1 align-middle me-2"></i>
                      Save warranty
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </section>
        </Col>
      </Row>

      {/* uploaded document   */}
      <Modal show={otherModal} onHide={() => handleCloseModal()} className="">
        <Modal.Header closeButton>
          <Modal.Title className="mx-1">
            <h3 className="mb-0">Add other Supplier name</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-5">
            <Form.Label className={otherSupErr ? "text-danger" : ""}>
              Supplier name
            </Form.Label>
            <Form.Control
              className={`mb-2 ${
                otherSupErr ? "is-invalid invalid-border-right" : ""
              }`}
              type="text"
              maxlength="100"
              placeholder="e.g. Starbucks"
              name="otherSupplierName"
              value={formValues.otherSupplierName}
              onChange={handleChange}
            />
            <Form.Text className={otherSupErr ? "text-danger mb-4" : "mb-4"}>
              Please enter the name of the supplier if you haven't found their
              name in the dropdown
            </Form.Text>
          </div>
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => submitModal()}
          >
            + Add
          </button>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default WarrantyEdit;
