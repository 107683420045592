import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Loading from "../../common/Loading";
import { useParams } from "react-router-dom";

const FamilyDashboardCalendar = (props) => {
  const [value, onChange] = useState(new Date()); // Selected date
  const [loadingspinner, setLoadingspinner] = useState(true);
  let { uuid } = useParams();
  const [currentMonthYear, setCurrentMonthYear] = useState({
    month: value.toLocaleString("default", { month: "long" }),
    year: value.getFullYear(),
  });

  // Format the selected day and date
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const selectedDayName = dayNames[value.getDay()];
  const selectedDate = value.getDate();

  // Handle month navigation and text updates
  const handleActiveStartDateChange = ({ activeStartDate }) => {
    setCurrentMonthYear({
      month: activeStartDate.toLocaleString("default", { month: "long" }),
      year: activeStartDate.getFullYear(),
    });
  };

  // Handle date or month change
  const changeDateMonth = (date) => {
    onChange(date);
    props.sendDataToParent(date); // Call the parent function
  };

  useEffect(() => {
    if (props.allData) {
      setLoadingspinner(false);
    }
  }, [props.allData]);

  return (
    <>
      <Row className="gx-5 gy-4 gy-md-0">
        <Col md="16" xxl="15">
          <div className="my-calendar-container">
            {/* Header */}
            <div className="calendar-header mb-3 d-flex flex-column align-items-baseline">
              <div className="d-flex align-items-center gap-3 justify-content-between mb-1 w-100">
                <div className="d-flex align-items-center">
                  <div className="calendar-text me-3">
                    <h2 className="month-year mb-1">
                      {currentMonthYear.month} {currentMonthYear.year}
                    </h2>
                  </div>
                  <div className="calendar-navigation d-flex align-items-center">
                    <button
                      className="btn btn-white btn-sm btn-circle me-2"
                      onClick={() =>
                        document
                          .querySelector(
                            ".react-calendar__navigation__prev-button"
                          )
                          .click()
                      }
                    >
                      <i className="fa-solid fa-angle-left text-dark"></i>
                    </button>
                    <button
                      className="btn btn-white btn-sm btn-circle"
                      onClick={() =>
                        document
                          .querySelector(
                            ".react-calendar__navigation__next-button"
                          )
                          .click()
                      }
                    >
                      <i className="fa-solid fa-angle-right text-dark"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div className=" small fw-medium">
                {selectedDayName}, {selectedDate}
              </div>
            </div>

            {/* Calendar */}
            <Calendar
              onChange={(date) => {
                changeDateMonth(date); // Update the selected date
              }}
              value={value}
              onActiveStartDateChange={handleActiveStartDateChange}
            />
          </div>
        </Col>
        <Col md="8" xxl="9" className="text-center">
          <h3 className="h4 mb-3">Reminders</h3>
          {loadingspinner ? (
            <>
              <div className="loadind-page">
                <Loading />
              </div>
            </>
          ) : (
            <>
              {props.allData.calenderData &&
              props.allData.calenderData.length !== 0 ? (
                props.allData.calenderData.map((value, index) => (
                  <div
                    className="rounded-2 px-2 py-3 mb-2"
                    style={{ background: `${value.colour}` }}
                  >
                    <div className="small fw-medium text-dark text-truncate">
                      {value.name}
                    </div>
                    <div className="h5 text-dark text-truncate mb-0">
                      {value.value}
                    </div>
                  </div>
                ))
              ) : (
                <div className="mt-2 mb-6 px-3 fs-14">
                  There is no reminder available on today's date
                </div>
              )}

              <Link
                className="small fw-medium text-dark"
                to={`/reminders/${uuid}`}
              >
                View all<i className="fa-solid fa-sm fa-angle-right ms-2"></i>
              </Link>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
export default FamilyDashboardCalendar;
