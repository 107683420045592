import React, { useState, useEffect } from "react";
import UserService from "../../services/user.service";
import { useHistory } from "react-router-dom";
import { SavedDebitCardBasic } from "../../stories/SavedDebitCard.stories";
import { Primary } from "../../stories/Button.stories";

const CardListCheckout = (props) => {
  let history = useHistory();
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const initialValues = { paymentMethod_id: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setIsDisabled(false);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };
  const validate = (values) => {
    const errors = {};
    if (!values.paymentMethod_id) {
      errors.msg = "Please enter family name";
      errors.paymentMethod_id = "Yes";
    } else {
    }
    return errors;
  };
  useEffect(() => {
    // handle confirm submit
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setMessage("");
      setIsDisabled(false);
      setLoading(true);

      // update payment method id as default source on stripe customer
      let updateData = {
        "invoice_settings[default_payment_method]": formValues.paymentMethod_id,
      };

      UserService.updateCustomerStripe(updateData, props.customerId).then(
        (response) => {
          // update subscription
          let subsciptionData = {
            "items[0][price]": props.priceId,
            proration_behavior: "create_prorations", //always_invoice
          };
          UserService.updateSubsciptionStripe(
            subsciptionData,
            props.subscription
          ).then(
            (response) => {
              if (response.status === 400) {
                setMessage(response.data.error.message.split(":")[0]);
                setLoading(false);
              } else {
                UserService.upgradeSubscription(
                  props.familyUUID,
                  props.planId,
                  props.subscription
                ).then((result) => {
                  history.push({
                    pathname: "/updateConfirmation",
                    familyUUID: props.userUUID,
                  });
                });
              }
            },
            (error) => {
              setLoading(false);
            }
          );
        },
        (error) => {
          const errors = {};
          setLoading(false);
        }
      );
    }
  }, [formErrors]);
  return (
    <form className="saved-card-payment-form" onSubmit={handleSubmit}>
      {message && <p className="mt-4 error-txt-msg">{message}</p>}
      <p className="mt-4 mb-1">Saved cards ( {props.paymentList.length} )</p>
      <div className="saved-card-element card-scroll-element mt-2">
        {props.paymentList.map((list, index) => (
          <div key={index}>
            <SavedDebitCardBasic
              last4digit={list.card.last4}
              userName={Capitalize(list.billing_details.name)}
              exp_month={list.card.exp_month}
              exp_year={list.card.exp_year}
              brand={list.card.brand}
              variant="basic"
              value={list.id}
              onChange={handleChange}
            />
          </div>
        ))}
      </div>
      <div className="text-center mt-5">
        <Primary
          children="Proceed to Payment"
          variant="primary"
          size="mediumbtn"
          type="submit"
          buttonWidth="65%"
          disabled={isDisabled ? "disabled" : ""}
          isLoading={loading ? "true" : "false"}
          isDisabled={loading ? "true" : "false"}
        />
        {/* {loading && (
              <span className="spinner-border spinner-border-sm"></span>
            )} */}
      </div>
    </form>
  );
};

export default CardListCheckout;
