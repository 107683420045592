import React from "react";
import { useRef } from "react";
import { Container, Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import EL_logo from "../../assets/images/EL_logo-big.svg";

const FamilyCreationConfirmation = (props) => {
  let history = useHistory();
  const myRef = useRef(null);

  function openWizardBtn() {
    history.push("/onboardUser");
    history.push({
      pathname: "/onboardUser",
      fromWizardtSuccsess: true,
    });
  }

  if (props.location.key) {
    return (
      <div className="my-5">
        <Container ref={myRef}>
          <div className="text-center">
            <Image
              className="hello-text-img new-img mb-7"
              src={EL_logo}
              alt="Check mark"
            />
            {/* <h3 style={{ "font-size": "initial" }}>Congratulations !!</h3> */}
            <div className="mb-2 fw-bold fs-18 bold-colour">
              Welcome to Elderly Life. We will now ask you a series of questions
              to get you and all the other users set up. 
            </div>
            <div className="mb-7 fw-bold fs-18 bold-colour">
              Remember, we are always here for you; you can click the chat icon
              at the bottom of each screen and drop us a message whenever you
              need support. 
            </div>
            <button className="btn btn-primary" onClick={openWizardBtn}>
              <b>Let’s begin</b>
            </button>
          </div>
        </Container>
      </div>
    );
  } else {
    window.location.href = "/404";
  }
};

export default FamilyCreationConfirmation;
