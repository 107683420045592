import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Image, Form } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { Primary } from "../../stories/Button.stories";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Modal from "react-bootstrap/Modal";
import CheckoutForm from "./CheckoutForm";
import UserService from "../../services/user.service";
import { Outline } from "../../stories/Button.stories";
import SavedCardCheckoutUpdateForm from "./SavedCardCheckoutForm";
import moment from "moment";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import Loading from "../../common/Loading";

const AccountDowngradeSubscription = (props) => {
  let history = useHistory();
  let { uuid } = useParams();
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));

  const [loading, setLoading] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [cusDetails, setCusDetails] = useState();
  const [SubscriptionId, setSubscriptionId] = useState();
  const [priceDetails, setPriceDetails] = useState();
  const [paymentList, setPaymentList] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const [show, setShow] = useState(false);
  const [planId, setPlanId] = useState();
  const [stripeProductId, setStripeProductId] = useState();
  const [planName, setPlanName] = useState();
  const [planPrice, setPlanPrice] = useState();
  const [priceSymbol, setPriceSymbol] = useState();
  const [planAuSeat, setPlanAuSeat] = useState();
  const [planSuSeat, setPlanSuSeat] = useState();
  const [planFrequency, setPlanFrequency] = useState();
  const [planImageIcon, setPlanImageIcon] = useState();
  const [showPayment, setShowPayment] = useState(false);
  const initialValues = { familyName: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [familyDetails, setFamilyDetails] = useState([]);
  const [showChoosePayment, setShowChoosePayment] = useState(false);
  const [showCancelAccount, setShowCancelAccount] = useState(false);
  const [showTrialPopup, setShowTrialPopup] = useState(false);
  const [cancelReason, setcancelReason] = useState(false);
  const [planDetails, setPlanDetails] = useState();
  const [accountPlanList, setAccountPlanList] = useState([]);
  const [trialCheck, settrialCheck] = useState({});
  const [cancelDate, setcancelDate] = useState("");
  const [canceledDate, setcanceledDate] = useState("");
  const [trialCheck1, settrialCheck1] = useState({});
  const [trialCheck2, settrialCheck2] = useState([]);
  const [textAreaField, settextAreaField] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  useEffect(() => {
    UserService.getUserAccountPlan(UserDetails.uuid).then(
      (response) => {
        var newData = [];
        for (var i = 0; i < response.usersFamilyData.length; i++) {
          if (response.usersFamilyData[i].isAo === true) {
            newData.push(response.usersFamilyData[i]);
          }
        }
        newData.forEach((row, ind) => (row.id = ind));
        setAccountPlanList(newData);
      },
      (error) => {}
    );
  }, []);

  const [planDa, setplanDa] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = (planData) => {
    setplanDa(planData);
    setShow(true);
    setFormValues({ familyName: familyDetails.FamilyData.Family.name });
    setFormErrors({});
    setIsSubmit(false);
    setPlanId(planData.id);
    setStripeProductId(planData.stripeProductId);
    setPlanName(planData.name);
    setPlanPrice(planData.price);
    setPriceSymbol(planData.Country.symbol);
    setPriceDetails(planData.stripePriceId);
    setPlanAuSeat(planData.availableSeats);
    setPlanSuSeat(planData.availableSupportSeats);
    setPlanFrequency(planData.renewalType);
    setPlanImageIcon(planData.image);
  };
  const handleSwitchPlan = () => setShow(false);
  const handlePaymentClose = () => setShowPayment(false);
  const handlePaymentShow = () => {
    setShowPayment(true);
  };

  // handle confirm submit
  const handleConfirmSubmit = (e) => {
    e.preventDefault();
    handleChoosePaymentShow();
    handleClose();
    setLoading(false);
  };

  const handleCancelAccount = (e) => {
    var createDate = trialCheck.created;
    var endDate = trialCheck.trial_end;

    if (endDate !== null) {
      var differenceTime = endDate - createDate;
    }
    if (endDate) {
      if (differenceTime <= 604800) {
        setShowTrialPopup(true);
      } else {
        setShowCancelAccount(true);
      }
    } else {
      setShowCancelAccount(true);
    }
  };

  const handleCloseCancelAccount = (e) => {
    setShowCancelAccount(false);
  };

  const handleCloseTrialPopup = (e) => {
    setShowTrialPopup(false);
  };

  // choose payment method modal
  const handleChoosePaymentClose = () => setShowChoosePayment(false);
  const handleChoosePaymentShow = () => {
    // get list of customer payment
    let cusdata = { type: "card" };
    UserService.getCustomerPaymentMethodsList(cusdata, cusDetails).then(
      (res) => {
        setPaymentList(res.data);
        setShowChoosePayment(true);
      }
    );
  };

  // Get Family details
  const getFamilyDetails = () => {
    setLoading(true);
    UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
      (response) => {
        setFamilyDetails(response);
        setPlanDetails(
          response.FamilyData.Family.Family_Subscription
            .Account_Subscription_Plan
        );
        setSubscriptionId(
          response.FamilyData.Family.Family_Subscription.stripeSubscriptionId
        );
        setCusDetails(response.FamilyData.Family.stripeCustomerId);

        getPlanList();
      }
    );
  };

  const getPlanList = () => {
    UserService.planList().then(
      (response) => {
        setSubscriptionPlans(response);
        getTrialCheck();
        // setLoading(false);
      },
      (error) => {
        // setLoading(false);
      }
    );
  };

  useEffect(() => {
    getFamilyDetails();
  }, []);

  const cancelFamily = (e) => {
    setcancelReason(true);
    setShowTrialPopup(false);
    setShowCancelAccount(false);
  };

  const cancelFamilyHide = (e) => {
    setcancelReason(false);
  };

  const cancelFamilySuccessfully = (e) => {
    var diffDates =
      trialCheck.current_period_end - trialCheck.current_period_start;
    var dateDiffernce = diffDates / (24 * 3600);
    var costDay = trialCheck1.plan.amount / dateDiffernce;
    const currentDate = new Date();
    const unixTimestamp = Math.floor(currentDate.getTime() / 1000);
    var newdiffDates = trialCheck.current_period_end - unixTimestamp;
    var newDate = newdiffDates / (24 * 3600);
    var newCost = costDay * newDate;

    // add reason for cancellation
    let dataValue = {
      cancellationComments: textAreaField,
    };

    UserService.reasonCancelFamily(uuid, dataValue).then((respo) => {});

    //cancel the family

    if (trialCheck.status === "trialing") {
      UserService.cancelSubscriptionStripe(trialCheck.id).then((respo) => {
        if (respo.status !== 400 && respo.status !== 404) {
          setShowToast(true);
          setSuccessToast(true);
          setToastMessage("Subscription cancelled successfully");
          setTimeout(() => {
            history.push("/family_selection");
          }, 3000);
        }
      });
    } else {
      UserService.getSubsciptionStripe(trialCheck.id).then((respo) => {
        let subscriptionDate = {
          from_subscription: respo.id,
        };
        UserService.createSubsciptionScheduleStripe(subscriptionDate).then(
          (resp) => {
            if (resp.status === 400) {
              let subsched = resp.data.error.message
                .split(":")[1]
                .trim()
                .slice(1, -2);
              let cancelObj = {
                end_behavior: "cancel",
              };
              UserService.updateSubsciptionScheduleStripe(
                cancelObj,
                subsched
              ).then(
                (response) => {
                  if (response.status !== 400 && response.status !== 404) {
                    setShowToast(true);
                    setSuccessToast(true);
                    setToastMessage("Subscription cancelled successfully");
                    setTimeout(() => {
                      history.push("/family_selection");
                    }, 3000);
                  }
                },
                (error) => {
                  setLoading(false);
                }
              );
            } else {
              let cancelObj = {
                end_behavior: "cancel",
              };
              UserService.updateSubsciptionScheduleStripe(
                cancelObj,
                resp.id
              ).then(
                (response) => {
                  if (response.status !== 400 && response.status !== 404) {
                    setShowToast(true);
                    setSuccessToast(true);
                    setToastMessage("Subscription cancelled successfully");
                    setTimeout(() => {
                      history.push("/family_selection");
                    }, 3000);
                  }
                },
                (error) => {
                  setLoading(false);
                }
              );
            }
          }
        );
      });
    }
  };

  const cancelFamilyTrial = (e) => {
    const unixTimestamp = trialCheck.created + 1209600;

    let subsciptionData = {
      trial_end: unixTimestamp,
      proration_behavior: "always_invoice",
      "items[0][plan]": trialCheck.plan.id,
      "items[0][id]": trialCheck1.id,
    };
    UserService.updateSubsciptionStripe(subsciptionData, trialCheck.id).then(
      (response) => {
        if (response.status !== 400 && response.status !== 404) {
          setShowToast(true);
          setSuccessToast(true);
          setToastMessage(
            "Subscription extended for the next 7 days successfully"
          );
          setTimeout(() => {
            history.push({
              pathname: `/dashboard/${uuid}`,
            });
          }, 3000);
        }
      }
    );
  };

  //check trial thing
  const getTrialCheck = () => {
    var dateCheck = new Date().getFullYear();
    let st = new Date("01-01-" + dateCheck).getTime();
    // get family details
    UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
      (response) => {
        UserService.getSubsciptionStripe(
          response.FamilyData.Family.Family_Subscription.stripeSubscriptionId
        ).then((res) => {
          settrialCheck(res);
          //cancelled Date
          var dateCancelled = new Date(res.canceled_at * 1000);
          const momentCancelled = moment(dateCancelled).format("MMMM Do, YYYY");
          setcanceledDate(momentCancelled);

          //stripe cancelled date
          var dateCancelFt = new Date(res.cancel_at * 1000);
          const momentCancel = moment(dateCancelFt).format("MMMM Do, YYYY");
          setcancelDate(momentCancel);
          settrialCheck1(res.items.data[res.items.data.length - 1]);
          setLoading(false);
        });
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const backToFD = () => {
    history.push(`/dashboard/${uuid}`);
  };

  const textField = (e) => {
    settextAreaField(e.target.value);
  };

  return (
    <>
      <ToastBlockContainer
        successToast={successToast}
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <div className="page-content subscription-pg tab-btn">
        <Container
          fluid
          style={{ minHeight: "calc(100vh - 17rem)", marginBottom: "6rem" }}
          className="d-flex flex-column"
        >
          {loading ? (
            <Loading />
          ) : (
            trialCheck.status !== "canceled" &&
            subscriptionPlans.length > 0 && (
              <>
                <Row className="justify-content-center gy-0 mb-6rem">
                  <Col lg="24" xl="22" xxl="20">
                    <h3>Change Account Plan</h3>
                    <p className="sub-title">
                      In order to move to a lower plan than earlier you need to
                      remove few users from your Family.
                    </p>
                    <section className="bg-white p-4 p-md-5 p-lg-6 shadow rounded-4 mb-4 mb-md-6 tab-view">
                      <Tabs
                        defaultActiveKey={
                          planDetails ? planDetails.renewalType : "annual"
                        }
                        id="uncontrolled-tab-example"
                        className="tab-container"
                      >
                        <Tab
                          eventKey="annual"
                          title="Annual"
                          className="tab-annual"
                        >
                          <Row>
                            <div class="bold-colour fs-18 my-3 fw-medium">
                              All the below plans will have access to all the
                              modules of the Elderly Life Platform
                            </div>
                            {subscriptionPlans.map((plan, index) =>
                              plan.renewalType === "yearly" ? (
                                <Col lg="8" key={index}>
                                  <div>
                                    <Card
                                      className={`story-card subscription-cards mb-3`}
                                      style={{
                                        boxShadow:
                                          "rgba(0, 123, 255, 0.35) 0px 5px 15px",
                                      }}
                                    >
                                      <Card.Body className="p-3">
                                        <div className="">
                                          <h1 className="mb-3">{plan.name}</h1>
                                          <div className="text-info fs-36 fw-bold">
                                            <strong>
                                              {plan.Country.symbol} {plan.price}
                                            </strong>
                                          </div>
                                          <div className="fw-semibold bold-colour mb-5">
                                            {plan.renewalType}
                                          </div>

                                          <p className="fw-semibold bold-colour mb-2">
                                            <span class="text-success me-2">
                                              &#10004;
                                            </span>
                                            {plan.availableSeats === 1 ? (
                                              <span>
                                                {plan.availableSeats} Main User
                                              </span>
                                            ) : (
                                              <span>
                                                {plan.availableSeats} Main Users
                                              </span>
                                            )}
                                          </p>
                                          <p className="fw-semibold bold-colour mb-2">
                                            <span class="text-success me-2">
                                              &#10004;
                                            </span>
                                            {plan.availableSeats === 1 ? (
                                              <span>
                                                {plan.availableSeats} Support
                                                User
                                              </span>
                                            ) : (
                                              <span>
                                                {plan.availableSeats} Support
                                                Users
                                              </span>
                                            )}
                                          </p>
                                          <p className="fw-semibold bold-colour mb-2">
                                            <span class="text-success me-2">
                                              &#10004;
                                            </span>
                                            <span>Cancel any time</span>
                                          </p>
                                          <p className="fw-semibold bold-colour mb-2">
                                            <span class="text-success me-2">
                                              &#10004;
                                            </span>
                                            <span>No hidden costs</span>
                                          </p>
                                        </div>
                                        {plan.id === planDetails.id && (
                                          <span className="subscription-btn">
                                            <Primary
                                              children="Current Plan"
                                              variant="info"
                                              className="btn btn-primary my-3 d-flex align-items-center gap-2 w-fit"
                                              disabled="true"
                                            />
                                          </span>
                                        )}
                                        {planDetails.id > plan.id && (
                                          <span className="subscription-btn">
                                            <Primary
                                              children={
                                                "can't able to downgrade"
                                              }
                                              variant="info"
                                              className="btn btn-primary my-3 d-flex align-items-center gap-2 w-fit"
                                              disabled="true"
                                            />
                                          </span>
                                        )}
                                        {planDetails.id < plan.id && (
                                          <span className="subscription-btn">
                                            <Outline
                                              children={"Choose Plan"}
                                              variant="outline"
                                              className="btn btn-primary my-3 d-flex align-items-center gap-2 w-fit"
                                              onClick={() => handleShow(plan)}
                                            />
                                          </span>
                                        )}
                                      </Card.Body>
                                    </Card>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )
                            )}
                          </Row>
                        </Tab>
                        <Tab
                          eventKey="monthly"
                          title="Monthly"
                          className="tab-monthly"
                        >
                          <Row>
                            <div class="bold-colour fs-18 my-3 fw-medium">
                              All the below plans will have access to all the
                              modules of the Elderly Life Platform
                            </div>

                            {subscriptionPlans.map((plan, index) =>
                              plan.renewalType === "monthly" ? (
                                <Col lg="8" key={index}>
                                  <div>
                                    <Card
                                      className={`story-card subscription-cards mb-3`}
                                      style={{
                                        boxShadow:
                                          "rgba(0, 123, 255, 0.35) 0px 5px 15px",
                                      }}
                                    >
                                      <Card.Body className="p-3">
                                        <div className="">
                                          <h1 className="mb-3">{plan.name}</h1>
                                          <div className="text-info fs-36 fw-bold">
                                            <strong>
                                              {plan.Country.symbol} {plan.price}
                                            </strong>
                                          </div>
                                          <div className="fw-semibold bold-colour mb-5">
                                            {plan.renewalType}
                                          </div>

                                          <p className="fw-semibold bold-colour mb-2">
                                            <span class="text-success me-2">
                                              &#10004;
                                            </span>
                                            {plan.availableSeats === 1 ? (
                                              <span>
                                                {plan.availableSeats} Main User
                                              </span>
                                            ) : (
                                              <span>
                                                {plan.availableSeats} Main Users
                                              </span>
                                            )}
                                          </p>
                                          <p className="fw-semibold bold-colour mb-2">
                                            <span class="text-success me-2">
                                              &#10004;
                                            </span>
                                            {plan.availableSeats === 1 ? (
                                              <span>
                                                {plan.availableSeats} Support
                                                User
                                              </span>
                                            ) : (
                                              <span>
                                                {plan.availableSeats} Support
                                                Users
                                              </span>
                                            )}
                                          </p>
                                          <p className="fw-semibold bold-colour mb-2">
                                            <span class="text-success me-2">
                                              &#10004;
                                            </span>
                                            <span>Cancel any time</span>
                                          </p>
                                          <p className="fw-semibold bold-colour mb-2">
                                            <span class="text-success me-2">
                                              &#10004;
                                            </span>
                                            <span>No hidden costs</span>
                                          </p>
                                        </div>
                                        {plan.id === planDetails.id && (
                                          <span className="subscription-btn">
                                            <Primary
                                              children="Current Plan"
                                              variant="info"
                                              className="btn btn-primary my-3 d-flex align-items-center gap-2 w-fit"
                                              disabled="true"
                                            />
                                          </span>
                                        )}
                                        {planDetails.id > plan.id && (
                                          <span className="subscription-btn">
                                            <Primary
                                              children={
                                                "can't able to downgrade"
                                              }
                                              variant="info"
                                              className="btn btn-primary my-3 d-flex align-items-center gap-2 w-fit"
                                              disabled="true"
                                            />
                                          </span>
                                        )}
                                        {planDetails.id < plan.id && (
                                          <span className="subscription-btn">
                                            <Outline
                                              children={"Choose Plan"}
                                              variant="outline"
                                              className="btn btn-primary my-3 d-flex align-items-center gap-2 w-fit"
                                              onClick={() => handleShow(plan)}
                                            />
                                          </span>
                                        )}
                                      </Card.Body>
                                    </Card>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )
                            )}
                          </Row>
                        </Tab>
                      </Tabs>
                      {trialCheck.status === "trialing" && (
                        <div className="trial-box">
                          {/* Your free trial will going to be end on{" "}
            {moment.unix(trialCheck.trial_end).format("DD-MM-YYYY")} */}
                          Your freetrial will end on{" "}
                          {moment
                            .unix(trialCheck.trial_end)
                            .format("MMMM Do YYYY")}
                          . If you don't cancel your subscription before that,
                          your paid subscription would start and your card would
                          be charged.
                        </div>
                      )}
                      <div
                        className="mt-5 mb-3 btn btn-white"
                        onClick={handleCancelAccount}
                      >
                        Cancel Account Plan
                      </div>
                      <div className="cancel-act-txt">
                        We as a business, sincerely apologise if you had some
                        problems in your Elderly Life journey. Please let us
                        know if we can help you by solving your problem.
                      </div>
                    </section>
                  </Col>
                </Row>
              </>
            )
          )}

          {/* cancelled family modal popup */}
          {!loading && trialCheck.canceled_at != null && (
            <div>
              <Modal show={showModal} className="choose_plan_modal">
                <Modal.Header>
                  <Modal.Title>
                    <h3 style={{ marginTop: "1rem" }}>
                      Subscription Cancelled
                    </h3>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div style={{ margin: "1.5rem 0 2rem 0" }}>
                    <div style={{ margin: "0.5rem 0" }}>
                      You have opted for Subscription Cancellation on{" "}
                      <b>{canceledDate}</b>.So you can't change the plan.
                    </div>
                    <div style={{ margin: "0.5rem 0" }}>
                      But you can still access your subscription until{" "}
                      <b> {cancelDate}</b>
                    </div>
                  </div>
                  <div className="cancel-btnFD" onClick={backToFD}>
                    Back to Group Dashboard
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          )}

          {/* Choose Plan Modal */}
          <Modal show={show} onHide={handleClose} className="choose_plan_modal">
            <Modal.Header closeButton>
              <Modal.Title>
                <h3>{formValues.familyName}</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form className="family-form" onSubmit={handleConfirmSubmit}>
                {Object.keys(formErrors).length !== 0 && isSubmit ? (
                  <p className="error-txt-msg">{formErrors.msg} </p>
                ) : (
                  ""
                )}
                <input
                  type="hidden"
                  name="familyName"
                  value={formValues.familyName}
                />
                <p className="mb-0 mt-4">Confirm Account Plan</p>
                <Card className="mt-2 mb-2">
                  <Card.Body className="p-3">
                    <span className="action-right card-img">
                      <Image src={planImageIcon} />
                    </span>
                    <span>
                      <h3 className="mb-0">{planName ? planName : ""}</h3>
                      <p className="card-subtext">
                        {priceSymbol} {planPrice}/{planFrequency} plan{" "}
                      </p>
                      <p className="mb-1 card-text">
                        {planAuSeat ? planAuSeat : "0"} Main Users &nbsp; &nbsp;
                        &nbsp; {planSuSeat ? planSuSeat : "0"} Support Seats
                      </p>
                    </span>
                  </Card.Body>
                </Card>
                <p className="subtext">
                  Changed your mind?{" "}
                  <Link
                    to={`/downgradeSubscription/${uuid}`}
                    className="modal-link"
                    onClick={handleSwitchPlan}
                  >
                    {" "}
                    Choose another plan{" "}
                  </Link>
                </p>
                <div className="text-center mt-5">
                  <Primary
                    children={"Proceed to payment"}
                    variant="primary"
                    size="mediumbtn"
                    type="submit"
                    buttonWidth="13rem"
                  />
                </div>
              </Form>
            </Modal.Body>
          </Modal>

          {/* Choose a payment method */}
          <Modal
            show={showChoosePayment}
            onHide={handleChoosePaymentClose}
            className="choose_paymentMethod_modal"
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h3>Choose a payment method</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="mt-2">
                <Outline
                  children="+ Add New card"
                  variant="outline"
                  size="mediumbtn"
                  onClick={() => {
                    handleChoosePaymentClose();
                    handlePaymentShow();
                  }}
                  buttonWidth="100%"
                />
              </div>

              {paymentList.length != 0 ? (
                <SavedCardCheckoutUpdateForm
                  customerId={cusDetails}
                  priceId={priceDetails}
                  planId={planId}
                  paymentList={paymentList}
                  familyName={formValues.familyName}
                  planName={subscriptionPlans}
                  planDetails={planDetails}
                  SubscriptionId={SubscriptionId}
                  priceDetails={priceDetails}
                />
              ) : (
                <>
                  <p className="mt-4">Saved cards ( 0 )</p>
                  <div className="text-center saved-card-empty">
                    No saved cards
                  </div>
                </>
              )}
            </Modal.Body>
          </Modal>

          {/* Payment modal */}
          <Modal
            show={showPayment}
            onHide={handlePaymentClose}
            className="payment_modal"
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h3>Add New Card</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="mb-3 subtext">
                Add the details below to save a new card
              </p>
              <CheckoutForm
                customerId={cusDetails}
                priceId={priceDetails}
                paymentList={paymentList}
                planId={planId}
                familyName={formValues.familyName}
                planName={subscriptionPlans}
                planDetails={planDetails}
                SubscriptionId={SubscriptionId}
                priceDetails={priceDetails}
              />
            </Modal.Body>
          </Modal>

          {/* Cancel Payment modal */}
          <Modal
            show={showCancelAccount}
            onHide={handleCloseCancelAccount}
            className="choose_paymentMethod_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h3>Cancel Account Plan</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="cancel-plan-txt">
                You have opted to cancel your Group account plan on Elderly
                Life.
              </div>
              <div className="cancel-plan-content">
                Members of your Group will loose access to the data that they
                have created on Elderly Life.
              </div>
              <p>Do you want to continue?</p>
              <div className="cancel-account-btns align-center">
                <Primary
                  children="Cancel"
                  size="mediumbtn"
                  variant="info"
                  type=""
                  buttonWidth="120px"
                  font_Weight="500"
                  onClick={handleCloseCancelAccount}
                />
                <Primary
                  children="Yes"
                  size="mediumbtn"
                  variant="primary"
                  type=""
                  buttonWidth="120px"
                  font_Weight="700"
                  onClick={(e) => cancelFamily(e)}
                />
              </div>
            </Modal.Body>
          </Modal>

          {/* cancel reason popup show */}
          <Modal
            show={cancelReason}
            onHide={cancelFamilyHide}
            className="choose_paymentMethod_modal"
            size={"lg"}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h3>Reason for cancellation</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <textarea
                id="w3review"
                name="w3review"
                style={{
                  height: "5rem",
                  width: "100%",
                  margin: "0.5rem 0 0 0",
                  padding: "12px",
                }}
                onChange={textField}
                maxlength="100"
              ></textarea>
              <span style={{ fontSize: "13px", fontWeight: "600" }}>
                * The field is optional
              </span>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Primary
                  children="Submit"
                  size="mediumbtn"
                  variant="primary"
                  type=""
                  buttonWidth="120px"
                  font_Weight="700"
                  onClick={(e) => cancelFamilySuccessfully(e)}
                />
              </div>
            </Modal.Body>
          </Modal>

          {/* trial account popup show */}
          <Modal
            show={showTrialPopup}
            onHide={handleCloseTrialPopup}
            className="choose_paymentMethod_modal"
            size={"lg"}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h3>Cancel subscription</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="cancel-plan-txt" style={{ margin: "1rem 0" }}>
                Is there something that we can do to change your mind? How about
                extending the free trial for another 7 days?
              </div>
              {/* <div className="cancel-plan-content">
              Members of your Family will loose access to the data that they
              have created on Elderly Life.
            </div> */}
              {/* <p>Do you want to continue?</p> */}
              <div className="cancel-account-btns align-center">
                <Primary
                  children="Please cancel the subscription"
                  size="mediumbtn"
                  variant="info"
                  type=""
                  buttonWidth="17rem"
                  font_Weight="500"
                  onClick={(e) => cancelFamily(e)}
                />
                <Primary
                  children=" I would like to extend Free trial"
                  size="mediumbtn"
                  variant="primary"
                  type=""
                  buttonWidth="17rem"
                  font_Weight="700"
                  onClick={(e) => cancelFamilyTrial(e)}
                />
              </div>
            </Modal.Body>
          </Modal>
        </Container>
      </div>
    </>
  );
};
export default AccountDowngradeSubscription;
