import React from "react";

const Loading = () => {
  return (
    <div
      className="d-flex m-auto justify-content-center h-100"
      style={{ flexGrow: "1" }}
    >
      <button className="btn" type="button">
        <span
          className="spinner-border spinner-border-sm me-2"
          role="status"
        ></span>
        Loading . . .
      </button>
    </div>
  );
};

export default Loading;
