import React from "react";
import BenefitsCard from "../components/Cards/BenefitsCard";

export default {
  title: "Cards/Benefits",
  component: BenefitsCard,
  args: {
    message: "ActionAlert message",
  },
};

const Template = (args) => <BenefitsCard {...args} />;

export const BenefitsCardVal = Template.bind({});
BenefitsCardVal.args = {
  variant: "basic",
};
