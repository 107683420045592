import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import moment from "moment";
import { Link, useParams, useHistory } from "react-router-dom";
import UserService from "../../services/user.service";
import Loading from "../../common/Loading";
import FileViewer from "react-file-viewer";
import PDFViewer from "pdf-viewer-reactjs";
import ToastBlockContainer from "../../common/ToastBlockContainer";

const ViewSubscriptionDetails = () => {
  let history = useHistory();
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const [subscriptionEditUrl, setSubscriptionEditUrl] = useState("");
  const { uuid, subId, userUUID } = useParams();
  const [showDocSub, setShowDocSub] = useState(false);
  const [fileName, setFileName] = useState("");
  const [loader, setLoader] = useState(true);
  const [dateEnd, setDateEnd] = useState(false);
  const [showDeleteSub, setShowDeleteSub] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [parsedDoc, setParsedDoc] = useState(false);

  useEffect(() => {
    // get single subscription details by ID
    UserService.getSubscriptionDetailsById(subId).then(
      (response) => {
        setSubscriptionDetails(response.data);

        // Convert back to array
        const parsedUrls = response.data.rows[0].documentUrl
          ? JSON.parse(response.data.rows[0].documentUrl)
          : response.data.rows[0].documentUrl;

        setParsedDoc(parsedUrls);

        const date = new Date(response.data.rows[0].endDate);
        const year = date.getFullYear();

        if (year > 2100) {
          setDateEnd(true);
        } else {
          setDateEnd(false);
        }

        if (response.data.rows) {
          if (userUUID) {
            setSubscriptionEditUrl(
              "/edit_user_outgoing/" +
                uuid +
                "/" +
                response.data.rows[0].id +
                "/" +
                userUUID
            );
          } else {
            setSubscriptionEditUrl(
              "/edit_outgoing/" + uuid + "/" + response.data.rows[0].id
            );
          }
        } else {
          setSubscriptionEditUrl("#");
        }
        setLoader(false);
      },
      (error) => {}
    );
  }, []);

  // Show doc modal
  const handleShowDocSubShow = (data) => {
    setShowDocSub(true);
    setFileName(data);
  };

  // Get the file extension from the URL
  const get_url_extension = (url) => {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  };

  // Close doc model
  const handleShowDocSubClose = () => {
    setShowDocSub(false);
  };

  // Close delete confirmation
  const handleShowDeleteModal = () => {
    setShowDeleteSub(true);
  };

  // Close delete confirmation
  const handleShowDeleteSubClose = () => {
    setShowDeleteSub(false);
  };

  // Delete subscription
  const handleDeleteSubscription = (subId) => {
    setShowDeleteSub(false);
    UserService.deleteSubscription(subId).then(
      (response) => {
        setShowToast(true);
        setSuccessToast(true);
        setToastMessage("Outgoing deleted successfully");
        window.scrollTo(0, 0);

        setTimeout(() => {
          history.push(`/myoutgoings/${uuid}`);
        }, 2500);
      },
      (error) => {}
    );
  };

  const validateDate = (date) => {
    // Get today's date
    const today = new Date();

    // Create the target date from the ISO string
    const targetDate = new Date(date);

    // Calculate the time difference in milliseconds
    const timeDifference = targetDate - today;

    // Convert the time difference from milliseconds to days
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  };

  return (
    <Container>
      <ToastBlockContainer
        successToast={successToast}
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <Row className="justify-content-center gy-0 mb-6rem">
        <Col xl="21" xxl="18">
          <h1 className="lh-base mb-4">Outgoings</h1>
          <section className="bg-white p-4 p-md-6 p-lg-8 shadow rounded-4">
            {loader ? (
              <>
                <Loading />
              </>
            ) : (
              <>
                <section>
                  <Row className="gy-3 gy-md-4 gy-lg-0 mb-3 mb-md-4">
                    <Col xs lg="15">
                      <h2
                        className={`${
                          subscriptionDetails.rows &&
                          subscriptionDetails.rows[0].productDescription
                            ? "mb-1 mb-md-2"
                            : "mb-0"
                        }`}
                      >
                        {subscriptionDetails.rows
                          ? subscriptionDetails.rows[0].productName
                          : ""}
                      </h2>
                      {subscriptionDetails.rows &&
                        subscriptionDetails.rows[0].productDescription !==
                          "" && (
                          <p className="mb-0">
                            {subscriptionDetails.rows[0].productDescription}
                          </p>
                        )}
                    </Col>
                    <Col
                      xs="auto"
                      className="order-lg-last ms-auto"
                      style={{ height: "0" }}
                    >
                      <Link
                        to={subscriptionEditUrl}
                        className="btn btn-white btn-sm btn-square d-inline-flex d-md-none"
                      >
                        <i className="fa-solid fa-edit"></i>
                      </Link>
                      <Link
                        to={subscriptionEditUrl}
                        className="btn btn-white btn-sm d-none d-md-inline-block"
                      >
                        <i className="fa-solid fa-edit me-2"></i>Edit
                      </Link>
                      <button
                        className="btn btn-danger btn-sm btn-square d-inline-flex ms-2"
                        onClick={() => handleShowDeleteModal()}
                      >
                        <i className="fa-solid fa-trash text-white"></i>
                      </button>
                    </Col>
                    {/* unless lg breakpoint */}
                    <Col lg="auto" className="ms-auto d-lg-none">
                      <div className="small fw-medium">Price</div>
                      <p className="h2 text-primary-dark mb-0">
                        £
                        {subscriptionDetails.rows
                          ? subscriptionDetails.rows[0].premiumAmount
                          : ""}
                      </p>
                    </Col>
                    {/* if lg breakpoint */}
                    <Col
                      lg="auto"
                      className="ms-auto d-none d-lg-block"
                      style={{ height: "0" }}
                    >
                      <div className="small fw-medium">Price</div>
                      <p className="h2 text-primary-dark mb-0">
                        £
                        {subscriptionDetails.rows
                          ? subscriptionDetails.rows[0].premiumAmount
                          : ""}
                      </p>
                    </Col>
                  </Row>

                  <Row className="gy-3 gy-md-0 mb-5">
                    <Col xs="16" md="8">
                      <div className="small fw-medium">Category</div>
                      <div className="d-flex align-items-center gap-2 mt-1">
                        <p className="text-dark fw-medium mb-0">
                          {subscriptionDetails.rows &&
                          subscriptionDetails.rows[0].Category &&
                          subscriptionDetails.rows[0].Category.name
                            ? subscriptionDetails.rows[0].Category.name
                            : "--"}
                        </p>
                      </div>
                    </Col>
                  </Row>

                  <Row className="gy-3 gy-md-0">
                    <Col xs="16" md="8">
                      <div className="small fw-medium">Supplier</div>
                      <div className="d-flex align-items-center gap-2 mt-2">
                        <img
                          className="img-third-party-logo"
                          src={
                            subscriptionDetails.rows &&
                            subscriptionDetails.rows[0].Supplier
                              ? subscriptionDetails.rows[0].Supplier.logo
                              : "https://el-publicassets.s3.eu-west-2.amazonaws.com/images/Supplier+default+logo.png"
                          }
                          alt="supplier logo"
                          style={{
                            borderRadius: "50%",
                            border: "1px solid #a09e9e",
                          }}
                        />
                        <p className="text-dark fw-medium mb-0">
                          {subscriptionDetails.rows &&
                          subscriptionDetails.rows[0].Supplier
                            ? subscriptionDetails.rows[0].Supplier.name ===
                              "Others"
                              ? subscriptionDetails.rows[0].otherSupplierName
                              : subscriptionDetails.rows[0].Supplier.name
                            : "N/A"}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </section>
                <hr className="my-6 my-md-7" />
                <section>
                  <h3 className="mb-3 mb-md-4">Payment</h3>
                  <Row className="gy-3 gy-md-0 mb-3 mb-md-4">
                    <Col md="8">
                      <div className="small fw-medium">Start date</div>
                      <p className="text-dark fw-semibold mb-0">
                        {subscriptionDetails.rows
                          ? moment(
                              subscriptionDetails.rows[0].startDate
                            ).format("DD/MM/YYYY")
                          : "-"}
                      </p>
                    </Col>
                    <Col md="8">
                      <div className="small fw-medium">End date</div>
                      <p
                        className={`${
                          validateDate(
                            subscriptionDetails.rows &&
                              subscriptionDetails.rows[0].endDate
                          ) <= 30
                            ? "text-danger"
                            : validateDate(
                                subscriptionDetails.rows &&
                                  subscriptionDetails.rows[0].endDate
                              ) <= 60 &&
                              validateDate(
                                subscriptionDetails.rows &&
                                  subscriptionDetails.rows[0].endDate
                              ) > 30
                            ? "text-orange"
                            : validateDate(
                                subscriptionDetails.rows &&
                                  subscriptionDetails.rows[0].endDate
                              ) <= 90 &&
                              validateDate(
                                subscriptionDetails.rows &&
                                  subscriptionDetails.rows[0].endDate
                              ) > 60
                            ? "text-lightgreen"
                            : "text-success"
                        } fw-semibold mb-0`}
                      >
                        {subscriptionDetails.rows &&
                        subscriptionDetails.rows[0].endDate &&
                        !dateEnd
                          ? moment(subscriptionDetails.rows[0].endDate).format(
                              "DD/MM/YYYY"
                            )
                          : "--"}
                      </p>
                    </Col>
                  </Row>
                  <Row className="gy-3 gy-md-0">
                    <Col md="12">
                      <div className="small fw-medium">Billing term</div>
                      <div className="d-flex align-items-center">
                        <p className="text-dark fw-medium mb-0 me-3">
                          {subscriptionDetails.rows
                            ? subscriptionDetails.rows[0].renewalFrequency
                            : ""}
                        </p>
                        {subscriptionDetails.rows &&
                          subscriptionDetails.rows[0].reminder && (
                            <div
                              className="d-flex ms-2 align-items-start"
                              style={{ marginTop: "-2px" }}
                            >
                              <span className="badge badge-xs d-inline-flex align-items-center badge-primary-light">
                                <i className="fa-solid fa-sm fa-bell"></i>
                                <span className="ms-1">Reminder</span>
                              </span>
                            </div>
                          )}
                      </div>
                    </Col>
                  </Row>
                </section>
                <hr className="my-6 my-md-7" />
                <section>
                  <h3 className="mb-3 mb-md-4">
                    {parsedDoc && parsedDoc.length > 1
                      ? "Uploaded attachments"
                      : "Uploaded attachment"}
                  </h3>
                  <Row className="gy-0">
                    {parsedDoc && parsedDoc.length > 0 ? (
                      parsedDoc.map((data, index) => (
                        <Col md="12" lg="8">
                          <p
                            className="btn btn-white btn-sm d-flex p-3"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => handleShowDocSubShow(data)}
                          >
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250 }}
                              overlay={
                                <Tooltip id="">
                                  <div className="">
                                    {data.split("/").pop()}
                                  </div>
                                </Tooltip>
                              }
                            >
                              <span
                                className="fw-medium text-black"
                                style={{
                                  display: "block",
                                  whiteSpace: "nowrap", // Prevent text from wrapping
                                  overflow: "hidden", // Hide overflowing text
                                  textOverflow: "ellipsis", // Add ellipsis for overflowing text
                                }}
                              >
                                <i className="fa-solid fa-file me-1"></i>{" "}
                                {data.split("/").pop()}
                              </span>
                            </OverlayTrigger>
                          </p>
                        </Col>
                      ))
                    ) : (
                      <p className="view-txt fw-medium">
                        No documents available
                      </p>
                    )}
                  </Row>
                </section>

                {/* uploaded document   */}
                <Modal
                  show={showDocSub}
                  onHide={handleShowDocSubClose}
                  className=""
                  size="lg"
                >
                  <Modal.Header
                    closeButton
                    className="d-flex align-items-center justify-content-between"
                  >
                    <Modal.Title className="mx-1">
                      <h3 className="mb-0">View Attachment</h3>
                    </Modal.Title>

                    <Link
                      to={{
                        pathname: fileName,
                      }}
                      target="_blank"
                      className="ms-auto position-absolute"
                      style={{
                        right: "4rem",
                        color: "black",
                        height: "20px",
                      }}
                    >
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip">
                            Download this file
                          </Tooltip>
                        }
                      >
                        <i class="fa-solid fa-download"></i>
                      </OverlayTrigger>
                    </Link>
                  </Modal.Header>
                  <Modal.Body
                    className="mx-1 overflow-auto"
                    style={{ maxHeight: "75vh" }}
                  >
                    <div className="document-container mb-5">
                      {get_url_extension(fileName) === "pdf" ? (
                        <PDFViewer
                          document={{
                            url: fileName,
                          }}
                          className="w-100 h-100"
                        />
                      ) : get_url_extension(fileName) === "png" ||
                        get_url_extension(fileName) === "jpg" ||
                        get_url_extension(fileName) === "jpeg" ? (
                        <img
                          src={fileName}
                          alt={`Document`}
                          className="w-100 h-100"
                        />
                      ) : get_url_extension(fileName) === "doc" ||
                        get_url_extension(fileName) === "docx" ? (
                        <FileViewer
                          fileType="docx"
                          filePath={fileName}
                          className="w-100 h-100"
                        />
                      ) : (
                        <p>
                          Unsupported file type: {get_url_extension(fileName)}
                        </p>
                      )}
                    </div>
                  </Modal.Body>
                  {/* <Modal.Footer>
                    <Link
                      to={{
                        pathname: fileName,
                      }}
                      target="_blank"
                    >
                      Download
                    </Link>
                  </Modal.Footer> */}
                </Modal>
              </>
            )}
          </section>
        </Col>
      </Row>

      {/* // Delete subscription */}
      <Modal
        show={showDeleteSub}
        onHide={handleShowDeleteSubClose}
        className="invite_au_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="h3 mb-0">Delete Outgoing</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            All the Outgoing related details will be deleted permanently from
            the system.
          </p>
          <p className="mb-5">Do you want to continue?</p>
          <Button variant="white" onClick={handleShowDeleteSubClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => handleDeleteSubscription(subId)}
            className="ms-3"
          >
            Yes
          </Button>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ViewSubscriptionDetails;
