/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import UserService from "../../services/user.service";
import { Category } from "../../stories/CategoryCard.stories";
import Loading from "../../common/Loading";
import layer from "../../assets/images/layer1.svg";
import InfiniteScroll from "react-infinite-scroll-component";

const CompareAndSave = (props) => {
  const [loadSpin, setloadspin] = useState(true);
  const [saveCompareList, setSaveCompareList] = useState([]);
  const [loadingspinner, setLoadingspinner] = useState(true);
  const [limit, setlimit] = useState(50);
  const [offset, setoffset] = useState(0);
  const [hasMore, sethasMore] = useState(false);
  const [shown, setShown] = useState(true);
  const [srcLink, setsrcLink] = useState("");
  const [totalItems, setTotalItems] = useState([]); // Total items, needed to calculate total pages

  // The desired order
  const order = [
    "Car Insurance",
    "Home Insurance",
    "Travel Insurance",
    "Pet Insurance",
    "Breakdown Insurance",
    "Bike Insurance",
    "Van Insurance",
    "Bicycle Insurance",
    "Caravan Insurance",
    "Campervan Insurance",
    "Motorhome Insurance",
    "Boat Insurance",
    "Holiday home Insurance",
    "Landlords Insurance",
    "Taxi Insurance",
    "Minibus Insurance",
    "Horse Insurance",
    "Horsebox Insurance",
    "Income Protection Insurance",
  ];

  useEffect(() => {
    // get compare and save listing for max length in pagination
    let maxLimit = {
      limit: 9007199254740991, // Max safe integer limit
      offset: offset,
    };
    UserService.getSaveCompareListNew(maxLimit).then(
      (response) => {
        // Sort the array based on the order array and the name property
        const newArr = response.result.sort((a, b) => {
          const indexA = order.indexOf(a.name);
          const indexB = order.indexOf(b.name);

          // If a name is not found in the order array, it will be placed at the end
          return (
            (indexA === -1 ? Infinity : indexA) -
            (indexB === -1 ? Infinity : indexB)
          );
        });

        setTotalItems(newArr); // Store the total number of items for pagination

        setSaveCompareList(newArr.slice(0, limit)); // Set the first batch of items

        if (newArr.slice(0, limit).length < newArr.length) {
          sethasMore(true);
        } else {
          sethasMore(false);
        }
        setLoadingspinner(false);
      },
      (error) => {
        setLoadingspinner(false);
      }
    );
  }, []);

  useEffect(() => {
    if (saveCompareList.length >= totalItems.length) {
      sethasMore(false); // No more data to load
    }
  }, [saveCompareList, totalItems]);

  const fetchMoreData = () => {
    if (saveCompareList.length < totalItems.length) {
      setTimeout(() => {
        const newOffset = offset + limit; // Increase the offset
        const start = newOffset;
        const end = start + limit;

        // Slice the next batch of data
        const nextBatch = totalItems.slice(start, end);

        setSaveCompareList((prevList) => [...prevList, ...nextBatch]); // Append new data to the list
        setoffset(newOffset); // Update the offset
      }, 250);
    } else {
      sethasMore(false); // No more data to load
    }
  };
  const VideoModal = (value) => {
    window.scrollTo(0, 0);
    setsrcLink(value.partnerLinks[0]);
    setShown(!shown);
    setloadspin(true);
  };

  const reloadbtn = () => {
    window.location.reload();
  };

  const hideSpinner = () => {
    setloadspin(false);
  };

  return (
    <>
      <div className="page-content save-compare-pg">
        <Container
          fluid
          style={{ minHeight: "calc(100vh - 17rem)", marginBottom: "6rem" }}
          className="d-flex flex-column"
        >
          {loadingspinner ? (
            <>
              <Loading />
            </>
          ) : shown ? (
            <>
              <Row className="pt-3">
                <h3>Compare and Save</h3>
                <p className="sub-title">
                  You can claim and utilise wide range of benefits from
                  different categories on Elderly Life
                </p>
              </Row>
              {saveCompareList && saveCompareList.length > 0 ? (
                <InfiniteScroll
                  dataLength={saveCompareList.length} // Set the length of the current data
                  next={fetchMoreData} // Trigger to load more data
                  hasMore={hasMore} // Check if more data is available
                  style={{
                    overflow: "visible",
                  }}
                  loader={
                    <div className="d-flex align-items-center gap-2 my-5 justify-content-center">
                      <span
                        className="spinner-border spinner-border-sm mr-2"
                        role="status"
                      ></span>
                      <span>Loading . . .</span>
                    </div>
                  }
                >
                  <Row className="mt-1 mb-3 compareAndSave-card d-flex flex-wrap gy-5">
                    {saveCompareList.map((compareData, index) =>
                      compareData.name != null ? (
                        <Col
                          md="8"
                          lg="6"
                          key={index}
                          onClick={() => VideoModal(compareData)}
                          style={{ cursor: "pointer" }}
                        >
                          <Category
                            heading={compareData.name ? compareData.name : ""}
                            value=""
                            variant="category"
                            cardImage={compareData.icon ? compareData.icon : ""}
                          />
                        </Col>
                      ) : (
                        ""
                      )
                    )}
                  </Row>
                </InfiniteScroll>
              ) : (
                <Row className="text-center">
                  <p className="p-5 m-5">No data found</p>
                </Row>
              )}
            </>
          ) : (
            <>
              <button
                className="d-flex align-items-center btn btn-primary"
                style={{ width: "fit-content" }}
                onClick={() => reloadbtn()}
              >
                {" "}
                <img
                  className=""
                  src={layer}
                  alt="Profile Avatar"
                  style={{ "margin-right": "15px" }}
                />{" "}
                <span>Back to Compare and Save</span>
              </button>
              {loadSpin ? (
                <div
                  className="d-flex m-auto"
                  style={{ minHeight: "calc(100vh - 17rem)" }}
                >
                  <Loading />
                </div>
              ) : null}
              <div
                className={`p-5 border-2 bg-white ${
                  loadSpin ? "d-none" : "d-block"
                } mt-5`}
                style={{ borderRadius: "8px" }}
              >
                <iframe
                  src={srcLink}
                  width="100%"
                  height="450"
                  onLoad={hideSpinner}
                ></iframe>
              </div>
            </>
          )}
        </Container>
      </div>
    </>
  );
};
export default CompareAndSave;
