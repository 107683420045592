import React from "react";
import { Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <footer
      className="container-fluid small text-muted py-2 py-md-3 mt-auto position-fixed bottom-0"
      style={{ background: "#e1fdf7" }}
    >
      <Row className="justify-content-center gy-1 gy-md-0">
        <Col md="auto" className="text-center mx-auto">
          ©{new Date().getFullYear()} Elderly Life Ltd. All rights reserved
        </Col>
        <Col xs="auto" md="auto" className="order-md-first">
          <a
            className="text-reset"
            href="https://elderlylife.com/terms-and-conditions"
            target="_blank"
            rel="noreferrer"
          >
            Terms and conditions
          </a>
        </Col>
        <Col xs="auto" md="auto" className="me-5">
          <a
            className="text-reset"
            href="https://elderlylife.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy policy
          </a>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
