import React from "react";
import { Row, Col } from "react-bootstrap";

function PaymentMtdCard({
  setShowPaymentMtd,
  paymentMethodLoading,
  activePaymentMethod,
}) {
  return (
    <Row className="m-0">
      <Col
        md="18"
        lg="16"
        xl="12"
        xxl="12"
        className="p-4 border rounded bg-white mb-5"
        style={{ boxShadow: "rgba(0, 123, 255, 0.35) 0px 5px 15px" }}
      >
        {!paymentMethodLoading && (
          <div className="p-3">
            <div>
              <h3 className="mb-3">{activePaymentMethod.card.brand}</h3>
              <h3 className="mb-3">
                **** **** **** {activePaymentMethod.card.last4}
              </h3>
              <h4 className="d-flex align-items-center justify-content-between">
                <div>{activePaymentMethod.billing_details.name}</div>
                <div>
                  {activePaymentMethod.card.exp_month}
                  <strong>/</strong>
                  {activePaymentMethod.card.exp_year}
                </div>
              </h4>
            </div>
            <button
              onClick={() => {
                setShowPaymentMtd(true);
              }}
              className="btn btn-primary mt-5"
            >
              Change Card
            </button>
          </div>
        )}
        {paymentMethodLoading && (
          <div>
            <div className="text-center">
              <button className="btn loading-btn p-0" type="button">
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                ></span>
                Loading Your Payment Method
              </button>
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
}

export default PaymentMtdCard;
