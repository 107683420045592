import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import { Primary } from "../../stories/Button.stories";
import { Link, useHistory } from "react-router-dom";
import CheckGreen from "../../assets/images/check-green.svg";

const FamilyUpdateConfirmation = (props) => {
  let history = useHistory();
  const routeChange = () => {
    history.push({
      pathname: `/dashboard/${props.location.familyUUID}`,
    });
  };

  return (
    <div className="page-content confirmation-pg">
      <Container>
        <div className="text-center confrm-div">
          <h3>Congratulations !!</h3>
          <Image
            className="hello-text-img my-4"
            src={CheckGreen}
            alt="Check mark"
            width="18%"
          />
          <p>
            Your Family has been updated successfully with the selected
            subscription
          </p>
          <Primary
            children="Visit your Family page"
            size="mediumbtn"
            type=""
            variant="primary"
            buttonWidth="45%"
            onClick={routeChange}
          />
        </div>
      </Container>
    </div>
  );
};
export default FamilyUpdateConfirmation;
