import React, { useState, useEffect, useRef } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import Loading from "../../common/Loading";
import moment from "moment";

const FamilyDashboardSubscriptions = (props) => {
  let history = useHistory();
  let { uuid, userUUID } = useParams();
  const parentUserUUId = localStorage.getItem("parentUser.uuid");
  const [addSubscriptionUrl, setAddSubscriptionUrl] = useState("");
  const [loadingspinner, setLoadingspinner] = useState(true);

  const viewDetailsRoute = (subId) => {
    if (userUUID) {
      history.push(`/view_user_outgoing_details/${uuid}/${subId}/${userUUID}`);
    } else {
      history.push(`/view_outgoing_details/${uuid}/${subId}`);
    }
  };

  useEffect(() => {
    // get family details
    if (userUUID) {
      setAddSubscriptionUrl("/add_user_outgoing/" + uuid + "/" + userUUID);
    } else {
      setAddSubscriptionUrl("/add_outgoing/" + uuid);
    }
  }, []);

  useEffect(() => {
    if (props.allData) {
      setLoadingspinner(false);
    }
  }, [props.allData]);

  return (
    <div>
      <div className="d-flex mb-3">
        <h2 className="me-auto mb-0">Outgoings</h2>
        <Link
          className="btn btn-white btn-sm btn-circle flex-shrink-0"
          to={addSubscriptionUrl}
        >
          <i className="fa-solid fa-sm fa-plus text-dark"></i>
        </Link>
        <Link
          className="btn btn-white btn-sm btn-circle flex-shrink-0 ms-2"
          to={
            uuid
              ? !parentUserUUId
                ? `/myoutgoings/${uuid}`
                : `/user_myoutgoings/${uuid}/${parentUserUUId}`
              : "#"
          }
        >
          <i className="fa-solid fa-sm fa-angle-right text-dark"></i>
        </Link>
      </div>
      {loadingspinner ? (
        <>
          <div className="loadind-page">
            <Loading />
          </div>
        </>
      ) : (
        <>
          <Row className="gx-3 gy-2 gx-md-5 gx-lg-7 mb-3">
            <Col xs="12" md="auto">
              <div className="small fw-medium">This month</div>
              <div className="h1 mb-0">
                £{" "}
                {Number.isInteger(props.allData.outgoingKPIData.this_month)
                  ? props.allData.outgoingKPIData.this_month.toLocaleString(
                      "en-US"
                    )
                  : Math.trunc(
                      props.allData.outgoingKPIData.this_month
                    ).toLocaleString("en-US")}
              </div>
            </Col>
            <Col xs="12" md="auto">
              <div className="small fw-medium">Next month</div>
              <div className="h1 mb-0">
                £{" "}
                {Number.isInteger(props.allData.outgoingKPIData.next_month)
                  ? props.allData.outgoingKPIData.next_month.toLocaleString(
                      "en-US"
                    )
                  : Math.trunc(
                      props.allData.outgoingKPIData.next_month
                    ).toLocaleString("en-US")}
              </div>
            </Col>
            <Col xs="12" md="auto">
              <div className="small fw-medium">Average monthly cost</div>
              <div className="h1 mb-0">
                £{" "}
                {Number.isInteger(props.allData.outgoingKPIData.avgMonthlycosts)
                  ? props.allData.outgoingKPIData.avgMonthlycosts.toLocaleString(
                      "en-US"
                    )
                  : Math.trunc(
                      props.allData.outgoingKPIData.avgMonthlycosts
                    ).toLocaleString("en-US")}
              </div>
            </Col>
          </Row>
          {props.allData.outGoingdata.length === 0 ? (
            <p className="small mb-0">
              You currently have no Outgoings set up!
            </p>
          ) : (
            <>
              {props.allData.outGoingdata.map((subData, index) => (
                <>
                  <hr className={index === 0 ? "mb-3" : "my-3"} />
                  <Row
                    className="align-items-center gx-3 gy-0 gx-md-4 gx-xl-2"
                    onClick={() => viewDetailsRoute(subData.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <Col xs="auto">
                      <div className="d-flex align-items-center gap-2">
                        <Link
                          className="btn btn-datestamp flex-shrink-0"
                          to="#"
                        >
                          <div>
                            <span className="datestamp-month">
                              {moment(subData.startDate).format("MMM")}
                            </span>
                            <span className="datestamp-day">
                              {moment(subData.startDate).format("D")}
                            </span>
                          </div>
                        </Link>
                        <div class="img-wrapper img-wrapper-warranty-product flex-shrink-0 d-none d-md-flex">
                          <img
                            src={
                              subData.Supplier && subData.Supplier.logo
                                ? subData.Supplier.logo
                                : "--"
                            }
                            alt="logo"
                            className="img-fluid w-100 h-100"
                          />
                        </div>
                      </div>
                    </Col>

                    <Col xs="10" md="10" lg="9" xl="6" xxl="8">
                      <div className="small fw-semibold text-dark text-truncate">
                        {subData.Supplier && subData.Supplier.name
                          ? subData.Supplier.name === "Others"
                            ? subData.otherSupplierName
                            : subData.Supplier.name
                          : "--"}
                      </div>
                    </Col>
                    <Col
                      className="d-none d-md-block"
                      md="4"
                      lg="3"
                      xl="4"
                      xxl="3"
                    >
                      <div className="d-inline small fw-medium text-dark">
                        {subData.renewalFrequency}
                      </div>
                    </Col>
                    <Col className="col-lg-4 col-xl d-none d-lg-block">
                      <div className="d-inline small fw-semibold text-warning">
                        <i className="fa-solid fa-stopwatch me-2 me-xl-1 me-xxl-2"></i>
                        <span>
                          <span class="d-xl-none d-xxl-inline">Ends </span>
                          {moment(subData.endDate).format("D MMM, YYYY")}
                        </span>
                      </div>
                    </Col>
                    <Col
                      xs="6"
                      md="4"
                      lg="3"
                      xl="4"
                      xxl="3"
                      className="text-end ms-auto"
                    >
                      <div className="d-inline small text-dark">
                        £{subData.premiumAmount}
                      </div>
                    </Col>
                    <Col xs="auto" className="text-end">
                      <Button
                        className="btn-sm btn-square btn-interactive"
                        variant="link"
                        onClick={() => viewDetailsRoute(subData.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="fa-solid fa-sm fa-chevron-right"></i>
                      </Button>
                    </Col>
                  </Row>
                </>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default FamilyDashboardSubscriptions;
