import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Loading from "../../common/Loading";
import ToastBlockContainer from "../../common/ToastBlockContainer";

const MySubscriptions = (props) => {
  const myRef = useRef(null);
  let history = useHistory();
  let { uuid, userUUID } = useParams();
  const UserDetails = JSON.parse(localStorage.getItem("UserDetails"));

  const filterOption = [
    { value: "all", label: "All Outgoings" },
    { value: "Monthly", label: "Monthly" },
    { value: "Quarterly", label: "Quarterly" },
    { value: "Bi-Annually", label: "Bi Annually" },
    { value: "Yearly", label: "Yearly" },
  ];
  const sortOption = [
    { value: "createdAt", label: "Created" },
    { value: "updatedAt", label: "Updated" },
    { value: "endDate", label: "Validity" },
    { value: "premiumAmount", label: "Price" },
  ];
  const [limit, setlimit] = useState(50);
  const [offset, setoffset] = useState(0);
  const [monthType, setMonthType] = useState("all");

  const [familyDetails, setFamilyDetails] = useState([]);
  const [userSubscriptionList, setUserSubscriptionList] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [showSearchText, setShowSearchText] = useState(false);
  const [addSubscriptionUrl, setAddSubscriptionUrl] = useState("");
  const [title, setTitle] = useState("Outgoings");
  const [isEmpty, setIsEmpty] = useState(false);
  const [monthlyCost, setMonthlyCost] = useState("£0");
  const [subId, setSubId] = useState(false);
  const [showDeleteSub, setShowDeleteSub] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [loadingspinner, setLoadingspinner] = useState(true);
  const [loading, setLoading] = useState(true);
  const [familyId, setFamilyId] = useState();
  const [familyUserId, setFamilyUserId] = useState();
  const executeScroll = () => myRef.current.scrollIntoView();
  const viewDetailsRoute = (subId) => {
    if (userUUID) {
      history.push(`/view_user_outgoing_details/${uuid}/${subId}/${userUUID}`);
    } else {
      history.push(`/view_outgoing_details/${uuid}/${subId}`);
    }
  };
  const editSubscriptionRoute = (subId) => {
    if (userUUID) {
      history.push(`/edit_user_outgoing/${uuid}/${subId}/${userUUID}`);
    } else {
      history.push(`/edit_outgoing/${uuid}/${subId}`);
    }
  };

  const [filterValue, setfilterValue] = useState("all");
  const [searchValue, setsearchValue] = useState("");
  const [sortValue, setsortValue] = useState("endDate");
  const [orderValue, setorderValue] = useState("DESC");

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState({
    value: "all",
    label: "All Outgoings",
  }); // Selected option for first dropdown

  const [selectedSort, setSelectedSort] = useState({
    value: "endDate",
    label: "Validity",
  }); // Selected option for sort dropdown
  const [isSortDropdown, setIsSortDropdown] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0); // Total items, needed to calculate total pages
  const [mounted, setMounted] = useState(false); // Tracks if the component has fully loaded

  useEffect(() => {
    setAddSubscriptionUrl("/add_outgoing/" + uuid);
    UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
      (response) => {
        setFamilyDetails(response);
        let familyUserId = response.FamilyData.id;
        let familyId = response.FamilyData.Family.id;

        UserService.getKPISub({
          fid: familyId,
          uid: familyUserId, //
        }).then(
          (response) => {
            setMonthlyCost(response.data);
          },
          (error) => {}
        );

        let maxLimit = {
          userId: familyUserId,
          familyId: familyId,
          searchStr: searchValue,
          sort: sortValue,
          order: orderValue,
          limit: 9007199254740991, // Max safe integer limit
          offset: 0,
          filtBy: filterValue,
          monthType: monthType,
        };
        UserService.getUserSubscriptionListNew(maxLimit).then(
          (response) => {
            setTotalItems(response.data); // Store the total number of items for pagination
            response.data.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
          },
          (error) => {}
        );

        let params = {
          userId: familyUserId,
          familyId: familyId,
          searchStr: searchValue,
          sort: sortValue,
          order: orderValue,
          limit: limit,
          offset: offset,
          filtBy: filterValue,
          monthType: monthType,
        };

        setLoading(true);
        UserService.getUserSubscriptionListNew(params).then(
          (response) => {
            setUserSubscriptionList(response.data);
            setTimeout(() => {
              setLoadingspinner(false);
              setMounted(true); // Set mounted to true after the initial load
            }, 250);
          },
          (error) => {}
        );
        setFamilyId(familyId);
        setFamilyUserId(familyUserId);
      },
      (error) => {}
    );
  }, []);

  // Get Subscription List
  const getSubscriptions = (params) => {
    setLoadingspinner(true);
    let Max = { ...params }; // Clone params to avoid modifying it directly
    Max.limit = 9007199254740991; // Update the limit property

    UserService.getUserSubscriptionListNew(Max).then(
      (response) => {
        setTotalItems(response.data);
      },
      (error) => {}
    );

    UserService.getUserSubscriptionListNew(params).then(
      (response) => {
        setUserSubscriptionList(response.data);
        setLoadingspinner(false);
      },
      (error) => {}
    );
  };

  // Filter subscriptions
  const FilterOutgoing = (val) => {
    let filterVal = val;
    setfilterValue(val);
    let params = {
      userId: familyUserId,
      familyId: familyDetails.FamilyData.Family.id,
      searchStr: searchValue,
      sort: sortValue,
      order: orderValue,
      limit: limit,
      offset: offset,
      filtBy: filterVal,
      monthType: monthType,
    };
    getSubscriptions(params);
  };

  // Sort subscriptions
  const subscriptionSort = (val) => {
    setsortValue(val);
    let params = {
      userId: familyUserId,
      familyId: familyId,
      searchStr: searchValue,
      sort: val,
      order: orderValue,
      limit: limit,
      offset: offset,
      filtBy: filterValue,
      monthType: monthType,
    };
    getSubscriptions(params);
  };

  // Clear search
  const clearSearch = (e) => {
    e.preventDefault();
    setShowSearchText(false);
    setLoading(true);
    setSearchStr("");
    setsearchValue("");
    let params = {
      userId: familyUserId,
      familyId: familyId,
      searchStr: "",
      sort: sortValue,
      order: orderValue,
      limit: limit,
      offset: offset,
      filtBy: filterValue,
      monthType: monthType,
    };
    getSubscriptions(params);
  };

  // Search subscriptions
  const subscriptionSearch = (e) => {
    if (e.key === "Enter" || e.keyCode === 13 || e.which === 13) {
      setShowSearchText(true);
      setLoading(true);
      let srchStr = e.target.value;
      setsearchValue(e.target.value);
      if (srchStr === "") {
        clearSearch(e);
      } else {
        let params = {
          userId: familyUserId,
          familyId: familyId,
          searchStr: srchStr,
          sort: sortValue,
          order: orderValue,
          limit: limit,
          offset: offset,
          filtBy: filterValue,
          monthType: monthType,
        };
        getSubscriptions(params);

        // Close the modal after search
        handleCloseSearch();
      }
    }
  };

  //search box
  useEffect(() => {
    if (mounted) {
      if (searchStr.length === 0) {
        // clearSearch();
        setShowSearchText(false);
        setLoading(true);
        setSearchStr("");
        setsearchValue("");
        let params = {
          userId: familyUserId,
          familyId: familyId,
          searchStr: "",
          sort: sortValue,
          order: orderValue,
          limit: limit,
          offset: offset,
          filtBy: filterValue,
          monthType: monthType,
        };
        getSubscriptions(params);
      }
    }
  }, [searchStr]);

  // Close delete confirmation
  const handleShowDeleteSubClose = () => {
    setShowDeleteSub(false);
  };

  // Delete subscription
  const handleDeleteSubscription = (subId) => {
    setShowDeleteSub(false);
    UserService.deleteSubscription(subId).then(
      (response) => {
        let subList = userSubscriptionList;
        const selectedSubIndex = subList.findIndex(
          (x) => Number(x.id) === Number(subId)
        );
        subList.splice(selectedSubIndex, 1);
        setUserSubscriptionList(subList);
        if (subList.length === 0) {
          setIsEmpty(true);
          setLoadingspinner(false);
          // offset = 0;
        } else {
          setIsEmpty(false);
          setLoadingspinner(false);
        }
        setSuccessToast(true);
        executeScroll();
        window.scrollTo(0, 0);
        setShowToast(true);

        setToastMessage("Outgoing deleted successfully");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      (error) => {}
    );
  };

  // Search modal
  const handleCloseSearch = () => setShowSearch(false);
  const handleShowSearch = () => setShowSearch(true);

  const [arrowAmount, setArrowAmount] = useState(true);
  const [arrowStartDate, setArrowStartDate] = useState(true);

  // remaining onclick function
  const handleAmount = () => {
    setArrowAmount(!arrowAmount);

    if (arrowAmount) {
      // Sort based on amount, from highest to lowest
      const sortedList = userSubscriptionList.sort(
        (a, b) => b.premiumAmount - a.premiumAmount
      );
      setUserSubscriptionList(sortedList);
    } else {
      // Sort based on amount, from lowest to highest
      const sortedList = userSubscriptionList.sort(
        (a, b) => a.premiumAmount - b.premiumAmount
      );
      setUserSubscriptionList(sortedList);
    }
  };

  // start date onclick function
  const handleStartDate = () => {
    setArrowStartDate(!arrowStartDate);

    if (arrowStartDate) {
      // Sort based on startDate, from highest (latest) to lowest (earliest)
      const sortedList = userSubscriptionList.sort(
        (a, b) => new Date(b.startDate) - new Date(a.startDate)
      );
      setUserSubscriptionList([...sortedList]); // Spread the array to trigger re-render
    } else {
      // Sort based on startDate, from lowest (earliest) to highest (latest)
      const sortedList = userSubscriptionList.sort(
        (a, b) => new Date(a.startDate) - new Date(b.startDate)
      );
      setUserSubscriptionList([...sortedList]); // Spread the array to trigger re-render
    }
  };

  const dropdownRef = useRef(null); // Ref for dropdown

  const sortRef = useRef(null); // Ref for the sort

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev); // Toggle the dropdown state
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false); // Close the main dropdown
  };

  const toggleDropdownSort = () => {
    setIsSortDropdown((prev) => !prev); // Toggle the dropdown state
  };

  const closeDropdownSort = () => {
    setIsSortDropdown(false);
  };

  useEffect(() => {
    // Handler to detect clicks outside of the dropdown
    const handleClickOutside = (event) => {
      // handle filter dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }

      // handle sort dropdown
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        closeDropdownSort();
      }
    };

    // Bind the event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // more Family details based on Pagination
  const fetchTasks = (page, limitVal) => {
    setLoadingspinner(true);
    const offset = (page - 1) * 50;
    let params = {
      userId: familyDetails.FamilyData.id,
      familyId: familyDetails.FamilyData.Family.id,
      searchStr: searchValue,
      sort: sortValue,
      order: orderValue,
      limit: limitVal,
      offset: offset,
      filtBy: filterValue,
      monthType: monthType,
    };

    UserService.getUserSubscriptionListNew(params).then(
      (response) => {
        setUserSubscriptionList(response.data);
        setLoadingspinner(false);
      },
      (error) => {}
    );
  };

  const [pageSize, setPageSize] = useState(50); // Default value is 50

  const handlePageSizeChange = (event) => {
    const selectedValue = parseInt(event.target.value, 50); // Get the selected value as a number
    setPageSize(selectedValue); // Update the state
    setlimit(selectedValue);

    fetchTasks(currentPage, selectedValue);
    setCurrentPage(1); // Reset to page 1 after limit change
  };

  useEffect(() => {
    if (mounted) {
      fetchTasks(currentPage, limit);
    }
  }, [currentPage]);

  const totalPages = Math.ceil(totalItems.length / limit);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const [activeButton, setActiveButton] = useState("all"); // Initial state set to 'voucher'

  // Function to handle button click
  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType); // Set active button based on click
    setMonthType(buttonType);
    let params = {
      userId: familyUserId,
      familyId: familyId,
      searchStr: searchValue,
      sort: sortValue,
      order: orderValue,
      limit: limit,
      offset: offset,
      filtBy: filterValue,
      monthType: buttonType,
    };
    getSubscriptions(params);
  };

  return (
    <Container
      fluid
      ref={myRef}
      style={{ minHeight: "calc(100vh - 17rem)", marginBottom: "6rem" }}
      className="d-flex flex-column"
    >
      <ToastBlockContainer
        successToast={successToast}
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />

      <>
        <Row className="row align-items-center justify-content-md-end gy-xl-0 gx-2 gx-xl-3 mb-4">
          <Col>
            <h1 className="lh-base mb-0">{title}</h1>
          </Col>

          {/* sort  */}
          <Col
            xs="auto"
            className="position-relative"
            ref={sortRef} // Ref to track button for outside clicks
          >
            <button
              className={`border-white btn btn-white d-xl-flex align-items-xl-center shadow position-relative ${
                isSortDropdown ? "dropdown-arrow" : ""
              }`}
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              onClick={toggleDropdownSort} // Toggle dropdown on click
              aria-expanded={isSortDropdown}
            >
              <i className="fa-solid fa-sm fa-sort lh-1 align-middle"></i>
              <span className="text-dark ms-md-2 d-none d-xl-block">
                Sort by
              </span>
            </button>
            {/* main dropdown  */}
            <ul
              className={`dropdown-menu dropdown-parent mt-2 ${
                isSortDropdown ? "show" : ""
              }`}
              ref={dropdownRef}
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
              aria-labelledby="dropdownMenuButton"
            >
              <li>
                {sortOption.map((option) => (
                  <div
                    key={option.value}
                    className="dropdown-item"
                    style={{
                      cursor: "pointer",
                      color:
                        selectedSort && selectedSort.value === option.value
                          ? "#020617"
                          : "",
                      backgroundColor:
                        selectedSort && selectedSort.value === option.value
                          ? "#36f1cc"
                          : "",
                    }}
                    onClick={() => {
                      setSelectedSort(option);
                      subscriptionSort(option.value);
                      closeDropdownSort();
                    }}
                  >
                    {option.label}
                  </div>
                ))}
              </li>
            </ul>
          </Col>

          {/* filter  */}
          <Col
            xs="auto"
            className="position-relative"
            ref={dropdownRef} // Ref to track button for outside clicks
          >
            <button
              className={`border-white btn btn-white d-xl-flex align-items-xl-center shadow position-relative ${
                isDropdownOpen ? "dropdown-arrow" : ""
              }`}
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              onClick={toggleDropdown} // Toggle dropdown on click
              aria-expanded={isDropdownOpen}
            >
              <i className="fa-solid fa-sm fa-sliders-h lh-1 align-middle"></i>
              <span className="text-dark ms-md-2 d-none d-xl-block">
                Filters
              </span>
            </button>
            {/* main dropdown  */}
            <ul
              className={`dropdown-menu dropdown-parent mt-2 ${
                isDropdownOpen ? "show" : ""
              }`}
              ref={dropdownRef}
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
              aria-labelledby="dropdownMenuButton"
            >
              <li>
                {filterOption.map((option) => (
                  <div
                    key={option.value}
                    className="dropdown-item"
                    style={{
                      cursor: "pointer",
                      color:
                        selectedOption1 &&
                        selectedOption1.value === option.value
                          ? "#020617"
                          : "",
                      backgroundColor:
                        selectedOption1 &&
                        selectedOption1.value === option.value
                          ? "#36f1cc"
                          : "",
                    }}
                    onClick={() => {
                      setSelectedOption1(option);
                      closeDropdown(); // Close dropdown after selecting an option}
                      FilterOutgoing(option.value);
                    }}
                  >
                    {option.label}
                  </div>
                ))}
              </li>
            </ul>
          </Col>

          <Col xs="auto">
            <Button
              variant="white"
              className="border-white shadow d-xl-none"
              onClick={handleShowSearch}
            >
              <i className="fa-solid fa-sm fa-magnifying-glass text-dark lh-1 align-middle"></i>
            </Button>
            <InputGroup className="shadow rounded d-none d-xl-flex">
              <Form.Control
                type="text"
                placeholder="Search outgoings"
                value={searchStr}
                onChange={(e) => setSearchStr(e.target.value)}
                onKeyDown={subscriptionSearch}
                className="border-white"
              />
              {!searchStr && (
                <Button variant="white" type="submit" className="border-white">
                  <i className="fa-solid fa-sm fa-magnifying-glass text-dark lh-1 align-middle"></i>
                </Button>
              )}
              {searchStr && (
                <Button
                  variant="white"
                  type="submit"
                  className="border-white"
                  onClick={clearSearch}
                >
                  <i className="fa-solid fa-sm fa-circle-xmark text-dark lh-1 align-middle"></i>
                </Button>
              )}
            </InputGroup>
          </Col>
          <Col xs="auto">
            <Link
              to={addSubscriptionUrl}
              className="btn btn-primary d-md-flex align-items-md-center"
            >
              <i className="fa-solid fa-sm fa-add lh-1 align-middle"></i>
              <span className="ms-md-2 d-none d-md-block">Add new</span>
            </Link>
          </Col>
        </Row>

        {loadingspinner ? (
          <Loading />
        ) : (
          <section className="bg-white p-4 p-md-5 p-lg-6 rounded-4 shadow h-100">
            <Row className="gx-3 gy-2 gx-md-5 gx-lg-7 mb-8">
              <Col xs="12" md="auto">
                <div className="small fw-medium">This month</div>
                <div className="h1 mb-0">
                  £{" "}
                  {Number.isInteger(monthlyCost.this_month)
                    ? monthlyCost.this_month.toLocaleString("en-US")
                    : Math.trunc(monthlyCost.this_month).toLocaleString(
                        "en-US"
                      )}
                </div>
              </Col>
              <Col xs="12" md="auto">
                <div className="small fw-medium">Next month</div>
                <div className="h1 mb-0">
                  £{" "}
                  {Number.isInteger(monthlyCost.next_month)
                    ? monthlyCost.next_month.toLocaleString("en-US")
                    : Math.trunc(monthlyCost.next_month).toLocaleString(
                        "en-US"
                      )}
                </div>
              </Col>
              <Col xs="12" md="auto">
                <div className="small fw-medium">Average monthly cost</div>
                <div className="h1 mb-0">
                  £{" "}
                  {Number.isInteger(monthlyCost.avgMonthlycosts)
                    ? monthlyCost.avgMonthlycosts.toLocaleString("en-US")
                    : Math.trunc(monthlyCost.avgMonthlycosts).toLocaleString(
                        "en-US"
                      )}
                </div>
              </Col>
              <Col className="d-flex justify-content-end align-items-end">
                <div
                  className={`btn btn-sm ${
                    activeButton === "all" ? "bg-cayn" : "btn-white"
                  }`}
                  onClick={() => handleButtonClick("all")}
                  style={{
                    borderRadius: "8px 0 0 8px",
                  }}
                >
                  All
                </div>
                <div
                  className={`btn btn-sm ${
                    activeButton === "upcoming" ? "bg-cayn" : "btn-white"
                  }`}
                  onClick={() => handleButtonClick("upcoming")}
                  style={{
                    borderRadius: "0",
                  }}
                >
                  Upcoming
                </div>
                <div
                  className={`btn btn-sm ${
                    activeButton === "next_month" ? "bg-cayn" : "btn-white"
                  }`}
                  onClick={() => handleButtonClick("next_month")}
                  style={{
                    borderRadius: "0 8px 8px 0",
                  }}
                >
                  Next
                </div>
              </Col>
            </Row>
            <div className="d-none align-items-center ms-8">
              <span>Sort by:</span>
              <Select
                className="ms-3"
                options={sortOption}
                defaultValue={{
                  label: "Created",
                  value: "created",
                }}
                onChange={subscriptionSort}
              />
            </div>
            <Row className="gx-3 gy-0 gx-md-4 gx-lg-3 gx-xl-2 gx-xxl-3">
              <Col xs="6" md="5" lg="5" xl="5">
                <span className="small fw-semibold fs-17">Name</span>
              </Col>
              <Col xs="6" md="5" lg="5" xl="4">
                <span className="small fw-semibold fs-17">Supplier</span>
              </Col>
              <Col xs="6" md="5" lg="5" xl="4">
                <span className="small fw-semibold fs-17">Category</span>
              </Col>
              <Col className="d-none d-xl-block" lg="3" xl="3">
                <span className="small fw-semibold fs-17">Renewal</span>
              </Col>
              <Col
                xs="6"
                md="4"
                lg="3"
                xl="3"
                className="cursor-pointer"
                onClick={handleAmount}
              >
                <span className="small fw-semibold fs-17">Amount</span>
                <i
                  className={`fa-solid fa-xs ms-1 ${
                    arrowAmount ? "fa-arrow-up" : "fa-arrow-down"
                  }`}
                ></i>
              </Col>
              {/* <Col className="order-xl-6 col-xl d-none d-xl-block">
              </Col> */}
              <Col xs="auto" className=" d-none d-md-block ms-xxl-1">
                <span className="small fw-semibold fs-17"></span>
                <Button
                  className="btn-sm btn-square invisible h-100 d-none d-md-inline-block"
                  variant="link"
                >
                  {/* maintain alignment */}
                </Button>
                <Button
                  className="btn-sm btn-square invisible h-100"
                  variant="link"
                >
                  {/* maintain alignment */}
                </Button>
              </Col>
            </Row>
            <hr className="mt-1 mb-3" />
            {isEmpty ? (
              <div className="text-center">
                <p className="mt-4">You currently have no Outgoings set up!</p>
                <Link
                  to={addSubscriptionUrl}
                  className="btn btn-primary btn-lg d-inline-flex align-items-center"
                >
                  <i className="fa-solid fa-sm fa-add lh-1 align-middle"></i>
                  <span className="ms-md-2 d-none d-md-block">Add new</span>
                </Link>
              </div>
            ) : (
              <>
                {userSubscriptionList &&
                  userSubscriptionList.map((subData, index) => (
                    <React.Fragment key={subData.id}>
                      <Row
                        className="align-items-center gx-3 gy-0 gx-md-4 gx-lg-3 gx-xl-2 gx-xxl-3"
                        onClick={() => viewDetailsRoute(subData.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <Col xs="6" md="5" lg="5" xl="5">
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250 }}
                            overlay={
                              <Tooltip id="">
                                <div className="">
                                  {subData.productName
                                    ? subData.productName
                                    : "--"}
                                </div>
                              </Tooltip>
                            }
                          >
                            <div className="small text-dark text-truncate max-width-webkit w-fit">
                              {subData.productName ? subData.productName : "--"}
                            </div>
                          </OverlayTrigger>
                        </Col>

                        <Col xs="6" md="5" lg="5" xl="4">
                          <div className="d-flex align-items-center gap-2">
                            <div class="img-wrapper img-wrapper-warranty-product flex-shrink-0 d-none d-md-flex">
                              <img
                                src={
                                  subData.Supplier && subData.Supplier.logo
                                    ? subData.Supplier.logo
                                    : "--"
                                }
                                alt="logo"
                                className="img-fluid w-100 h-100"
                              />
                            </div>
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250 }}
                              overlay={
                                <Tooltip id="">
                                  <div className="">
                                    {subData.Supplier && subData.Supplier.name
                                      ? subData.Supplier.name === "Others"
                                        ? subData.otherSupplierName
                                        : subData.Supplier.name
                                      : "--"}
                                  </div>
                                </Tooltip>
                              }
                            >
                              <div className="small  text-dark text-truncate max-width-webkit w-fit">
                                {subData.Supplier && subData.Supplier.name
                                  ? subData.Supplier.name === "Others"
                                    ? subData.otherSupplierName
                                    : subData.Supplier.name
                                  : "--"}
                              </div>
                            </OverlayTrigger>
                          </div>
                        </Col>

                        <Col xs="6" md="5" lg="5" xl="4">
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250 }}
                            overlay={
                              <Tooltip id="">
                                <div className="">
                                  {subData.Category
                                    ? subData.Category.CategoryName
                                    : "--"}
                                </div>
                              </Tooltip>
                            }
                          >
                            <div className="small text-dark text-truncate max-width-webkit w-fit">
                              {subData.Category
                                ? subData.Category.CategoryName
                                : "--"}
                            </div>
                          </OverlayTrigger>
                        </Col>
                        <Col className="d-none d-xl-block" lg="3" xl="3">
                          <div className="d-inline small fw-2 text-dark">
                            {subData.renewalFrequency}
                          </div>
                        </Col>

                        <Col xs="6" md="4" lg="3" xl="3" className="">
                          <div className="d-inline small  text-dark">
                            £{subData.premiumAmount}
                          </div>
                        </Col>
                        <Col
                          xs="auto"
                          className=" ms-auto d-flex align-items-center gap-lg-2 w-auto"
                        >
                          {subData.reminder && (
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250 }}
                              overlay={
                                <Tooltip id="">
                                  <div className="">
                                    Reminder set for this outgoing
                                  </div>
                                </Tooltip>
                              }
                            >
                              <div className="d-none d-lg-flex me-2">
                                <span className="badge badge-xs d-inline-flex align-items-center badge-primary-light rounded-circle">
                                  <i className="fa-solid fa-md fa-bell"></i>
                                </span>
                              </div>
                            </OverlayTrigger>
                          )}
                          {subData.documentUrl ? (
                            <div className="d-none d-lg-flex">
                              <span className="badge badge-xs d-inline-flex align-items-center bg-warning d-flex align-items-center">
                                <i className="fa fa-file"></i>{" "}
                                <span
                                  className="ms-1 fs-12"
                                  style={{ marginTop: "3px" }}
                                >
                                  {JSON.parse(subData.documentUrl).length}{" "}
                                  {JSON.parse(subData.documentUrl).length > 1
                                    ? "Files"
                                    : "File"}
                                </span>
                              </span>
                            </div>
                          ) : (
                            <div style={{ width: "59px" }}></div>
                          )}
                          <Button
                            className="btn-sm btn-square btn-interactive"
                            variant="link"
                            onClick={(event) => {
                              event.stopPropagation(); // Prevents event from bubbling up
                              editSubscriptionRoute(subData.id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa-solid fa-sm fa-edit"></i>
                          </Button>
                          <Button
                            className="btn-sm btn-square btn-interactive"
                            variant="link"
                            onClick={() => viewDetailsRoute(subData.id)}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa-solid fa-sm fa-chevron-right"></i>
                          </Button>
                        </Col>
                      </Row>
                      {index !== userSubscriptionList.length - 1 && (
                        <hr className="my-3" />
                      )}
                    </React.Fragment>
                  ))}
              </>
            )}

            {userSubscriptionList.length === 0 && !isEmpty && (
              <>
                <p className="text-center mt-4">
                  There are no results for the query
                </p>
              </>
            )}

            {/* pagination  */}
            <div className="d-sm-flex align-items-center gap-2 justify-content-end mt-7 fs-14 bold-colour pt-3">
              {/* Page Size Dropdown */}
              <div className="d-flex align-items-center gap-2 me-3  mb-3 mb-sm-0">
                <label htmlFor="pageSize">Page Size:</label>
                <select
                  id="pageSize"
                  value={pageSize} // Bind the state to the select element
                  onChange={handlePageSizeChange}
                  className="form-select p-2 me-2 fs-14"
                  style={{ width: "75px", display: "inline-block" }}
                >
                  <option value={50}>50</option>
                  <option value={75}>75</option>
                  <option value={100}>100</option>
                  <option value={150}>150</option>
                </select>
                <div>
                  Showing <strong>{userSubscriptionList.length}</strong> of{" "}
                  <strong>{totalItems.length}</strong>
                </div>
              </div>
              <div className="d-flex align-items-center gap-2">
                {/* Previous Button */}
                <button
                  className="border-0 bg-transparent"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <i class="fas fa-chevron-left "></i>
                </button>
                <div>
                  Page <strong>{totalPages === 0 ? 0 : currentPage}</strong> of{" "}
                  <strong>{totalPages}</strong>
                </div>
                {/* Next Button */}
                <button
                  className="border-0 bg-transparent"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage * limit >= totalItems.length}
                >
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </section>
        )}
      </>

      {/* // Delete subscription */}
      <Modal
        show={showDeleteSub}
        onHide={handleShowDeleteSubClose}
        className="invite_au_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="h3 mb-0">Delete Outgoing</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            All the Outgoing related details will be deleted from the family
          </p>
          <p className="mb-5">Do you want to continue?</p>
          <Button variant="primary" onClick={handleShowDeleteSubClose}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => handleDeleteSubscription(subId)}
            className="ms-4"
          >
            Yes
          </Button>
        </Modal.Body>
      </Modal>

      {/* Search for mobile */}
      <Modal show={showSearch} onHide={handleCloseSearch}>
        <Modal.Header closeButton>
          <Modal.Title>Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="rounded shadow">
            <Form.Control
              type="text"
              placeholder="Search outgoings"
              value={searchStr}
              onChange={(e) => setSearchStr(e.target.value)}
              onKeyDown={subscriptionSearch}
              className="border-white"
            />
            {!searchStr && (
              <Button variant="white" type="submit" className="border-white">
                <i className="fa-solid fa-sm fa-magnifying-glass text-dark lh-1 align-middle"></i>
              </Button>
            )}
            {searchStr && (
              <Button
                variant="white"
                type="submit"
                className="border-white"
                onClick={clearSearch}
              >
                <i className="fa-solid fa-sm fa-circle-xmark text-dark lh-1 align-middle"></i>
              </Button>
            )}
          </InputGroup>
        </Modal.Body>
      </Modal>
    </Container>
  );
};
export default MySubscriptions;
