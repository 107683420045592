import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Image, Form } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { Primary } from "../../stories/Button.stories";
import { ElAccountPlanCrd } from "../../stories/ElAccountPlanCard.stories";
import Select from "react-select";
import Accordion from "react-bootstrap/Accordion";
import { Large } from "../../stories/Button.stories";
import UserService from "../../services/user.service";
import Loading from "../../common/Loading";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import layer from "../../assets/images/layer1.svg";

const ReminderSetting = (props) => {
  let history = useHistory();
  let { uuid, userUUID } = useParams();
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  // const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [familyDetails, setFamilyDetails] = useState([]);
  const [title, setTitle] = useState("Reminder Settings");
  const [subTitle, setSubTitle] = useState(
    "Manage your settings for the group that you are part of on Elderly Life."
  );
  const [formValues, setFormValues] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [selectedSettings1, setSelectedSettings1] = useState();
  const [selectedSettings2, setSelectedSettings2] = useState();
  const [selectedSettings3, setSelectedSettings3] = useState();
  const [successToast, setSuccessToast] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [stopFn, setStopFn] = useState("");
  const [loader, setLoader] = useState(true);
  const [loadYes, setLoadYes] = useState("");

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      backgroundColor: "#ecf6ff 0% 0% no-repeat padding-box",
      fontSize: "0.8rem",
      borderRadius: "0px 10px 10px 0px",
      borderStyle: "none",
      cursor: "pointer",
      "padding-top": "0.7rem",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "0.5rem",
      fontSize: "0.8rem",
      width: "100%",
      cursor: "pointer",
    }),
  };
  const initialfilterOption = [
    { value: "3Months", label: "3 Months" },
    { value: "2Months", label: "2 Months" },
    { value: "1Month", label: "1 Month" },
    { value: "3Weeks", label: "3 Weeks" },
    { value: "2Weeks", label: "2 Weeks" },
    { value: "1Week", label: "1 Week" },
  ];
  const [filterOption1, setFilterOption1] = useState(initialfilterOption);
  const [filterOption2, setFilterOption2] = useState(initialfilterOption);
  const [filterOption3, setFilterOption3] = useState(initialfilterOption);

  const handleChange = (e, action) => {
    if (action) {
      var targetData = {
        name: action.name,
        value: e.value,
      };
      let abc = { label: "select settings", value: "" };
      if (action.name === "settings1") {
        setSelectedSettings1(e);
        setSelectedSettings2(abc);
        setSelectedSettings3(abc);
        const { name, value } = targetData;
        setFormValues({
          ...formValues,
          settings1: e.value,
          settings2: "",
          settings3: "",
        });

        if (e.value == "3Months") {
          const newOption = [
            { value: "3Months", label: "3 Months", isdisabled: true },
            { value: "2Months", label: "2 Months" },
            { value: "1Month", label: "1 Month" },
            { value: "3Weeks", label: "3 Weeks" },
            { value: "2Weeks", label: "2 Weeks" },
            { value: "1Week", label: "1 Week" },
          ];
          setFilterOption2(newOption);
        } else if (e.value == "2Months") {
          const newOption = [
            { value: "3Months", label: "3 Months", isdisabled: true },
            { value: "2Months", label: "2 Months", isdisabled: true },
            { value: "1Month", label: "1 Month" },
            { value: "3Weeks", label: "3 Weeks" },
            { value: "2Weeks", label: "2 Weeks" },
            { value: "1Week", label: "1 Week" },
          ];
          setFilterOption2(newOption);
        } else if (e.value == "1Month") {
          const newOption = [
            { value: "3Months", label: "3 Months", isdisabled: true },
            { value: "2Months", label: "2 Months", isdisabled: true },
            { value: "1Month", label: "1 Month", isdisabled: true },
            { value: "3Weeks", label: "3 Weeks" },
            { value: "2Weeks", label: "2 Weeks" },
            { value: "1Week", label: "1 Week" },
          ];
          setFilterOption2(newOption);
        } else if (e.value == "3Weeks") {
          const newOption = [
            { value: "3Months", label: "3 Months", isdisabled: true },
            { value: "2Months", label: "2 Months", isdisabled: true },
            { value: "1Month", label: "1 Month", isdisabled: true },
            { value: "3Weeks", label: "3 Weeks", isdisabled: true },
            { value: "2Weeks", label: "2 Weeks" },
            { value: "1Week", label: "1 Week" },
          ];
          setFilterOption2(newOption);
        } else if (e.value == "2Weeks") {
          const newOption = [
            { value: "3Months", label: "3 Months", isdisabled: true },
            { value: "2Months", label: "2 Months", isdisabled: true },
            { value: "1Month", label: "1 Month", isdisabled: true },
            { value: "3Weeks", label: "3 Weeks", isdisabled: true },
            { value: "2Weeks", label: "2 Weeks", isdisabled: true },
            { value: "1Week", label: "1 Week" },
          ];
          setFilterOption2(newOption);
        } else if (e.value == "1Week") {
          const newOption = [
            { value: "3Months", label: "3 Months", isdisabled: true },
            { value: "2Months", label: "2 Months", isdisabled: true },
            { value: "1Month", label: "1 Month", isdisabled: true },
            { value: "3Weeks", label: "3 Weeks", isdisabled: true },
            { value: "2Weeks", label: "2 Weeks", isdisabled: true },
            { value: "1Week", label: "1 Week", isdisabled: true },
          ];
          setFilterOption2(newOption);
        }
      }
      if (action.name === "settings2") {
        setSelectedSettings2(e);
        setSelectedSettings3(abc);
        setFormValues({
          ...formValues,

          settings2: e.value,
          settings3: "",
        });
        if (e.value == "3Months") {
          const newOption = [
            { value: "3Months", label: "3 Months", isdisabled: true },
            { value: "2Months", label: "2 Months" },
            { value: "1Month", label: "1 Month" },
            { value: "3Weeks", label: "3 Weeks" },
            { value: "2Weeks", label: "2 Weeks" },
            { value: "1Week", label: "1 Week" },
          ];
          setFilterOption3(newOption);
        } else if (e.value == "2Months") {
          const newOption = [
            { value: "3Months", label: "3 Months", isdisabled: true },
            { value: "2Months", label: "2 Months", isdisabled: true },
            { value: "1Month", label: "1 Month" },
            { value: "3Weeks", label: "3 Weeks" },
            { value: "2Weeks", label: "2 Weeks" },
            { value: "1Week", label: "1 Week" },
          ];
          setFilterOption3(newOption);
        } else if (e.value == "1Month") {
          const newOption = [
            { value: "3Months", label: "3 Months", isdisabled: true },
            { value: "2Months", label: "2 Months", isdisabled: true },
            { value: "1Month", label: "1 Month", isdisabled: true },
            { value: "3Weeks", label: "3 Weeks" },
            { value: "2Weeks", label: "2 Weeks" },
            { value: "1Week", label: "1 Week" },
          ];
          setFilterOption3(newOption);
        } else if (e.value == "3Weeks") {
          const newOption = [
            { value: "3Months", label: "3 Months", isdisabled: true },
            { value: "2Months", label: "2 Months", isdisabled: true },
            { value: "1Month", label: "1 Month", isdisabled: true },
            { value: "3Weeks", label: "3 Weeks", isdisabled: true },
            { value: "2Weeks", label: "2 Weeks" },
            { value: "1Week", label: "1 Week" },
          ];
          setFilterOption3(newOption);
        } else if (e.value == "2Weeks") {
          const newOption = [
            { value: "3Months", label: "3 Months", isdisabled: true },
            { value: "2Months", label: "2 Months", isdisabled: true },
            { value: "1Month", label: "1 Month", isdisabled: true },
            { value: "3Weeks", label: "3 Weeks", isdisabled: true },
            { value: "2Weeks", label: "2 Weeks", isdisabled: true },
            { value: "1Week", label: "1 Week" },
          ];
          setFilterOption3(newOption);
        } else if (e.value == "1Week") {
          const newOption = [
            { value: "3Months", label: "3 Months", isdisabled: true },
            { value: "2Months", label: "2 Months", isdisabled: true },
            { value: "1Month", label: "1 Month", isdisabled: true },
            { value: "3Weeks", label: "3 Weeks", isdisabled: true },
            { value: "2Weeks", label: "2 Weeks", isdisabled: true },
            { value: "1Week", label: "1 Week", isdisabled: true },
          ];
          setFilterOption3(newOption);
        }
      }
      if (action.name === "settings3") {
        setSelectedSettings3(e);
        setFormValues({
          ...formValues,
          settings3: e.value,
        });
      }
    } else if (e.target.name === "amount") {
      var targetData = {
        name: e.target.name,
        value: Math.round(e.target.value * 100) / 100,
        //value: parseFloat(e.target.value)
      };
    } else {
      var targetData = {
        name: e.target.name,
        value: e.target.value,
      };
    }
  };

  //
  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    if (
      formValues.settings1 === "" ||
      formValues.settings2 === "" ||
      formValues.settings3 === ""
    ) {
      window.scrollTo(0, 0);
      setIsSubmit(false);
      setUpdateLoading(false);
      setStopFn("stop");
      setSuccessToast(false);
      setShowToast(true);
      setToastMessage("Please select all the settings");
      setLoadYes("loadYes");
    } else {
      setIsSubmit(true);
      setStopFn("");
      setLoadYes("loadYes");
      setUpdateLoading(true);
    }
  };
  // validation
  const validate = (values) => {
    const errors = {};
    if (formValues.settings1 === "") {
      errors.msg = "Please enter the first setting";
      errors.settings1 = "Yes";
    }
    if (formValues.settings2 === "") {
      errors.msg = "Please enter the second setting";
      errors.settings2 = "Yes";
    }
    if (formValues.settings3 === "") {
      errors.msg = "Please enter the third setting";
      errors.settings3 = "Yes";
    }
    return errors;
  };
  useEffect(() => {
    //setLoading(true);
    if (userUUID) {
      UserService.userProfile(userUUID).then((response) => {
        getFamilyDetails(response.userData.id);
        let pUser = response.userData;
        // setParentUser(response.userData);
        setTitle(
          pUser.firstName + " " + pUser.lastName + "'s Reminder Settings"
        );
        setSubTitle(
          "Manage settings for the families that you are part of on Elderly Life for " +
            pUser.firstName +
            " " +
            pUser.lastName +
            " here"
        );
      });
    } else {
      getFamilyDetails(UserDetails.id);
    }

    // submit form
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      let data = {
        settings1: formValues.settings1,
        settings2: formValues.settings2,
        settings3: formValues.settings3,
      };
      // api call
      UserService.updateReminderSetting(
        uuid,
        formValues.user_family_id,
        data
      ).then(
        (response) => {
          if (response.status === 400) {
            window.scrollTo(0, 0);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(response.data.message);
          } else {
            window.scrollTo(0, 0);
            setUpdateLoading(false);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("Reminder settings updated successfully");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        },
        (error) => {}
      );
    }
  }, [formErrors]);

  // get family details
  const getFamilyDetails = (userId) => {
    if (stopFn === "") {
      if (loadYes === "") {
        setLoader(true);
        UserService.getUserSingleFamilyDetails(uuid, userId).then(
          (response) => {
            const initialValues = {
              settings1: response.FamilyData.settings1,
              settings2: response.FamilyData.settings2,
              settings3: response.FamilyData.settings3,
              user_family_id: response.FamilyData.id,
            };
            setFormValues(initialValues);

            setSelectedSettings1({
              value: response.FamilyData.settings1,
              label: addSpaceInStr(response.FamilyData.settings1),
            });
            setSelectedSettings2({
              value: response.FamilyData.settings2,
              label: addSpaceInStr(response.FamilyData.settings2),
            });
            setSelectedSettings3({
              value: response.FamilyData.settings3,
              label: addSpaceInStr(response.FamilyData.settings3),
            });
            //setFamilyDetails(response.FamilyData);

            //setting 1
            if (response.FamilyData.settings1 == "3Months") {
              const newOption = [
                { value: "3Months", label: "3 Months", isdisabled: true },
                { value: "2Months", label: "2 Months" },
                { value: "1Month", label: "1 Month" },
                { value: "3Weeks", label: "3 Weeks" },
                { value: "2Weeks", label: "2 Weeks" },
                { value: "1Week", label: "1 Week" },
              ];
              setFilterOption2(newOption);
            } else if (response.FamilyData.settings1 == "2Months") {
              const newOption = [
                { value: "3Months", label: "3 Months", isdisabled: true },
                { value: "2Months", label: "2 Months", isdisabled: true },
                { value: "1Month", label: "1 Month" },
                { value: "3Weeks", label: "3 Weeks" },
                { value: "2Weeks", label: "2 Weeks" },
                { value: "1Week", label: "1 Week" },
              ];
              setFilterOption2(newOption);
            } else if (response.FamilyData.settings1 == "1Month") {
              const newOption = [
                { value: "3Months", label: "3 Months", isdisabled: true },
                { value: "2Months", label: "2 Months", isdisabled: true },
                { value: "1Month", label: "1 Month", isdisabled: true },
                { value: "3Weeks", label: "3 Weeks" },
                { value: "2Weeks", label: "2 Weeks" },
                { value: "1Week", label: "1 Week" },
              ];
              setFilterOption2(newOption);
            } else if (response.FamilyData.settings1 == "3Weeks") {
              const newOption = [
                { value: "3Months", label: "3 Months", isdisabled: true },
                { value: "2Months", label: "2 Months", isdisabled: true },
                { value: "1Month", label: "1 Month", isdisabled: true },
                { value: "3Weeks", label: "3 Weeks", isdisabled: true },
                { value: "2Weeks", label: "2 Weeks" },
                { value: "1Week", label: "1 Week" },
              ];
              setFilterOption2(newOption);
            } else if (response.FamilyData.settings1 == "2Weeks") {
              const newOption = [
                { value: "3Months", label: "3 Months", isdisabled: true },
                { value: "2Months", label: "2 Months", isdisabled: true },
                { value: "1Month", label: "1 Month", isdisabled: true },
                { value: "3Weeks", label: "3 Weeks", isdisabled: true },
                { value: "2Weeks", label: "2 Weeks", isdisabled: true },
                { value: "1Week", label: "1 Week" },
              ];
              setFilterOption2(newOption);
            } else if (response.FamilyData.settings1 == "1Week") {
              const newOption = [
                { value: "3Months", label: "3 Months", isdisabled: true },
                { value: "2Months", label: "2 Months", isdisabled: true },
                { value: "1Month", label: "1 Month", isdisabled: true },
                { value: "3Weeks", label: "3 Weeks", isdisabled: true },
                { value: "2Weeks", label: "2 Weeks", isdisabled: true },
                { value: "1Week", label: "1 Week", isdisabled: true },
              ];
              setFilterOption2(newOption);
            }

            //setting 2
            if (response.FamilyData.settings2 == "3Months") {
              const newOption = [
                { value: "3Months", label: "3 Months", isdisabled: true },
                { value: "2Months", label: "2 Months" },
                { value: "1Month", label: "1 Month" },
                { value: "3Weeks", label: "3 Weeks" },
                { value: "2Weeks", label: "2 Weeks" },
                { value: "1Week", label: "1 Week" },
              ];
              setFilterOption3(newOption);
            } else if (response.FamilyData.settings2 == "2Months") {
              const newOption = [
                { value: "3Months", label: "3 Months", isdisabled: true },
                { value: "2Months", label: "2 Months", isdisabled: true },
                { value: "1Month", label: "1 Month" },
                { value: "3Weeks", label: "3 Weeks" },
                { value: "2Weeks", label: "2 Weeks" },
                { value: "1Week", label: "1 Week" },
              ];
              setFilterOption3(newOption);
            } else if (response.FamilyData.settings2 == "1Month") {
              const newOption = [
                { value: "3Months", label: "3 Months", isdisabled: true },
                { value: "2Months", label: "2 Months", isdisabled: true },
                { value: "1Month", label: "1 Month", isdisabled: true },
                { value: "3Weeks", label: "3 Weeks" },
                { value: "2Weeks", label: "2 Weeks" },
                { value: "1Week", label: "1 Week" },
              ];
              setFilterOption3(newOption);
            } else if (response.FamilyData.settings2 == "3Weeks") {
              const newOption = [
                { value: "3Months", label: "3 Months", isdisabled: true },
                { value: "2Months", label: "2 Months", isdisabled: true },
                { value: "1Month", label: "1 Month", isdisabled: true },
                { value: "3Weeks", label: "3 Weeks", isdisabled: true },
                { value: "2Weeks", label: "2 Weeks" },
                { value: "1Week", label: "1 Week" },
              ];
              setFilterOption3(newOption);
            } else if (response.FamilyData.settings2 == "2Weeks") {
              const newOption = [
                { value: "3Months", label: "3 Months", isdisabled: true },
                { value: "2Months", label: "2 Months", isdisabled: true },
                { value: "1Month", label: "1 Month", isdisabled: true },
                { value: "3Weeks", label: "3 Weeks", isdisabled: true },
                { value: "2Weeks", label: "2 Weeks", isdisabled: true },
                { value: "1Week", label: "1 Week" },
              ];
              setFilterOption3(newOption);
            } else if (response.FamilyData.settings2 == "1Week") {
              const newOption = [
                { value: "3Months", label: "3 Months", isdisabled: true },
                { value: "2Months", label: "2 Months", isdisabled: true },
                { value: "1Month", label: "1 Month", isdisabled: true },
                { value: "3Weeks", label: "3 Weeks", isdisabled: true },
                { value: "2Weeks", label: "2 Weeks", isdisabled: true },
                { value: "1Week", label: "1 Week", isdisabled: true },
              ];
              setFilterOption3(newOption);
            }
            if (loadYes === "") {
              setLoader(false);
            }

            //setting 3
          },
          (error) => {}
        );
      }
    } else {
    }
  };
  const addSpaceInStr = (str) => {
    var newStr = "";
    if (str !== "Never") {
      var position = 1;
      newStr = [str.slice(0, position), str.slice(position)].join(" ");
    } else {
      newStr = str;
    }
    return newStr;
  };
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  var userType = localStorage.getItem("userType");
  var uuidDetails = localStorage.getItem("uuid");
  var userUuidDetails = JSON.parse(localStorage.getItem("userUUID"));
  var parentUserUUId = localStorage.getItem("parentUser.uuid");
  const dashboardRed = () => {
    if (parentUserUUId === null) {
      history.push({
        pathname: `/dashboard/${uuidDetails}`,
      });
    } else {
      history.push({
        pathname: `/supporting_user_dashboard/${uuidDetails}/${userUuidDetails}`,
      });
    }
  };

  return (
    <div className="page-content reminder-pg tab-btn">
      <Container fluid>
        {/* {details} */}
        <ToastBlockContainer
          successToast={successToast}
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />
        <Row>
          <Col md="8" lg="8">
            <div
              className="Reminder-backBtn"
              onClick={() =>
                // history.push({
                //   pathname: `/dashboard/${data.state.state.tab}`,
                dashboardRed()
              }
            >
              {" "}
              <img
                className=""
                src={layer}
                alt="Profile Avatar"
                style={{ "margin-right": "15px" }}
              />{" "}
              {parentUserUUId === null ? (
                <span>Back to Group Dashboard</span>
              ) : (
                <span>Back to Main User Dashboard</span>
              )}
            </div>
            <h3>{title}</h3>
            <p className="sub-title">{subTitle}</p>
          </Col>
          <Col md="4" lg="4"></Col>
        </Row>
        <Row className="mt-3">
          {loader ? (
            <div style={{ "margin-top": "5rem" }}>
              <Loading />
            </div>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Card className="p-4" style={{ "margin-bottom": "12.5rem" }}>
                <Row className="my-3">
                  <Col md="9">
                    <p className="sub-title mb-0">
                      The following reminder settings are applicable to all the
                      Elderly Life modules except To Do's.{" "}
                    </p>
                    <p className="sub-title">
                      {" "}
                      For To Do's by default you will be reminded via
                      notification before a Week, before a Day & on the day of
                      the To Do's.
                    </p>
                  </Col>
                  <Col md="3">
                    <div className="align-right accordion-save-btn">
                      <Large
                        children="Save Updates"
                        size="mediumbtn"
                        variant="primary"
                        type="submit"
                        buttonWidth="210px"
                        font_Weight="500"
                        isLoading={updateLoading ? "true" : "false"}
                        isDisabled={updateLoading ? "true" : "false"}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="reminder-select mb-3 mx-1">
                  <Col md="3" lg="3" className="reminder-box">
                    <p className="mb-2 reminder-text">1st Reminder</p>
                    <p className="reminder-subtext">
                      reminder will be sent based on the selection below
                    </p>

                    <Select
                      styles={customStyles}
                      options={filterOption1}
                      defaultValue={{
                        label: "select settings",
                        value: "",
                      }}
                      value={selectedSettings1}
                      onChange={handleChange}
                      name="settings1"
                      isOptionDisabled={(option) => option.isdisabled}
                    />
                  </Col>
                  <Col md="3" lg="3" className="reminder-box mx-5">
                    <p className="mb-2 reminder-text">2nd Reminder</p>
                    <p className="reminder-subtext">
                      reminder will be sent based on the selection below
                    </p>
                    <Select
                      styles={customStyles}
                      options={filterOption2}
                      defaultValue={{
                        label: "select settings",
                        value: "",
                      }}
                      value={selectedSettings2}
                      onChange={handleChange}
                      name="settings2"
                      isOptionDisabled={(option) => option.isdisabled}
                    />
                  </Col>
                  <Col md="3" lg="3" className="reminder-box">
                    <p className="mb-2 reminder-text">3rd Reminder</p>
                    <p className="reminder-subtext">
                      reminder will be sent based on the selection below
                    </p>
                    <Select
                      styles={customStyles}
                      options={filterOption3}
                      defaultValue={{
                        label: "select settings",
                        value: "",
                      }}
                      value={selectedSettings3}
                      onChange={handleChange}
                      name="settings3"
                      isOptionDisabled={(option) => option.isdisabled}
                    />
                  </Col>
                </Row>
              </Card>
            </Form>
          )}
        </Row>
      </Container>
    </div>
  );
};
export default ReminderSetting;
