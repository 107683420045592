import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  InputGroup,
  Form,
  Nav,
  NavLink,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import Dropdown from "react-bootstrap/Dropdown";
import { BenefitsCardVal } from "../../stories/BenefitsCard.stories";
import Loading from "../../common/Loading";
import InfiniteScroll from "react-infinite-scroll-component";

const Benefits = (props) => {
  const [familyDetails, setFamilyDetails] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [benefitItemList, setBenefitItemList] = useState([]);
  const [dropDownOpen, setDropddownOpen] = useState(false);
  const [categoryId, setCategoryId] = useState(0);
  const [loadingval, setLoadingval] = useState(true);
  const [loading, setLoading] = useState(false);
  const [displayLength, setDisplayLength] = useState(0);
  const [searchStr, setSearchStr] = useState("");
  const [allBenefits, setAllBenefits] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [backUrl, setBackUrl] = useState("");
  const [limit, setlimit] = useState(50);
  const [offset, setoffset] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const [dataLengthTop20, setDataLengthTop20] = useState(0);
  const [dataLengthCat, setDataLengthCat] = useState(0);
  const [dataLengthSearch, setDataLengthSearch] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [hasMoreTop20, setHasMoreTop20] = useState(false);
  const [hasMoreCat, setHasMoreCat] = useState(false);
  const [hasMoreSearch, setHasMoreSearch] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [catName, setCatName] = useState("All");

  const isCategoryMatched = categoryList
    .slice(displayLength)
    .some(({ id }) => id === categoryId);
  const [searchVal, setSearchVal] = useState("");

  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  let { uuid, userUUID } = useParams();

  // get benefit item listing by category
  const selectCategory = (categoryId) => {
    setLoading(true);
    setSearchStr("");
    setCategoryId(categoryId);

    for (let i = 0; i < categoryList.length; i++) {
      if (categoryList[i].id === categoryId) {
        setCatName(categoryList[i].name);
      }
    }

    let params = {
      limit: 50,
      offset: 0,
      id: categoryId,
    };
    UserService.getBenefitItemListByCategory(params).then(
      (response) => {
        setBenefitItemList(response.result);
        setLoading(true);

        setDataLengthCat(response.result.length);

        let nextOffset = 50;

        if (response.result.length === 0) {
          setIsEmpty(true);
          setLoading(false);
          setoffset(0);
          setHasMoreCat(false);
        } else {
          setIsEmpty(false);
          setLoading(false);
          if (response.result.length >= nextOffset) {
            setoffset(nextOffset);
            setHasMoreCat(true);
          } else {
            setHasMoreCat(false);
          }
        }
      },
      (error) => {}
    );
  };

  // get top 20 benefit item listing
  const getTop20BenefitItems = () => {
    setLoading(true);
    setSearchStr("");
    setCategoryId("top-20");
    let params = {
      limit: 50,
      offset: 0,
    };
    UserService.getTop20BenefitItemList(params).then(
      (response) => {
        setBenefitItemList(response.result.rows);
        setLoading(true);
        setDataLengthTop20(response.result.rows.length);

        let nextOffset = 50;

        if (response.result.rows.length === 0) {
          setIsEmpty(true);
          setLoading(false);
          setoffset(0);
          setHasMoreTop20(false);
        } else {
          setIsEmpty(false);
          setLoading(false);
          if (response.result.rows.length >= nextOffset) {
            setoffset(nextOffset);
            setHasMoreTop20(true);
          } else {
            setHasMoreTop20(false);
          }
        }
      },
      (error) => {}
    );
  };

  // get benefit item listing by category
  const getBenefitItems = () => {
    setLoading(true);
    setSearchStr("");
    setCategoryId("all");

    let params = {
      limit: 50,
      offset: 0,
    };
    UserService.getBenefitItemList(params).then(
      (response) => {
        setBenefitItemList(response.result);
        setAllBenefits(response.result);
        setLoading(true);

        setDataLength(response.result.length);

        let nextOffset = 50;

        if (response.result.length === 0) {
          setIsEmpty(true);
          setLoading(false);
          setoffset(0);
          setHasMore(false);
        } else {
          setIsEmpty(false);
          setLoading(false);
          if (response.result.length >= nextOffset) {
            setoffset(nextOffset);
            setHasMore(true);
          } else {
            setHasMore(false);
          }
        }
      },
      (error) => {}
    );
  };

  // Clear search
  const clearSearch = (e) => {
    e.preventDefault();
    let params = {
      limit: limit,
      offset: offset,
    };
    if (categoryId === "all" || categoryId === 0) {
      getBenefitItems();
    } else if (categoryId === "top-20") {
      getTop20BenefitItems();
    } else {
      selectCategory(categoryId);
    }
  };

  //search clean
  useEffect(() => {
    let params = {
      limit: limit,
      offset: offset,
    };
    if (searchStr.length === 0) {
      setSearchVal("");
      if (categoryId === "all" || categoryId === 0) {
        getBenefitItems();
      } else if (categoryId === "top-20") {
        getTop20BenefitItems();
      } else {
        selectCategory(categoryId);
      }
    }
  }, [searchStr]);

  // Search benefits
  const searchBenefits = (e) => {
    if (e.key === "Enter" || e.keyCode === 13 || e.which === 13) {
      setLoading(true);
      let val = e.target.value;
      setSearchStr(e.target.value);
      if (val === "") {
        setSearchVal("");
        let params = {
          limit: 50,
          offset: 0,
        };
        clearSearch(e);
        UserService.getBenefitItemList(params).then(
          (response) => {
            setBenefitItemList(response.result);
            setAllBenefits(response.result);
            setLoading(true);
            setDataLength(response.result.length);

            let nextOffset = 50;

            if (response.result.length === 0) {
              setIsEmpty(true);
              setLoading(false);
              setoffset(0);
              setHasMore(false);
            } else {
              setIsEmpty(false);
              setLoading(false);
              if (response.result.length >= nextOffset) {
                setoffset(nextOffset);
                setHasMore(true);
              } else {
                setHasMore(false);
              }
            }
          },
          (error) => {}
        );
      } else {
        let params = {
          limit: 50,
          offset: 0,
          searchTxt: val,
        };
        setSearchVal("abc");
        UserService.getBenefitItemSearchList(params).then((response) => {
          if (response.data.count > 0) {
            if (categoryId === "all" || categoryId === 0) {
              setBenefitItemList(response.data.rows);
              setAllBenefits(response.data.rows);
              setLoading(true);

              setDataLengthSearch(response.data.rows.length);

              let nextOffset = 50;

              if (response.data.rows.length === 0) {
                setIsEmpty(true);
                setLoading(false);
                setoffset(0);
                setHasMoreSearch(false);
              } else {
                setIsEmpty(false);
                setLoading(false);
                if (response.data.rows.length >= nextOffset) {
                  setoffset(nextOffset);
                  setHasMoreSearch(true);
                } else {
                  setHasMoreSearch(false);
                }
              }
            } else if (categoryId === "top-20") {
              let newItems = [];
              setBenefitItemList([]);
              for (var i = 0; i < response.data.rows.length; i++) {
                if (response.data.rows[i].top20) {
                  newItems.push(response.data.rows[i]);

                  if (i + 1 === response.data.rows.length) {
                    setBenefitItemList(newItems);
                    setAllBenefits(response.data.rows);
                    setLoading(true);

                    setDataLengthSearch(response.data.rows.length);

                    let nextOffset = 50;

                    if (response.data.rows.length === 0) {
                      setIsEmpty(true);
                      setLoading(false);
                      setoffset(0);
                      setHasMoreSearch(false);
                    } else {
                      setIsEmpty(false);
                      setLoading(false);
                      if (response.data.rows.length >= nextOffset) {
                        setoffset(nextOffset);
                        setHasMoreSearch(true);
                      } else {
                        setHasMoreSearch(false);
                      }
                    }
                  }
                }
              }
            } else {
              let newItems = [];
              setBenefitItemList([]);
              for (var i = 0; i < response.data.rows.length; i++) {
                newItems.push(response.data.rows[i]);
                if (i + 1 === response.data.rows.length) {
                  setBenefitItemList(newItems);
                  setAllBenefits(response.data.rows);
                  setLoading(true);

                  setDataLengthSearch(response.data.rows.length);

                  let nextOffset = 50;

                  if (response.data.rows.length === 0) {
                    setIsEmpty(true);
                    setLoading(false);
                    setoffset(0);
                    setHasMoreSearch(false);
                  } else {
                    setIsEmpty(false);
                    setLoading(false);
                    if (response.data.rows.length >= nextOffset) {
                      setoffset(nextOffset);
                      setHasMoreSearch(true);
                    } else {
                      setHasMoreSearch(false);
                    }
                  }
                }
              }
            }
          } else {
            setBenefitItemList(response.data.rows);
          }
          setLoading(false);
        });
      }
    }
  };

  // Search modal
  const handleCloseSearch = () => setShowSearch(false);
  const handleShowSearch = () => setShowSearch(true);

  // get family details
  const getFamilyDetails = (userId) => {
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    if (userUUID) {
      setBackUrl("/supporting_user_dashboard/" + uuid + "/" + userUUID);
    } else {
      setBackUrl("/dashboard/" + uuid);
    }
    getFamilyDetails(UserDetails.id);

    // get benefit category listing
    UserService.getBenefitCategoryList().then(
      (response) => {
        setCategoryList(response.result);
      },
      (error) => {}
    );
    let params = {
      limit: limit,
      offset: offset,
    };
    // get benefit item listing
    UserService.getBenefitItemList(params).then(
      (response) => {
        setBenefitItemList(response.result);
        setAllBenefits(response.result);
        setLoadingval(true);

        setDataLength(response.result.length);

        let nextOffset = 50;

        if (response.result.length === 0) {
          setIsEmpty(true);
          setLoadingval(false);
          setoffset(0);
          setHasMore(false);
        } else {
          setIsEmpty(false);
          setLoadingval(false);
          if (response.result.length >= nextOffset) {
            setoffset(nextOffset);
            setHasMore(true);
          } else {
            setHasMore(false);
          }
        }
      },
      (error) => {}
    );
  }, []);

  useEffect(() => {
    // Function to update the display length based on window width
    const updateDisplayLength = () => {
      const width = window.innerWidth;
      if (width >= 1440) {
        setDisplayLength(8);
      } else if (width >= 1350) {
        setDisplayLength(7);
      } else if (width >= 1200) {
        setDisplayLength(6);
      } else if (width >= 1025) {
        setDisplayLength(5);
      } else if (width >= 820) {
        setDisplayLength(4);
      } else if (width >= 710) {
        setDisplayLength(3);
      } else if (width >= 550) {
        setDisplayLength(2);
      } else if (width >= 450) {
        setDisplayLength(1);
      } else {
        setDisplayLength(0);
      }
    };

    // Call the function initially to set the display length
    updateDisplayLength();

    // Attach the event listener to window resize
    window.addEventListener("resize", updateDisplayLength);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateDisplayLength);
    };
  }, []);

  const moreDataBenifits = () => {
    let params = {
      limit: limit,
      offset: offset,
    };
    setTimeout(() => {
      UserService.getBenefitItemList(params).then(
        (response) => {
          setBenefitItemList([...benefitItemList, ...response.result]);
          setLoading(false);
          setDataLength(response.result.length + benefitItemList.length);
          let nextOffset = Number(offset) + Number(limit);

          if (response.result.length + benefitItemList.length >= nextOffset) {
            setoffset(nextOffset);
            setHasMore(true);
          } else {
            setHasMore(false);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }, 500);
  };

  const moreDataBenifitsTop20 = () => {
    let params = {
      limit: limit,
      offset: offset,
    };
    setTimeout(() => {
      UserService.getTop20BenefitItemList(params).then(
        (response) => {
          setBenefitItemList([...benefitItemList, ...response.result.rows]);
          setLoading(false);
          setDataLengthTop20(
            response.result.rows.length + benefitItemList.length
          );
          let nextOffset = Number(offset) + Number(limit);

          if (
            response.result.rows.length + benefitItemList.length >=
            nextOffset
          ) {
            setoffset(nextOffset);
            setHasMoreTop20(true);
          } else {
            setHasMoreTop20(false);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }, 500);
  };

  const moreDataBenifitsCat = () => {
    let params = {
      limit: limit,
      offset: offset,
      id: categoryId,
    };
    setTimeout(() => {
      UserService.getBenefitItemListByCategory(params).then(
        (response) => {
          setBenefitItemList([...benefitItemList, ...response.result]);
          setLoading(false);
          setDataLengthCat(response.result.length + benefitItemList.length);
          let nextOffset = Number(offset) + Number(limit);

          if (response.result.length + benefitItemList.length >= nextOffset) {
            setoffset(nextOffset);
            setHasMoreCat(true);
          } else {
            setHasMoreCat(false);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }, 500);
  };

  const moreDataBenifitsSearch = () => {
    let params = {
      limit: limit,
      offset: offset,
      searchTxt: searchStr,
    };
    setTimeout(() => {
      UserService.getBenefitItemSearchList(params).then(
        (response) => {
          setBenefitItemList([...benefitItemList, ...response.data.rows]);
          setLoading(false);
          setDataLengthSearch(
            response.data.rows.length + benefitItemList.length
          );
          let nextOffset = Number(offset) + Number(limit);

          if (
            response.data.rows.length + benefitItemList.length >=
            nextOffset
          ) {
            setoffset(nextOffset);
            setHasMoreSearch(true);
          } else {
            setHasMoreSearch(false);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }, 500);
  };

  return (
    <Container
      fluid
      style={{ minHeight: "calc(100vh - 17rem)", marginBottom: "6rem" }}
      className="d-flex flex-column"
    >
      <>
        <Row className="row align-items-center justify-content-md-end gy-xl-0 gx-2 gx-xl-3 mb-4">
          <Col>
            <h1 className="mb-0">Discounts</h1>
          </Col>
          <Col xs="auto">
            <Button
              variant="white"
              className="border-white shadow d-md-none"
              onClick={handleShowSearch}
            >
              <i className="fa-solid fa-sm fa-magnifying-glass text-dark lh-1 align-middle"></i>
            </Button>
            <InputGroup className="rounded shadow d-none d-md-flex">
              <Form.Control
                type="text"
                placeholder="Search discounts"
                value={searchStr}
                onChange={(e) => setSearchStr(e.target.value)}
                onKeyDown={searchBenefits}
                className="border-white"
              />
              {!searchStr && (
                <Button variant="white" type="submit" className="border-white">
                  <i className="fa-solid fa-sm fa-magnifying-glass text-dark lh-1 align-middle"></i>
                </Button>
              )}
              {searchStr && (
                <Button
                  variant="white"
                  type="submit"
                  className="border-white"
                  onClick={clearSearch}
                >
                  <i className="fa-solid fa-sm fa-circle-xmark text-dark lh-1 align-middle"></i>
                </Button>
              )}
            </InputGroup>
          </Col>
        </Row>
        {loadingval ? (
          <>
            <Loading />
          </>
        ) : (
          <>
            <Nav variant="pills" defaultActiveKey="all" className="mb-4">
              <Nav.Item>
                <Nav.Link
                  className={isCategoryMatched ? "force-inactive" : ""}
                  eventKey="all"
                  onClick={() => getBenefitItems()}
                >
                  All
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={isCategoryMatched ? "force-inactive" : ""}
                  eventKey="top-20"
                  onClick={() => getTop20BenefitItems()}
                >
                  Top 20
                </Nav.Link>
              </Nav.Item>
              {categoryList.slice(0, displayLength).map(({ name, id }) => {
                return (
                  <Nav.Item>
                    <Nav.Link
                      className={isCategoryMatched ? "force-inactive" : ""}
                      eventKey={id}
                      onClick={() => selectCategory(id)}
                    >
                      {name}
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
              {categoryList.length > displayLength && (
                <Nav.Item>
                  <Dropdown
                    onToggle={(isOpen) => {
                      setDropddownOpen(isOpen);
                    }}
                  >
                    <Dropdown.Toggle
                      as={NavLink}
                      className={`${
                        isCategoryMatched ? "active" : ""
                      } d-flex align-items-center`}
                      id="dropdown-basic"
                    >
                      More<i className="fa-solid fa-sm fa-angle-down ms-1"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="primary-drop-down"
                      style={{
                        height: "auto",
                        maxHeight: "15rem",
                        overflow: "auto",
                      }}
                    >
                      {categoryList.slice(displayLength).map((item, index) => (
                        <Dropdown.Item
                          className={categoryId === item.id ? "active" : ""}
                          onClick={() => {
                            selectCategory(item.id);
                          }}
                          key={index}
                        >
                          {item.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav.Item>
              )}
            </Nav>
            <h2 class="mb-4">
              {categoryId === "all"
                ? "All"
                : categoryId === "top-20"
                ? "Top 20"
                : catName}
            </h2>
            {benefitItemList.length > 0 && !loading && (
              <>
                {" "}
                {(categoryId === 0 || categoryId === "all") &&
                searchVal === "" ? (
                  <InfiniteScroll
                    dataLength={dataLength}
                    next={moreDataBenifits}
                    hasMore={hasMore}
                    style={{
                      overflow: "visible",
                    }}
                    loader={
                      <>
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                        ></span>
                        <span>Loading . . .</span>
                      </>
                    }
                  >
                    <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-3 g-md-4 g-xxl-5">
                      {benefitItemList?.map(
                        ({
                          benefitText,
                          id,
                          benefitUrl,
                          logoImg,
                          bannerImg,
                          ctaText,
                        }) => {
                          return (
                            <Col>
                              <BenefitsCardVal
                                key={id}
                                variant="basic"
                                benefitTitle={`${benefitText}`}
                                logo={`${logoImg}`}
                                banner={`${bannerImg}`}
                                link={`${benefitUrl}`}
                                linkText={`${ctaText}`}
                                showBtn="true"
                              />
                            </Col>
                          );
                        }
                      )}
                    </Row>
                  </InfiniteScroll>
                ) : categoryId === "top-20" && searchVal === "" ? (
                  <InfiniteScroll
                    dataLength={dataLengthTop20}
                    next={moreDataBenifitsTop20}
                    hasMore={hasMoreTop20}
                    style={{
                      overflow: "visible",
                    }}
                    loader={
                      <>
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                        ></span>
                        <span>Loading . . .</span>
                      </>
                    }
                  >
                    <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-3 g-md-4 g-xxl-5">
                      {benefitItemList?.map(
                        ({
                          benefitText,
                          id,
                          benefitUrl,
                          logoImg,
                          bannerImg,
                          ctaText,
                        }) => {
                          return (
                            <Col>
                              <BenefitsCardVal
                                key={id}
                                variant="basic"
                                benefitTitle={`${benefitText}`}
                                logo={`${logoImg}`}
                                banner={`${bannerImg}`}
                                link={`${benefitUrl}`}
                                linkText={`${ctaText}`}
                                showBtn="true"
                              />
                            </Col>
                          );
                        }
                      )}
                    </Row>
                  </InfiniteScroll>
                ) : categoryId !== 0 &&
                  categoryId !== "all" &&
                  categoryId !== "top-20" &&
                  searchVal === "" ? (
                  <InfiniteScroll
                    dataLength={dataLengthCat}
                    next={moreDataBenifitsCat}
                    hasMore={hasMoreCat}
                    style={{
                      overflow: "visible",
                    }}
                    loader={
                      <>
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                        ></span>
                        <span>Loading . . .</span>
                      </>
                    }
                  >
                    <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-3 g-md-4 g-xxl-5">
                      {benefitItemList?.map(
                        ({
                          benefitText,
                          id,
                          benefitUrl,
                          logoImg,
                          bannerImg,
                          ctaText,
                        }) => {
                          return (
                            <Col>
                              <BenefitsCardVal
                                key={id}
                                variant="basic"
                                benefitTitle={`${benefitText}`}
                                logo={`${logoImg}`}
                                banner={`${bannerImg}`}
                                link={`${benefitUrl}`}
                                linkText={`${ctaText}`}
                                showBtn="true"
                              />
                            </Col>
                          );
                        }
                      )}
                    </Row>
                  </InfiniteScroll>
                ) : (
                  <InfiniteScroll
                    dataLength={dataLengthSearch}
                    next={moreDataBenifitsSearch}
                    hasMore={hasMoreSearch}
                    style={{
                      overflow: "visible",
                    }}
                    loader={
                      <>
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                        ></span>
                        <span>Loading . . .</span>
                      </>
                    }
                  >
                    <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-3 g-md-4 g-xxl-5">
                      {benefitItemList?.map(
                        ({
                          benefitText,
                          id,
                          benefitUrl,
                          logoImg,
                          bannerImg,
                          ctaText,
                        }) => {
                          return (
                            <Col>
                              <BenefitsCardVal
                                key={id}
                                variant="basic"
                                benefitTitle={`${benefitText}`}
                                logo={`${logoImg}`}
                                banner={`${bannerImg}`}
                                link={`${benefitUrl}`}
                                linkText={`${ctaText}`}
                                showBtn="true"
                              />
                            </Col>
                          );
                        }
                      )}
                    </Row>
                  </InfiniteScroll>
                )}
              </>
            )}
            {benefitItemList.length === 0 && !loading && (
              <div className="card">
                <div className="card-body">No discounts found</div>
              </div>
            )}
            {loading && (
              <button className="btn" type="button">
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                ></span>
                Loading . . .
              </button>
            )}
          </>
        )}
      </>
      {/* Search for mobile */}
      <Modal show={showSearch} onHide={handleCloseSearch}>
        <Modal.Header closeButton>
          <Modal.Title>Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="rounded shadow">
            <Form.Control
              type="text"
              placeholder="Search discounts"
              value={searchStr}
              onChange={(e) => setSearchStr(e.target.value)}
              onKeyDown={searchBenefits}
              className="border-white"
            />
            {!searchStr && (
              <Button variant="white" type="submit" className="border-white">
                <i className="fa-solid fa-sm fa-magnifying-glass text-dark lh-1 align-middle"></i>
              </Button>
            )}
            {searchStr && (
              <Button
                variant="white"
                type="submit"
                onClick={clearSearch}
                className="border-white"
              >
                <i className="fa-solid fa-sm fa-circle-xmark text-dark lh-1 align-middle"></i>
              </Button>
            )}
          </InputGroup>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Benefits;
