import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import moment from "moment";
import Loading from "../../common/Loading";
import { NotificationDetailsCardBasic } from "../../stories/NotificationDetailsCard.stories";

const ViewNotificationDetails = (props) => {
  let { uuid, userUUID } = useParams();
  const [Filternewval, setFilternewval] = useState();
  const [sortFilter, setsortFilter] = useState();
  const [title, setTitle] = useState("Event Logs");
  const [subTitle, setSubTitle] = useState(
    "You can view all the event logs in this section."
  );
  const [familyNotificationList, setFamilyNotificationList] = useState([]);
  const [Loadingfilterby, setLoadingfilterby] = useState(true);
  const [familyId, setFamilyId] = useState();
  const [usersDetail, setUsersDetail] = useState([]);
  const [usersId, setUsersId] = useState("all");
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(0);

  let history = useHistory();
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  var IdValue = parseInt(localStorage.getItem("userUUIDIdVal"));

  useEffect(() => {
    const userArr = [{ value: "all", label: "All" }];
    UserService.getFamilyAllAu(uuid).then(
      (resp) => {
        for (let i = 0; i < resp.data.length; i++) {
          userArr.push({
            value: resp.data[i].id,
            label: resp.data[i].User.email,
          });
        }
        setUsersDetail(userArr);
      },
      () => {}
    );
  }, []);

  var PrimaryIdval = localStorage.getItem("PrimaryIdval");
  const filterOption = [
    { value: "all", label: "All" },
    { value: "Subscriptions", label: "Outgoings" },
    { value: "Warranties", label: "Warranties" },
    { value: "Giftcards", label: "Giftcards" },
    { value: "Tasks", label: "To Do's" },
  ];

  const sortOption = [
    { value: "ASC", label: "Ascending" },
    { value: "DESC", label: "Descending" },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0); // Total items, needed to calculate total pages
  const [mounted, setMounted] = useState(false); // Tracks if the component has fully loaded

  useEffect(() => {
    // get family details
    if (userUUID) {
      UserService.userProfile(userUUID).then((response) => {
        getFamilyDetails(response.userData.id);
        let pUser = response.userData;
        // setParentUser(response.userData);
        setTitle(pUser.firstName + " " + pUser.lastName + "'s Event logs");
        setSubTitle(
          "You can view all the actionable event logs of " +
            pUser.firstName +
            " " +
            pUser.lastName +
            " here"
        );
      });
    } else {
      getFamilyDetails(UserDetails.id);
    }
  }, []);

  // Get Family details
  const getFamilyDetails = (userId) => {
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setLoadingfilterby(true);
        let familyId = response.FamilyData.Family.uuid;
        //set all the value
        setFamilyId(familyId);
        if (response.FamilyData) {
          if (userType === "AO" || "AO + SU" || "AO + AU" || "AO + AU + SU") {
            let params = {
              fuuid: familyId,
              userId: "all",
              limit: limit,
              offset: offset,
              filterVal: "",
              sortVal: sortFilter,
            };
            if (sortFilter === "ASC") {
              params.sortVal = "ASC";
            } else {
              params.sortVal = "DESC";
            }

            params.limit = 9007199254740991;

            // storing the whole data
            UserService.getUserFamilyNotification3(params).then(
              (response) => {
                setTotalItems(response.data); // Store the total number of items for pagination
                params.limit = 50;

                //getting first 50
                UserService.getUserFamilyNotification3(params).then(
                  (res) => {
                    setFamilyNotificationList(res.data);
                    setTimeout(() => {
                      setLoadingfilterby(false);
                      setMounted(true); // Set mounted to true after the initial load
                    }, 250);
                  },
                  () => {}
                );
              },
              () => {}
            );
          } else {
            let params = {
              fuuid: familyId,
              userId:
                props.match.path === "/user_notification/:uuid/:userUUID"
                  ? IdValue
                  : userType === "AU + SU"
                  ? PrimaryIdval
                  : userType === "AU"
                  ? PrimaryIdval
                  : usersId,
              limit: limit,
              offset: offset,
              filterVal: "",
              sortVal: sortFilter,
            };
            if (sortFilter === "ASC") {
              params.sortVal = "ASC";
            } else {
              params.sortVal = "DESC";
            }

            params.limit = 9007199254740991;

            // storing the whole data
            UserService.getUserFamilyNotification3(params).then(
              (response) => {
                setTotalItems(response.data); // Store the total number of items for pagination
                params.limit = 50;

                //getting first 50
                UserService.getUserFamilyNotification3(params).then(
                  (res) => {
                    setFamilyNotificationList(res.data);
                    setTimeout(() => {
                      setLoadingfilterby(false);
                      setMounted(true); // Set mounted to true after the initial load
                    }, 250);
                  },
                  () => {}
                );
              },
              () => {}
            );
          }
        }
      },
      () => {
        setLoadingfilterby(false);
      }
    );
  };

  const newBtnfn = (
    FamilyIdVal,
    SubscriptionIdVal,
    WarrantyIdVal,
    TaskIdVal,
    GiftcardIdVal
  ) => {
    if (TaskIdVal) {
      UserService.getTaskDetailsById(TaskIdVal).then((response) => {
        history.push({
          pathname: `/view_todos_details/${uuid}/${response.data.ParentId}`,
          state: TaskIdVal,
        });
      });
    }
    if (SubscriptionIdVal) {
      history.push(`/view_outgoing_details/${uuid}/${SubscriptionIdVal}`);
    }
    if (WarrantyIdVal) {
      history.push(`/view_warranty_details/${uuid}/${WarrantyIdVal}`);
    }
    if (GiftcardIdVal) {
      history.push(`/view__vouchers_&_gift_cards/${uuid}/${GiftcardIdVal}`);
    }
  };

  //filter by user
  const EventUserDetails = (e) => {
    setUsersId(e.value);
    setLoadingfilterby(true);
    let params = {
      fuuid: familyId,
      userId: e.value,
      limit: limit,
      offset: offset,
      filterVal: "",
      sortVal: sortFilter,
    };
    if (sortFilter === "ASC") {
      params.sortVal = "ASC";
    } else {
      params.sortVal = "DESC";
    }
    getEventLogs(params);
  };

  //filter by in Event Log
  const EventLogFilter = (e) => {
    let filterVal = e.value;
    setFilternewval(filterVal);
    setLoadingfilterby(true);
    let params = {
      fuuid: familyId,
      userId:
        props.match.path === "/user_notification/:uuid/:userUUID"
          ? IdValue
          : userType === "AU + SU"
          ? PrimaryIdval
          : userType === "AU"
          ? PrimaryIdval
          : usersId,
      limit: limit,
      offset: offset,
      filterVal: filterVal,
      sortVal: sortFilter,
    };
    // eslint-disable-next-line no-lone-blocks
    {
      params.filterVal === undefined || params.filterVal === "all"
        ? (params.filterVal = "")
        : (params.filterVal = filterVal);
    }
    if (sortFilter === "ASC") {
      params.sortVal = "ASC";
    } else {
      params.sortVal = "DESC";
    }
    getEventLogs(params);
  };

  //sort by in Event Log
  const EventLogSort = (e) => {
    let sortFil = e.value;
    setsortFilter(sortFil);
    setLoadingfilterby(true);
    let params = {
      fuuid: familyId,
      userId:
        props.match.path === "/user_notification/:uuid/:userUUID"
          ? IdValue
          : userType === "AU + SU"
          ? PrimaryIdval
          : userType === "AU"
          ? PrimaryIdval
          : usersId,
      limit: limit,
      offset: offset,
      filterVal: Filternewval,
      sortVal: sortFil,
    };
    // eslint-disable-next-line no-lone-blocks
    {
      params.filterVal === undefined || params.filterVal === "all"
        ? (params.filterVal = "")
        : (params.filterVal = Filternewval);
    }
    if (sortFil === "ASC") {
      params.sortVal = "ASC";
    } else {
      params.sortVal = "DESC";
    }
    getEventLogs(params);
  };

  //for more notification in Infinite Scroll
  const getEventLogs = (params) => {
    setLoadingfilterby(true);
    let Max = { ...params }; // Clone params to avoid modifying it directly
    Max.limit = 9007199254740991; // Update the limit property

    UserService.getUserFamilyNotification3(Max).then(
      (response) => {
        setTotalItems(response.data);
      },
      () => {}
    );

    UserService.getUserFamilyNotification3(params).then(
      (res) => {
        setFamilyNotificationList(res.data);
        setLoadingfilterby(false);
      },
      () => {}
    );
  };

  //for more notification in Infinite Scroll
  const fetchTasks = (page, limitVal) => {
    setLoadingfilterby(true);
    const offset = (page - 1) * limit;

    let params = {
      fuuid: familyId,
      userId:
        props.match.path === "/user_notification/:uuid/:userUUID"
          ? IdValue
          : userType === "AU + SU"
          ? PrimaryIdval
          : userType === "AU"
          ? PrimaryIdval
          : usersId,
      limit: limitVal,
      offset: offset,
      filterVal: Filternewval,
      sortVal: sortFilter,
    };
    // eslint-disable-next-line no-lone-blocks
    {
      params.filterVal === undefined || params.filterVal === "all"
        ? (params.filterVal = "")
        : (params.filterVal = Filternewval);
    }
    if (sortFilter === "ASC") {
      params.sortVal = "ASC";
    } else {
      params.sortVal = "DESC";
    }

    UserService.getUserFamilyNotification3(params).then(
      (res) => {
        setFamilyNotificationList(res.data);
        setLoadingfilterby(false);
      },
      () => {}
    );
  };

  var userType = localStorage.getItem("userType");

  const sortRef1 = useRef(null);
  const sortRef2 = useRef(null);
  const sortRef3 = useRef(null);

  const [isSortDropdown1, setIsSortDropdown1] = useState(false);
  const [isSortDropdown2, setIsSortDropdown2] = useState(false);
  const [isSortDropdown3, setIsSortDropdown3] = useState(false);

  const [selectedSort1, setSelectedSort1] = useState(null);
  const [selectedSort2, setSelectedSort2] = useState(null);
  const [selectedSort3, setSelectedSort3] = useState(null);

  const toggleDropdown1 = () => setIsSortDropdown1((prev) => !prev);
  const toggleDropdown2 = () => setIsSortDropdown2((prev) => !prev);
  const toggleDropdown3 = () => setIsSortDropdown3((prev) => !prev);

  const closeDropdown1 = () => setIsSortDropdown1(false);
  const closeDropdown2 = () => setIsSortDropdown2(false);
  const closeDropdown3 = () => setIsSortDropdown3(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sortRef1.current && !sortRef1.current.contains(event.target)) {
        closeDropdown1();
      }
      if (sortRef2.current && !sortRef2.current.contains(event.target)) {
        closeDropdown2();
      }
      if (sortRef3.current && !sortRef3.current.contains(event.target)) {
        closeDropdown3();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const Dropdown = ({
    refProp,
    isDropdownOpen,
    toggleDropdown,
    selectedSort,
    setSelectedSort,
    items,
    label,
    icon,
  }) => (
    <Col xs="auto" className="position-relative" ref={refProp}>
      <button
        className={`btn btn-white shadow-sm d-flex align-items-center ${
          isDropdownOpen ? "dropdown-arrow" : ""
        }`}
        type="button"
        onClick={toggleDropdown}
        aria-expanded={isDropdownOpen}
      >
        <i className={icon}></i>
        <span class="text-dark ms-md-2 d-none d-xl-block"> {label}</span>
      </button>
      <ul
        className={`dropdown-menu mt-2 ${isDropdownOpen ? "show" : ""}`}
        style={{
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          minWidth: "fit-content",
          right: "0",
        }}
        ref={refProp}
      >
        {items.map((option) => (
          <li
            key={option.value}
            className="dropdown-item ellipsisText"
            style={{
              cursor: "pointer",
              color:
                selectedSort && selectedSort.value === option.value
                  ? "#020617"
                  : "",
              backgroundColor:
                selectedSort && selectedSort.value === option.value
                  ? "#36f1cc"
                  : "",
            }}
            onClick={() => {
              setSelectedSort(option);
              toggleDropdown();

              if (refProp === sortRef1) {
                EventUserDetails(option);
              }
              if (refProp === sortRef2) {
                EventLogFilter(option);
              }
              if (refProp === sortRef3) {
                EventLogSort(option);
              }
            }}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </Col>
  );

  // pagination
  useEffect(() => {
    if (mounted) {
      fetchTasks(currentPage, limit);
    }
  }, [currentPage]);

  const totalPages = Math.ceil(totalItems.length / limit);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const [pageSize, setPageSize] = useState(50); // Default value is 50

  const handlePageSizeChange = (event) => {
    const selectedValue = parseInt(event.target.value, 50); // Get the selected value as a number
    setPageSize(selectedValue); // Update the state
    setLimit(selectedValue);

    fetchTasks(currentPage, selectedValue);
    setCurrentPage(1); // Reset to page 1 after limit change
  };

  return (
    <div className="page-content warranty-pg">
      <Container fluid>
        <>
          <Row className="row align-items-center justify-content-md-end gy-xl-0 gx-2 gx-xl-3 mb-4">
            <Col>
              <h3>{title}</h3>
              <p className="sub-title">{subTitle}</p>
            </Col>

            {/* filter by user  */}
            {props.match.path !== "/user_notification/:uuid/:userUUID" &&
              userType !== "AU" &&
              userType !== "AU + SU" &&
              userType !== "SU" && (
                <Dropdown
                  refProp={sortRef1}
                  isDropdownOpen={isSortDropdown1}
                  toggleDropdown={toggleDropdown1}
                  selectedSort={selectedSort1}
                  setSelectedSort={setSelectedSort1}
                  items={usersDetail}
                  label="Filter by Users"
                  icon="fa-solid fa-users"
                />
              )}
            <Dropdown
              refProp={sortRef2}
              isDropdownOpen={isSortDropdown2}
              toggleDropdown={toggleDropdown2}
              selectedSort={selectedSort2}
              setSelectedSort={setSelectedSort2}
              items={filterOption}
              label="Filter by"
              icon="fa-solid fa-filter"
            />
            <Dropdown
              refProp={sortRef3}
              isDropdownOpen={isSortDropdown3}
              toggleDropdown={toggleDropdown3}
              selectedSort={selectedSort3}
              setSelectedSort={setSelectedSort3}
              items={sortOption}
              label="Sort by"
              icon="fa-solid fa-sort"
            />
          </Row>

          <Row className="viewNotification-row mb-6rem">
            {Loadingfilterby ? (
              <div
                style={{
                  minHeight: "calc(100vh - 20rem)",
                }}
                className="d-flex flex-column"
              >
                <Loading />
              </div>
            ) : (
              <>
                {familyNotificationList.length !== 0 ? (
                  <Row>
                    {familyNotificationList.map((notifyItem, index) => (
                      <Col xxl="8" xl="12" lg="12" key={index}>
                        <NotificationDetailsCardBasic
                          title={notifyItem.title}
                          description={notifyItem.message}
                          categoryName={
                            notifyItem.title === "Voucher created"
                              ? "Vouchers"
                              : notifyItem.module
                          }
                          date={moment(notifyItem.createdAt).format(
                            "MMM Do, YYYY"
                          )}
                          variant="basic"
                          viewPage={() =>
                            newBtnfn(
                              notifyItem.FamilyId,
                              notifyItem.SubscriptionId,
                              notifyItem.WarrantyId,
                              notifyItem.TaskId,
                              notifyItem.GiftcardId
                            )
                          }
                        />
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <div style={{ padding: "8rem", textAlign: "center" }}>
                    <p>
                      Your have no notifications to catch up in this Family.
                    </p>
                  </div>
                )}

                {/* pagination  */}
                <div className="d-sm-flex align-items-center gap-2 justify-content-end mt-7 fs-14 bold-colour pt-3">
                  {/* Page Size Dropdown */}
                  <div className="d-flex align-items-center gap-2 me-3  mb-3 mb-sm-0">
                    <label htmlFor="pageSize">Page Size:</label>
                    <select
                      id="pageSize"
                      value={pageSize} // Bind the state to the select element
                      onChange={handlePageSizeChange}
                      className="form-select p-2 me-2 fs-14"
                      style={{ width: "75px", display: "inline-block" }}
                    >
                      <option value={50}>50</option>
                      <option value={75}>75</option>
                      <option value={100}>100</option>
                      <option value={150}>150</option>
                    </select>
                    <div>
                      Showing{" "}
                      <strong>
                        {Math.min(
                          (currentPage - 1) * pageSize + 1,
                          totalItems.length
                        )}
                      </strong>{" "}
                      to{" "}
                      <strong>
                        {Math.min(currentPage * pageSize, totalItems.length)}
                      </strong>{" "}
                      of <strong>{totalItems.length}</strong>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    {/* First Page Button */}
                    <button
                      className="border-0 bg-transparent"
                      onClick={() => handlePageChange(1)}
                      disabled={currentPage === 1}
                    >
                      <i className="fas fa-angle-double-left"></i>
                    </button>
                    {/* Previous Button */}
                    <button
                      className="border-0 bg-transparent"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      <i class="fas fa-chevron-left "></i>
                    </button>
                    <div>
                      Page <strong>{totalPages === 0 ? 0 : currentPage}</strong>{" "}
                      of <strong>{totalPages}</strong>
                    </div>
                    {/* Next Button */}
                    <button
                      className="border-0 bg-transparent"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage * limit >= totalItems.length}
                    >
                      <i class="fas fa-chevron-right"></i>
                    </button>
                    {/* Last Page Button */}
                    <button
                      className="border-0 bg-transparent"
                      onClick={() => handlePageChange(totalPages)}
                      disabled={currentPage === totalPages || totalPages === 0}
                    >
                      <i className="fas fa-angle-double-right"></i>
                    </button>
                  </div>
                </div>
              </>
            )}
          </Row>
        </>
        {/* )} */}
      </Container>
    </div>
  );
};
export default ViewNotificationDetails;
