import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import Loading from "../../common/Loading";
import moment from "moment";

const FamilyDashboardGiftCards = (props) => {
  const myRef = useRef(null);
  let history = useHistory();
  let { uuid, userUUID } = useParams();
  const parentUserUUId = localStorage.getItem("parentUser.uuid");
  const [loadingspinner, setLoadingspinner] = useState(true);
  const [addGiftcardUrl, setAddGiftcardUrl] = useState("");

  const viewDetailsRoute = (giftId) => {
    if (userUUID) {
      history.push(
        `/view_user_vouchers_&_gift_cards/${uuid}/${giftId}/${userUUID}`
      );
    } else {
      history.push(`/view__vouchers_&_gift_cards/${uuid}/${giftId}`);
    }
  };

  useEffect(() => {
    // get family details
    if (userUUID) {
      UserService.userProfile(userUUID).then((response) => {
        setAddGiftcardUrl(
          "/add_user_vouchers_&_gift_cards/" + uuid + "/" + userUUID
        );
      });
    } else {
      setAddGiftcardUrl("/add_vouchers_&_gift_cards/" + uuid);
    }
  }, []);

  useEffect(() => {
    if (props.allData) {
      setLoadingspinner(false);
    }
  }, [props.allData]);

  const validateDate = (date) => {
    // Get today's date
    const today = new Date();

    // Create the target date from the ISO string
    const targetDate = new Date(date);

    // Calculate the time difference in milliseconds
    const timeDifference = targetDate - today;

    // Convert the time difference from milliseconds to days
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  };

  return (
    <div ref={myRef}>
      <div className="mb-3 d-flex">
        <h2 className="me-auto mb-0">Vouchers & Gift cards</h2>
        <Link
          className="btn btn-white btn-sm btn-circle flex-shrink-0"
          to={addGiftcardUrl}
        >
          <i className="fa-solid fa-sm fa-plus text-dark"></i>
        </Link>
        <Link
          className="btn btn-white btn-sm btn-circle flex-shrink-0 ms-2"
          to={
            uuid
              ? !parentUserUUId
                ? `/vouchers_&_gift_cards/${uuid}`
                : `/user_vouchers_&_gift_cards/${uuid}/${parentUserUUId}`
              : "#"
          }
        >
          <i className="fa-solid fa-sm fa-angle-right text-dark"></i>
        </Link>
      </div>
      {loadingspinner ? (
        <>
          <div className="loadind-page">
            <Loading />
          </div>
        </>
      ) : (
        <>
          {props.allData.giftCardData.length === 0 ? (
            <>
              <p className="small mb-0">
                You currently have no Vouchers & Gift Cards set up!
              </p>
            </>
          ) : (
            <>
              {props.allData.giftCardData.map((giftcard, index) => (
                <>
                  <hr className="my-3" />
                  <Row
                    className="align-items-center gx-3 gy-0 gx-md-4"
                    onClick={() => viewDetailsRoute(giftcard.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <Col xs="auto" className="">
                      <div className="img-wrapper img-wrapper-voucher">
                        <img
                          src={
                            giftcard.Supplier &&
                            giftcard.Supplier.logo !== null &&
                            giftcard.Supplier.logo !== ""
                              ? giftcard.Supplier.logo
                              : "https://el-backenddata.s3.eu-west-2.amazonaws.com/adminassets/Default_logo.jpg"
                          }
                          alt="Gift card product logo"
                          className="img-fluid"
                        />
                      </div>
                    </Col>
                    <Col xs="12" xl="9" xxl="10" className="ms-1">
                      <div className="small fw-semibold text-dark text-truncate">
                        {giftcard.Supplier && giftcard.Supplier.name}
                      </div>
                    </Col>
                    <Col md="5" xl="6" className="d-none d-md-flex">
                      <div
                        className={`small ${
                          validateDate(giftcard.expiryDate) <= 30
                            ? "text-danger"
                            : validateDate(giftcard.expiryDate) <= 60 &&
                              validateDate(giftcard.expiryDate) > 30
                            ? "text-orange"
                            : validateDate(giftcard.expiryDate) <= 90 &&
                              validateDate(giftcard.expiryDate) > 60
                            ? "text-lightgreen"
                            : "text-success"
                        } fw-semibold text-truncate`}
                      >
                        {moment(giftcard.expiryDate).format("Do MMM, YYYY")}
                      </div>
                    </Col>
                    <Col xs="6" md="4" className="ms-auto">
                      <div className="small fw-semibold text-dark text-end">
                        {giftcard.totalAmount
                          ? "£" + giftcard.totalAmount
                          : "N/A"}
                      </div>
                    </Col>
                  </Row>
                </>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default FamilyDashboardGiftCards;
