import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

function BenefitsCard(props) {
  const {
    benefitTitle,
    logo,
    banner,
    link,
    linkText,
    showBtn,
    propClassName,
    ...rest
  } = props;

  console.log(props);

  return (
    <div className={`card-body card ${propClassName}`}>
      <div className="d-flex align-items-center mb-2">
        <img
          className="img-third-party-logo img-third-party-logo-xs me-auto rounded-circle "
          src={logo}
          alt="logo"
          style={{ border: "1px solid #d9d9d9" }}
        />
        {/* <div className="small fw-semibold text-warning">31 March</div> */}
      </div>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250 }}
        overlay={
          <Tooltip id="">
            <div className="">{linkText}</div>
          </Tooltip>
        }
      >
        <div
          className="h5 mb-1"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: "pointer",
          }}
        >
          {linkText}
        </div>
      </OverlayTrigger>
      <div className="small text-body">{benefitTitle}</div>
      {showBtn === "true" && (
        <a
          href={link}
          style={{ textDecoration: `none` }}
          target="_blank"
          alt={link}
          rel="noreferrer"
        >
          <Button className="mt-3 w-100" size="sm" variant="primary">
            Get discount
          </Button>
        </a>
      )}
    </div>
  );
}

export default BenefitsCard;
