import React from "react";
import { Container, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";

const Header = () => {
  return (
    <Navbar>
      <Container fluid className="justify-content-center">
        <Link className="order-xl-first" to={`/`}>
          <img src={Logo} alt="" />
        </Link>
      </Container>
    </Navbar>
  );
};

export default Header;
