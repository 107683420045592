import React, { useState, useEffect } from "react";
import NavBar from "../templates/NavBar";
import AuthHeader from "../templates/AuthHeader";
import Footer from "../Footer/newFooter";

const AuthLayout = (props) => {
  const [navbarOpen, setNavbarOpen] = useState(true);
  const handleViewNavbar = () => setNavbarOpen(!navbarOpen);
  var contentClass = navbarOpen ? "open" : "";
  var splitUrl = window.location.href.split("/");

  return (
    <>
      <NavBar isOpen={navbarOpen} toggleNavbar={handleViewNavbar} />
      {/* to pass data in props children we use React.cloneElement */}
      <div className={splitUrl[3] !== "confirmation" && splitUrl[3] !== "onboardUser" ? `content ${contentClass}` : ``} >
        <AuthHeader />
        {React.cloneElement(props.children, { isOpen: navbarOpen })}
        <Footer />
      </div>
    </>
  );
};

export default AuthLayout;
