import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import RotateSvg from "../../assets/images/rotateSvg.svg";
import MinusIcon from "../../assets/images/negative_icon.png";
import AddIcon from "../../assets/images/positive_icon.png";
import DeleteIcon from "../../assets/images/delete.png";
import UserService from "../../services/user.service";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import "./ProfileModal.scss";

function ProfileModal({
  modalOpen,
  setModalOpen,
  newProPicImg,
  setNewProPicImg,
}) {
  const [imageReaderurl, setImageReaderUrl] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [imgTransform, setImgTransform] = useState(0);
  const [zoomValue, setZoomValue] = useState(100);
  const [disableUpload, setDisableUpload] = useState(true);
  const [selectedFile, setSelectedFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const [sliderVal, setSliderVal] = useState(0);
  const image = useRef();
  const holder = useRef();
  const DEFAULT_AVATAR =
    "https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png";

  useEffect(() => {
    setImageReaderUrl(newProPicImg);

    document.getElementById("uploadphoto-modal-slider").oninput = function () {
      var value = ((this.value - this.min) / (this.max - this.min)) * 100;
      this.style.background =
        "linear-gradient(to right, #003A6F 0%, #003A6F " +
        value +
        "%,#9AC2E5  " +
        value +
        "%, #9AC2E5 100%)";
    };
  }, []);

  const uploadPic = (file) => {
    const formData = new FormData();
    if (file) {
      formData.append("file", file, file.name.replace(/ /g, ""));
      localStorage.setItem("S3File", JSON.stringify(file.name));
      UserService.uploadUserDocuments(formData).then(
        (response) => {
          setLoading(false);
          setNewProPicImg(response.link);
          setShowToast(true);
          setSuccessToast(true);
          setToastMessage(response.message);
          setModalOpen(false);
          let formData = {};
          formData.avatar = response.link;
          UserService.updateUserProfile(UserDetails.uuid, formData).then(
            (res) => {
              //setNewProPicImg(response.link);
              UserDetails.avatar = response.link;
              localStorage.setItem("UserDetails", JSON.stringify(UserDetails));
              setShowToast(true);
              setSuccessToast(true);
              setToastMessage(res.message);
              setLoading(false);
              setModalOpen(false);
              setTimeout(function () {
                window.location.reload();
              }, 500);
            },
            (error) => {
              setLoading(false);
            }
          );
        },
        (error) => {
          setShowToast(true);
          setSuccessToast(true);
          setToastMessage("hello");
          setLoading(false);
          setModalOpen(false);
          setTimeout(function () {
            window.location.reload();
          }, 500);
        }
      );
    } else {
    }
  };

  const readURL = (input) => {
    const file = input.target.files[0];
    localStorage.setItem("S3MainFile", JSON.stringify(input.target.files[0]));
    setSelectedFile(file);
    setDisableUpload(false);

    if (input.target.files[0]) {
      var reader = new FileReader();

      reader.onload = function (e) {
        setImageReaderUrl(e.target.result);
        localStorage.setItem("file", JSON.stringify(e.target.result));
      };
      reader.readAsDataURL(input.target.files[0]);
    }
  };

  useEffect(() => {
    if (imgTransform === 360) {
      setImgTransform(0);
    }
  }, [imgTransform]);

  const rotateImg = () => {
    setImgTransform(imgTransform + 90);
  };

  const minimizeImg = () => {
    if (zoomValue - 10 > 99) {
      setZoomValue(zoomValue - 10);
    }
    setSliderVal((prev) => Math.max(prev - 10, 0));
  };
  const zoomImg = (e) => {
    if (zoomValue + 10 < 201) {
      setZoomValue(zoomValue + 10);
    }
    setSliderVal((prev) => Math.min(prev + 10, 100));
  };

  const zoomWithSlider = (e) => {
    setSliderVal(Number(e.target.value));
    setZoomValue(100 + Number(e.target.value));
  };
  function dragElement(elmnt) {
    var pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;

    elmnt.onmousedown = dragMouseDown;

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      holder.current.onmouseup = closeDragElement;
      holder.current.onmouseleave = closeDragElement;

      // call a function whenever the cursor moves:
      holder.current.onmousemove = elementDrag;
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      //&&image.current.offsetRight<0&&image.current.offsetTop<0&&image.current.offsetTop<0)
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      let offsetBottom =
        elmnt.offsetParent.offsetHeight - elmnt.offsetTop - elmnt.offsetHeight;
      let offsetRight =
        elmnt.offsetParent.offsetWidth - elmnt.offsetLeft - elmnt.offsetWidth;

      let top = elmnt.style.top;
      let left = elmnt.style.left;

      if (elmnt.offsetHeight > holder.current.offsetHeight) {
        if (elmnt.offsetTop <= 0 && offsetBottom <= 0) {
          elmnt.style.top = elmnt.offsetTop - pos2 + "px";
          //document.getElementById('imagepicker-img').style.top =  (elmnt.offsetTop*0.4 - pos2*0.4) + "px";
        } else if (offsetBottom > 0) {
          elmnt.style.top = `${Number(top.replace("px", "")) + 3}px`;
        } else if (elmnt.offsetTop >= 0) {
          elmnt.style.top = "-2px";
        }
      }
      if (elmnt.offsetWidth > holder.current.offsetWidth) {
        if (elmnt.offsetLeft <= 0 && offsetRight <= 0) {
          elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
          //document.getElementById('imagepicker-img').style.left = (elmnt.offsetLeft*0.4 - pos1*0.4) + "px";
        } else if (elmnt.offsetLeft > 0) {
          elmnt.style.left = `-2px`;
        } else if (offsetRight > 0) {
          elmnt.style.left = `${Number(left.replace("px", "")) + 3}px`;
        }
      }
    }

    function closeDragElement() {
      /* stop moving when mouse button is released:*/
      holder.current.onmouseup = null;
      holder.current.onmousemove = null;
      holder.current.onmouseleave = null;
    }
  }

  const deleteProfileIcon = () => {
    setSelectedFile("");
    document.querySelector("#upload-profilephoto").value = "";
    setImageReaderUrl(DEFAULT_AVATAR);
    setDisableUpload(true);
    setImgTransform(0);
  };

  const uploadCroppedProfile = () => {
    setLoading(true);
    if (selectedFile) {
      uploadPic(selectedFile);
      setTimeout(() => {
        setShowToast(true);
        setSuccessToast(true);
        setToastMessage("File uploaded successfully");
      }, 500);
      setTimeout(() => {
        setModalOpen(false);
      }, 2000);
    } else {
      setTimeout(() => {
        setShowToast(true);
        setSuccessToast(true);
        setToastMessage("File uploaded successfully");
      }, 500);
      setTimeout(() => {
        setModalOpen(false);
      }, 2000);
      setTimeout(function () {
        window.location.reload();
      }, 3000);
    }
  };

  useEffect(() => {
    dragElement(image.current);
  }, []);

  return (
    <>
      <ToastBlockContainer
        successToast={successToast}
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <Row>
        <Col lg="12">
          <div ref={holder} className="uploadphoto-modal-ImgHolder">
            <img
              ref={image}
              style={{
                transform: `rotate(${imgTransform}deg)`,
                height: `${zoomValue}%`,
              }}
              src={imageReaderurl !== "" ? imageReaderurl : ""}
              alt=""
            />
            {disableUpload && (
              <label className="upload-label" htmlFor="upload-profilephoto">
                <span>Upload</span>
              </label>
            )}
            {!disableUpload && (
              <div className="delete-profile-icon" onClick={deleteProfileIcon}>
                <img src={DeleteIcon} alt="" />
              </div>
            )}
          </div>
        </Col>
        <Col
          lg="12"
          className="text-left d-flex flex-column justify-content-center"
        >
          <div className="bold-colour fs-18 fw-medium my-3">Zoom Image</div>

          <div className="uploadphoto-modal-sliderHolder mb-3">
            <div onClick={minimizeImg} className="uploadphoto-modal-plus">
              <img src={MinusIcon} alt="" />
            </div>
            <input
              onChange={zoomWithSlider}
              type="range"
              min="0"
              max="100"
              value={sliderVal} // Bind the input value to the state
              id="uploadphoto-modal-slider"
              className="uploadphoto-modal-slider"
            />
            <div onClick={zoomImg} className="uploadphoto-modal-plus">
              <img src={AddIcon} alt="" />
            </div>
          </div>

          <button
            onClick={rotateImg}
            className="mb-5 uploadphoto-modal-rotatebtn "
          >
            <img src={RotateSvg} alt="" />
            Rotate Image
          </button>
          {imageReaderurl ===
          "https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png" ? (
            <button
              className="uploadphoto-modal-label primary"
              // disabled={disableUpload}
              onClick={uploadCroppedProfile}
              disabled
            >
              Save
              {loading && (
                <span className="ml-3 spinner-border spinner-border-sm"></span>
              )}
            </button>
          ) : (
            <button
              className="uploadphoto-modal-label primary"
              // disabled={disableUpload}
              onClick={uploadCroppedProfile}
            >
              Save
              {loading && (
                <span className="ml-3 spinner-border spinner-border-sm"></span>
              )}
            </button>
          )}

          <input
            onChange={(e) => {
              readURL(e);
            }}
            type="file"
            id="upload-profilephoto"
            style={{ display: "none" }}
            accept="image/*"
          />
        </Col>
      </Row>
    </>
  );
}

export default ProfileModal;
