import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Image,
  Accordion,
  Dropdown,
} from "react-bootstrap";
import Loading from "../../common/Loading";
import EditIcon from "../../assets/images/Edit.svg";
import DeleteIcon from "../../assets/images/Delete.svg";
import userService from "../../services/user.service";
import UserService from "../../services/user.service";
import moment from "moment";
import { Primary } from "../../stories/Button.stories";
import { Small } from "../../stories/Input.stories";
import { Info } from "../../stories/Button.stories";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import { SeatOccupied } from "../../stories/SeatCard.stories";
import { AddSupportUserBasic } from "../../stories/AddSupportUserCard.stories";

const ManageUsers = (props) => {
  const splitUrl = window.location.href.split("/");
  const UserDetails = JSON.parse(localStorage.getItem("UserDetails"));

  // form field initial values
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
  };

  // initial value
  const initialSuFmlyValues = {
    su_id: "",
  };
  const [loading, setLoading] = useState(true);
  const [familyDetails, setfamilyDetails] = useState({});
  const [audata, setaudata] = useState([]);
  const [sudata, setsudata] = useState([]);
  const [usertype, setusertype] = useState([]);
  const [mainLoading, setmainLoading] = useState(false);
  const [suppLoading, setsuppLoading] = useState(false);
  const [mainUser, setmainUser] = useState(false);
  const [supportUser, setsupportUser] = useState(false);
  const [mainLeft, setMainLeft] = useState({});
  const [suppLeft, setSuppLeft] = useState({});
  const [mainRight, setMainRight] = useState([]);
  const [suppRight, setSuppRight] = useState([]);
  const [showInviteAu, setShowInviteAu] = useState(false);
  const [showInviteSu, setShowInviteSu] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [formLoading, setFormLoading] = useState(false);
  const [apiRefresh, setApiRefresh] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [familyDt, setfamilyDt] = useState({});
  const [showRemoveAuUser, setShowRemoveAuUser] = useState(false);
  const [showRemoveSuUser, setShowRemoveSuUser] = useState(false);
  const [auInviteFirstName, setAuInviteFirstName] = useState("");
  const [auInviteLastName, setAuInviteLastName] = useState("");
  const [auInviteEmail, setAuInviteEmail] = useState("");
  const [suInviteFirstName, setSuInviteFirstName] = useState("");
  const [suInviteLastName, setSuInviteLastName] = useState("");
  const [suInviteEmail, setSuInviteEmail] = useState("");
  const [auInviteId, setAuInviteId] = useState("");
  const [auInviteImage, setAuInviteImage] = useState("");
  const [suInviteId, setSuInviteId] = useState("");
  const [suInviteImage, setSuInviteImage] = useState("");
  const [removeUserModelTitle, setRemoveUserModelTitle] = useState("");
  const [removeUserModelTitleSu, setRemoveUserModelTitleSu] = useState("");
  const [supportMainUser, setSupportMainUser] = useState([]);
  const [isChooseInviteDisabled, setIsChooseInviteDisabled] = useState(true);
  const [showChooseSu, setShowChooseSu] = useState(false);
  const [chooseSuFormLoading, setChooseSuFormLoading] = useState(false);
  const [suFmlyFormValues, setSuFmlyFormValues] = useState(initialSuFmlyValues);
  const [suFmlyFormErrors, setSuFmlyFormErrors] = useState({});
  const [checkSU, setcheckSU] = useState([]);
  const [newValue, setNewValue] = useState([]);
  const [showChooseexistSU, setShowChooseExistSU] = useState(false);

  useEffect(() => {
    setApiRefresh(false);

    UserService.getUserSingleFamilyDetails(splitUrl[4], UserDetails.id).then(
      (response) => {
        setfamilyDt(response.FamilyData);

        UserService.onboardedUserAU(
          splitUrl[4],
          response.FamilyData.UserId,
          `AU`
        ).then((respo) => {
          setcheckSU(respo.data.usersData);
        });

        UserService.getOnBoardedFamilyUserList(
          splitUrl[4],
          response.FamilyData.UserId,
          "AU"
        ).then((userlistres) => {
          UserService.getFamilyAllSu(splitUrl[4]).then((su_res) => {
            const arr1 = su_res.data.map((ele) => ele.UserId);
            const arr2 = userlistres.usersData.map(
              (ele) => ele.Family_User.UserId
            );
            const bigCities = arr1.filter((ele) => !arr2.includes(ele));
            const newSU = [];

            bigCities.map((ele) => {
              su_res.data.filter((ele1) => {
                if (ele1.UserId === ele && ele1.UserId !== UserDetails.id) {
                  newSU.push(ele1);
                }
              });
            });

            setNewValue(newSU);
          });
        });
      }
    );
    userService.getUserAccountPlan(UserDetails.uuid).then((res) => {
      for (let i = 0; i < res.usersFamilyData.length; i++) {
        if (res.usersFamilyData[i].Family.uuid === splitUrl[4]) {
          setfamilyDetails(res.usersFamilyData[i]);
          if (
            res.usersFamilyData[i].isAo &&
            res.usersFamilyData[i].isAu &&
            res.usersFamilyData[i].isSu
          ) {
            setusertype("AO + AU + SU");
          } else if (
            res.usersFamilyData[i].isAo &&
            res.usersFamilyData[i].isAu
          ) {
            setusertype("AO + AU");
          } else if (
            res.usersFamilyData[i].isAo &&
            res.usersFamilyData[i].isSu
          ) {
            setusertype("AO + SU");
          } else if (
            res.usersFamilyData[i].isAu &&
            res.usersFamilyData[i].isSu
          ) {
            setusertype("AU + SU");
          } else if (res.usersFamilyData[i].isAo) {
            setusertype("AO");
          } else if (res.usersFamilyData[i].isAu) {
            setusertype("AU");
          } else if (res.usersFamilyData[i].isSu) {
            setusertype("SU");
          }
        }
      }
      userService.updateSUAURel(splitUrl[4]).then((response) => {
        let auarray = [];
        let suarray = [];
        for (let i = 0; i < response.data.length; i++) {
          if (
            response.data[i].isAu &&
            response.data[i].inviteStatus !== "Removed" &&
            response.data[i].inviteStatus !== "Cancelled"
          ) {
            auarray.push(response.data[i]);
          }
          if (
            response.data[i].isSu &&
            response.data[i].inviteStatus !== "Removed" &&
            response.data[i].inviteStatus !== "Cancelled"
          ) {
            suarray.push(response.data[i]);
          }
        }
        setaudata(auarray);
        setsudata(suarray);

        setTimeout(() => {
          setLoading(false);
        }, 500);
      });
    });
  }, [apiRefresh]);

  // main user edit button modal close
  const mainUserClose = () => {
    setmainUser(false);
  };
  // support user edit button modal close
  const supportUserClose = () => {
    setsupportUser(false);
  };

  // main user modal open
  const mainUserDtls = (userList) => {
    setMainLeft(userList);
    setmainUser(true);
    setmainLoading(true);
    const data1 = userList.UserId;
    UserService.getOnBoardedFamilyUserList(splitUrl[4], data1, `AU`).then(
      (res) => {
        setMainRight(res.usersData);

        setTimeout(() => {
          setmainLoading(false);
        }, 250);
      }
    );
  };

  // support user modal open
  const supportUserDtls = (userList) => {
    setSuppLeft(userList);
    setsupportUser(true);
    setsuppLoading(true);

    const data1 = userList.UserId;

    UserService.onboardedUserAU(splitUrl[4], data1, `SU`).then((res) => {
      setSuppRight(res.data.usersData);

      // check main user for support
      UserService.checkMainUser(splitUrl[4], userList.id).then((response) => {
        setSupportMainUser(response.data.data);
        setTimeout(() => {
          setsuppLoading(false);
        }, 250);
      });
    });
  };

  // invite main user
  const handleShowInviteAuShow = () => {
    setShowInviteAu(true);
    setFormValues(initialValues);
    setFormErrors({});
  };

  // invite main user modal close
  const handleShowInviteAuClose = () => {
    setShowInviteAu(false);
  };

  // main user invite submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    // if (formValues.email === UserDetails.email) {
    //   // handleInviteMyselfAsAU();
    // } else {
    //   // setIsAuSubmit(true);
    // }
  };

  // main user form change function
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // validate form for main user
  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.firstname) {
      errors.msg = "Please enter the required fields";
      errors.firstname = "Yes";
    }
    if (!values.lastname) {
      errors.msg = "Please enter the required fields";
      errors.lastname = "Yes";
    }
    if (!values.email) {
      errors.msg = "Please enter the required fields";
      errors.email = "Yes";
    } else if (!regex.test(values.email)) {
      errors.msg = "This is not a valid email format !";
      errors.email = "Yes";
    }
    if (Object.keys(errors).length === 0) {
      setFormLoading(true);
      let data = {
        firstName: formValues.firstname,
        lastName: formValues.lastname,
        to: formValues.email,
        from: UserDetails.uuid,
        for: splitUrl[4],
        isAU: true,
      };
      UserService.sendFamilyInvite(data).then(
        (res) => {
          if (res.status === 400) {
            setFormLoading(false);
            handleShowInviteAuClose();
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(res.data.message);
            setApiRefresh(true);
            setFormValues({});
            setFormErrors({});
          } else {
            setFormLoading(false);
            handleShowInviteAuClose();
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("User have been successfully invited");
            setApiRefresh(true);
            setFormValues({});
            setFormErrors({});
          }
        },
        (error) => {}
      );
    }
    return errors;
  };

  // invite ao as au
  const handleInviteMyselfAsAU = () => {
    let data = {
      isAU: true,
    };
    // api call
    UserService.sendFamilyInviteToAoAsAu(splitUrl[4], data).then(
      (res) => {
        setFormLoading(false);
        handleShowInviteAuClose();
        setSuccessToast(true);
        setShowToast(true);
        setToastMessage("User have been successfully invited");
        setApiRefresh(true);
        setFormValues({});
        setFormErrors({});
      },
      (error) => {
        setFormLoading(false);
      }
    );
  };

  // invite su
  const handleShowInviteSuShow = () => {
    setShowInviteSu(true);
    setFormValues(initialValues);
    setmainUser(false);
    setFormErrors({});
  };

  // invite su modal close
  const handleShowInviteSuClose = () => {
    setShowInviteSu(false);
  };

  // su user invite submit
  const handleSuInviteSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate1(formValues));
    // if (formValues.email === UserDetails.email) {
    //   // handleInviteMyselfAsAU();
    // } else {
    //   // setIsAuSubmit(true);
    // }
  };

  // validate form for support user
  const validate1 = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.firstname) {
      errors.msg = "Please enter the required fields";
      errors.firstname = "Yes";
    }
    if (!values.lastname) {
      errors.msg = "Please enter the required fields";
      errors.lastname = "Yes";
    }
    if (!values.email) {
      errors.msg = "Please enter the required fields";
      errors.email = "Yes";
    } else if (!regex.test(values.email)) {
      errors.msg = "This is not a valid email format !";
      errors.email = "Yes";
    }
    if (Object.keys(errors).length === 0) {
      setFormLoading(true);
      let data = {
        firstName: formValues.firstname,
        lastName: formValues.lastname,
        to: formValues.email,
        from: UserDetails.uuid,
        for: splitUrl[4],
        isAU: false,
      };
      UserService.sendFamilySuInvite(data).then(
        (res) => {
          if (res.status === 400) {
            setFormLoading(false);
            handleShowInviteSuClose();
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(res.data.message);
            setApiRefresh(true);
            setFormValues({});
            setFormErrors({});
          } else {
            setFormLoading(false);
            handleShowInviteSuClose();
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("User have been successfully invited");
            setApiRefresh(true);
            setFormValues({});
            setFormErrors({});
          }
        },
        (error) => {}
      );
    }
    return errors;
  };

  // delete au
  const handleShowRemoveAuUserShow = (userList, type) => {
    setsupportUser(false);
    setShowRemoveAuUser(true);
    setRemoveUserModelTitle(type);
    setAuInviteFirstName(
      userList.User
        ? userList.User.firstName === "null"
          ? ""
          : userList.User.firstName
        : userList.supportingUserId.User.firstName
    );
    setAuInviteLastName(
      userList.User
        ? userList.User.lastName === "null"
          ? ""
          : userList.User.lastName
        : userList.supportingUserId.User.lastName
    );
    setAuInviteEmail(
      userList.User ? userList.User.email : userList.supportingUserId.User.email
    );
    setAuInviteImage(
      userList.User
        ? userList.User.avatar
        : userList.supportingUserId.User.avatar
    );
    // usertype === "AO + AU + SU" || usertype === "AO + SU"
    // ? setAuInviteId(userList.supportingUserId.id)
    // :
    setAuInviteId(userList.id);
  };

  // delete su
  const handleShowRemoveSuUserShow = (userList, type) => {
    setShowRemoveSuUser(true);
    setsupportUser(false);
    setmainUser(false);
    setRemoveUserModelTitleSu(type);
    setSuInviteFirstName(
      userList.User
        ? userList.User.firstName === "null"
          ? ""
          : userList.User.firstName
        : userList.Family_User.User.firstName
    );
    setSuInviteLastName(
      userList.User
        ? userList.User.lastName === "null"
          ? ""
          : userList.User.lastName
        : userList.Family_User.User.lastName
    );
    setSuInviteEmail(
      userList.User ? userList.User.email : userList.Family_User.User.email
    );
    setSuInviteImage(
      userList.User ? userList.User.avatar : userList.Family_User.User.avatar
    );
    setSuInviteId(userList.id);
  };

  // delete su
  const handleShowRemoveSuUserShow1 = (userList, type) => {
    setShowRemoveSuUser(true);
    setsupportUser(false);
    setmainUser(false);
    setRemoveUserModelTitleSu(type);
    setSuInviteFirstName(
      userList.User
        ? userList.User.firstName === "null"
          ? ""
          : userList.User.firstName
        : userList.Family_User.User.firstName
    );
    setSuInviteLastName(
      userList.User
        ? userList.User.lastName === "null"
          ? ""
          : userList.User.lastName
        : userList.Family_User.User.lastName
    );
    setSuInviteEmail(
      userList.User ? userList.User.email : userList.Family_User.User.email
    );
    setSuInviteImage(
      userList.User ? userList.User.avatar : userList.Family_User.User.avatar
    );
    setSuInviteId(userList.Family_User.id);
  };

  // delete su
  const handleShowRemoveSUUser = (userList, type) => {
    setShowRemoveSuUser(true);
    setsupportUser(false);
    setmainUser(false);
    setRemoveUserModelTitleSu(type);
    setSuInviteFirstName(userList.supportingUserId.User.firstName);
    setSuInviteLastName(userList.supportingUserId.User.lastName);
    setSuInviteEmail(userList.supportingUserId.User.email);
    setSuInviteImage(userList.supportingUserId.User.avatar);
    setSuInviteId(userList.supportingUserId.id);
  };

  // au delete modal close
  const handleShowRemoveAuUserClose = () => {
    setShowRemoveAuUser(false);
    // setmainUser(true);
  };

  // su delete modal close
  const handleShowRemoveSuUserClose = () => {
    setShowRemoveSuUser(false);
    // setmainUser(true);
  };

  // au user delete function
  const handleCancelAuInvite = (inviteId, type) => {
    if (type === "Revoke Main") {
      let updateData = {
        fuuid: splitUrl[4],
        id: inviteId,
        pu: true,
      };
      // revoke family invite
      UserService.revokeFamilyInviteStatus(updateData).then(
        (response) => {
          if (response.status === 400) {
            setShowRemoveAuUser(false);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(response.data.error);
            setApiRefresh(true);
          } else {
            setShowRemoveAuUser(false);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("Invitation revoked successfully");
            setApiRefresh(true);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    } else if (type === "Remove Main") {
      UserService.removeFamilyUser(splitUrl[4], [inviteId], []).then(
        (response) => {
          if (response.status === 400) {
            setShowRemoveAuUser(false);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(response.data.error);
            setApiRefresh(true);
          } else {
            setShowRemoveAuUser(false);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("User removed successfully");
            setApiRefresh(true);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    } else if (type === "Remove Support") {
      UserService.removeFamilyUser(splitUrl[4], [], [inviteId]).then(
        (response) => {
          if (response.status === 400) {
            setShowRemoveAuUser(false);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(response.data.error);
            setApiRefresh(true);
          } else {
            setShowRemoveAuUser(false);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("User removed successfully");
            setApiRefresh(true);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    } else if (type === "Remove this user as Support") {
      UserService.removeSUFromAuFamily(
        splitUrl[4],
        familyDetails.id,
        inviteId
      ).then(
        (response) => {
          if (response.status === 400 || response.status === 404) {
            setShowRemoveAuUser(false);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(response.data.error);
            setApiRefresh(true);
          } else {
            setShowRemoveAuUser(false);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("User removed as SU successfully");
            setApiRefresh(true);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    } else if (type === "Stop Supporting this") {
      UserService.removeSUFromAuFamily(
        splitUrl[4],
        inviteId,
        familyDetails.id
      ).then(
        (response) => {
          if (response.status === 400 || response.status === 404) {
            setShowRemoveAuUser(false);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(response.data.error);
            setApiRefresh(true);
          } else {
            setShowRemoveAuUser(false);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("Stop supporting this user successfully");
            setApiRefresh(true);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }
  };

  // su user delete function
  const handleCancelSuInvite = (inviteId, type) => {
    if (type === "Revoke Support") {
      let updateData = {
        fuuid: splitUrl[4],
        id: inviteId,
        pu: false,
      };
      // revoke family invite
      UserService.revokeFamilyInviteStatus(updateData).then(
        (response) => {
          if (response.status === 400) {
            setShowRemoveSuUser(false);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(response.data.error);
            setApiRefresh(true);
          } else {
            setShowRemoveSuUser(false);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("Invitation revoked successfully");
            setApiRefresh(true);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    } else if (type === "Remove Support") {
      UserService.removeFamilyUser(splitUrl[4], [], [inviteId]).then(
        (response) => {
          if (response.status === 400) {
            setShowRemoveSuUser(false);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(response.data.error);
            setApiRefresh(true);
          } else {
            setShowRemoveSuUser(false);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("User removed successfully");
            setApiRefresh(true);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    } else if (type === "Remove Support") {
      UserService.removeFamilyUser(splitUrl[4], [], [inviteId]).then(
        (response) => {
          if (response.status === 400) {
            setShowRemoveSuUser(false);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(response.data.error);
            setApiRefresh(true);
          } else {
            setShowRemoveSuUser(false);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("User removed successfully");
            setApiRefresh(true);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    } else if (type === "Remove this user as Support") {
      UserService.removeSUFromAuFamily(
        splitUrl[4],
        familyDetails.id,
        inviteId
      ).then(
        (response) => {
          if (response.status === 400 || response.status === 404) {
            setShowRemoveSuUser(false);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(response.data.error);
            setApiRefresh(true);
          } else {
            setShowRemoveSuUser(false);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("User removed as SU successfully");
            setApiRefresh(true);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    } else if (type === "Stop Supporting this") {
      UserService.removeSUFromAuFamily(
        splitUrl[4],
        inviteId,
        familyDetails.id
      ).then(
        (response) => {
          if (response.status === 400 || response.status === 404) {
            setShowRemoveSuUser(false);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(response.data.error);
            setApiRefresh(true);
          } else {
            setShowRemoveSuUser(false);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("Stop supporting this user successfully");
            setApiRefresh(true);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }
  };

  // chose existing main user
  const handleShowChooseSuShow = () => {
    setIsChooseInviteDisabled(true);
    setShowChooseSu(true);
    supportUserClose();
  };

  // close modal of existing modal
  const handleShowChooseSuClose = () => {
    setShowChooseSu(false);
    setsupportUser(true);
  };

  // choose existing user
  const handleChooseSuChange = (e) => {
    const { name, value } = e.target;

    setSuFmlyFormValues({ ...suFmlyFormValues, [name]: value });
    setIsChooseInviteDisabled(false);
  };

  // submit existing user select
  const handleChooseSuSubmit = (e) => {
    e.preventDefault();
    setSuFmlyFormErrors(validateChooseSu(suFmlyFormValues));
  };

  // validate existing user
  const validateChooseSu = (values) => {
    const errors = {};
    if (!values.su_id) {
      errors.msg = "Please enter family name";
      errors.su_id = "Yes";
    }
    if (Object.keys(errors).length === 0) {
      setChooseSuFormLoading(true);
      // api call
      UserService.sendFamilyInviteToAoAsSu(splitUrl[4], values.su_id, {}).then(
        (res) => {
          if (res.status === 400) {
            setChooseSuFormLoading(false);
            setShowChooseSu(false);
            setShowToast(true);
            setToastMessage(res.data.message);
            setSuccessToast(false);
            setApiRefresh(true);
          } else {
            setChooseSuFormLoading(false);
            setShowChooseSu(false);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("User have been successfully invited");
            setApiRefresh(true);
          }
        },
        (error) => {
          setChooseSuFormLoading(false);
        }
      );
    }
  };

  // chose existing su
  const handleShowChooseSu = () => {
    setShowChooseExistSU(true);
    setmainUser(false);
  };

  // close exist SU modal
  const handleCloseExisting = () => {
    setShowChooseExistSU(false);
  };

  // submit existing user select
  const handleChooseExistSuSubmit = (e) => {
    e.preventDefault();
    setSuFmlyFormErrors(validateChooseExistSu(suFmlyFormValues));
  };

  // validate existing user
  const validateChooseExistSu = (values) => {
    const errors = {};
    if (!values.su_id) {
      errors.msg = "Please enter family name";
      errors.su_id = "Yes";
    }
    if (Object.keys(errors).length === 0) {
      setChooseSuFormLoading(true);
      // api call
      let data = {
        uuid: splitUrl[4], //family uuuid
        iuid: UserDetails.id, //iniviting user
        suid: suFmlyFormValues.su_id, //su id
      };
      UserService.sendFamilyInviteToSufromfamily(data).then(
        (res) => {
          if (res.status === 400) {
            setChooseSuFormLoading(false);
            setShowChooseExistSU(false);
            setShowToast(true);
            setToastMessage(res.data.message);
            setSuccessToast(false);
            setApiRefresh(true);
          } else {
            setChooseSuFormLoading(false);
            setShowChooseExistSU(false);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("User have been successfully invited");
            setApiRefresh(true);
          }
        },
        (error) => {
          setChooseSuFormLoading(false);
        }
      );
    }
  };

  // Assign myself(AO) as SU
  const assignMyselfAOAsSU = (userId) => {
    UserService.sendFamilyInviteToAoAsSu(splitUrl[4], userId, {}).then(
      (res) => {
        if (res.status === 400) {
          setShowToast(true);
          setToastMessage(res.data.message);
          setSuccessToast(false);
          setApiRefresh(true);
        } else {
          setSuccessToast(true);
          setShowToast(true);
          setToastMessage("User have been successfully invited");
          setApiRefresh(true);
        }
      },
      (error) => {
        setChooseSuFormLoading(false);
      }
    );
  };

  return (
    <>
      <Container fluid style={{ marginBottom: "6rem" }}>
        {loading ? (
          <div
            className="d-flex m-auto"
            style={{ minHeight: "calc(100vh - 17rem)" }}
          >
            <Loading />
          </div>
        ) : (
          <>
            <ToastBlockContainer
              successToast={successToast}
              showToast={showToast}
              setShowToast={setShowToast}
              toastMessage={toastMessage}
            />
            <Row className="justify-content-center gy-0 mb-6rem">
              <Col lg="24" xl="22" xxl="20">
                <h3 className="p-0 mb-3">
                  {usertype === "AO" ||
                  usertype === "AU" ||
                  usertype === "AU + SU" ||
                  usertype === "SU"
                    ? "All Users"
                    : "Manage Users"}
                </h3>
                <section className="bg-white p-4 p-md-5 p-lg-6 shadow rounded-4 mb-4 mb-md-6">
                  <Row>
                    <Col lg="12" className="mb-4 pb-4 custom-line">
                      <h4 className="mb-3">Main Users</h4>

                      <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between mb-5 gap-3">
                        {audata.length === 1 ? (
                          <div className="bold-colour fw-medium">
                            {audata.length}/
                            {
                              familyDetails.Family.Family_Subscription
                                .Account_Subscription_Plan.availableS
                            }{" "}
                            user is occupied
                          </div>
                        ) : (
                          <div className="bold-colour fw-medium">
                            {audata.length}/
                            {
                              familyDetails.Family.Family_Subscription
                                .Account_Subscription_Plan.availableS
                            }{" "}
                            users are occupied
                          </div>
                        )}
                        {familyDt.isAo ? (
                          familyDetails.Family.Family_Subscription
                            .Account_Subscription_Plan.availableS >
                          audata.length ? (
                            <button
                              className="btn btn-primary w-fit"
                              onClick={handleShowInviteAuShow}
                            >
                              Add Main User
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary w-fit"
                              style={{ visibility: "hidden" }}
                            >
                              Add Main User
                            </button>
                          )
                        ) : (
                          <button
                            className="btn btn-primary"
                            style={{ visibility: "hidden" }}
                          >
                            Add Main User
                          </button>
                        )}
                      </div>

                      {audata.length > 0 ? (
                        audata.map((userList, index) => (
                          <span key={index}>
                            <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between gap-2 fs-14 manage-users-card">
                              <div className="d-flex align-items-center gap-2">
                                <img
                                  src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                  alt="Profile Avatar"
                                  style={{
                                    width: "2.5rem",
                                    height: "2.5rem",
                                  }}
                                />
                                <div className="text-break">
                                  <div>
                                    <div className="PU-dsn-txt">
                                      <span>
                                        {userList.User.firstName === "null" ? (
                                          <span></span>
                                        ) : (
                                          userList.User.firstName
                                        )}{" "}
                                        {userList.User.lastName === "null" ? (
                                          <span></span>
                                        ) : (
                                          userList.User.lastName
                                        )}
                                      </span>
                                      <span>
                                        {userList.User.firstName !== "null" ? (
                                          <div className="PU-dsn-txt-1">
                                            {userList.User.email}
                                          </div>
                                        ) : (
                                          <div className="PU-dsn-txt-1">
                                            {userList.User.email}
                                          </div>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex align-items-center gap-2 ms-7 ms-sm-0">
                                {userList.inviteStatus ===
                                  "Acceptance Pending" && (
                                  <div className="text-break fs-12">
                                    {userList.inviteStatus}
                                  </div>
                                )}

                                {usertype === "AU" ||
                                usertype === "AU + SU" ||
                                usertype === "SU" ? (
                                  <div className="d-flex align-items-center gap-2">
                                    <img
                                      src={EditIcon}
                                      alt="EditIcon"
                                      onClick={() => mainUserDtls(userList)}
                                      style={{ cursor: "pointer" }}
                                    />
                                    <img
                                      src={DeleteIcon}
                                      alt="DeleteIcon"
                                      // onClick={() =>
                                      //   mainUserDtls(userList)
                                      // }
                                      style={{
                                        cursor: "pointer",
                                        display: "none",
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div className="d-flex align-items-center">
                                    {userList.inviteStatus === "Onboarded" && (
                                      <Dropdown className="profile-dropdwn">
                                        <Dropdown.Toggle
                                          variant=""
                                          className="m-0 inviteTxt user-dropdown pl-0"
                                          id=""
                                        >
                                          <img
                                            src={EditIcon}
                                            alt="EditIcon"
                                            style={{
                                              cursor: "pointer",
                                              padding: "0.25rem",
                                            }}
                                          />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() =>
                                              mainUserDtls(userList)
                                            }
                                          >
                                            Edit user
                                          </Dropdown.Item>
                                          {userList.inviteStatus !==
                                            "Acceptance Pending" &&
                                          userList.isAo !== true &&
                                          sudata.length <
                                            familyDetails.Family
                                              .Family_Subscription
                                              .Account_Subscription_Plan
                                              .availableS ? (
                                            <Dropdown.Item
                                              onClick={() =>
                                                assignMyselfAOAsSU(userList.id)
                                              }
                                            >
                                              Assign myself as SU
                                            </Dropdown.Item>
                                          ) : (
                                            ""
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    )}
                                    <img
                                      src={DeleteIcon}
                                      alt="DeleteIcon"
                                      onClick={() =>
                                        handleShowRemoveAuUserShow(
                                          userList,
                                          "Remove Main"
                                        )
                                      }
                                      style={{
                                        cursor: "pointer",
                                        padding: "0.25rem",
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </span>
                        ))
                      ) : (
                        <div className="new-Txt-Chng">
                          No Main Users available.
                        </div>
                      )}
                    </Col>

                    <Col lg="12" className="">
                      <h4 className="mb-3">Support Users</h4>

                      <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between mb-5 gap-3">
                        {sudata.length === 1 ? (
                          <div className="bold-colour fw-medium">
                            {sudata.length}/
                            {
                              familyDetails.Family.Family_Subscription
                                .Account_Subscription_Plan.availableS
                            }{" "}
                            user is occupied
                          </div>
                        ) : (
                          <div className="bold-colour fw-medium">
                            {sudata.length}/
                            {
                              familyDetails.Family.Family_Subscription
                                .Account_Subscription_Plan.availableS
                            }{" "}
                            users are occupied
                          </div>
                        )}
                        {usertype === "AO + AU + SU" ||
                        usertype === "AO + AU" ||
                        usertype === "AU + SU" ||
                        usertype === "AU" ? (
                          familyDetails.Family.Family_Subscription
                            .Account_Subscription_Plan.availableS >
                          sudata.length ? (
                            newValue.length > 0 ? (
                              <Accordion className="family-dashbrd">
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      <li
                                        onClick={handleShowChooseSu}
                                        style={{ cursor: "pointer" }}
                                      >
                                        Choose existing Support Users
                                      </li>

                                      <li
                                        onClick={handleShowInviteSuShow}
                                        style={{ cursor: "pointer" }}
                                      >
                                        Invite a new Support User
                                      </li>
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            ) : (
                              <button
                                className="btn btn-primary w-fit"
                                onClick={handleShowInviteSuShow}
                              >
                                Add Support User
                              </button>
                            )
                          ) : (
                            <button
                              className="btn btn-primary w-fit"
                              style={{ visibility: "hidden" }}
                            >
                              Add Support User
                            </button>
                          )
                        ) : (
                          <button
                            className="btn btn-primary w-fit"
                            style={{ visibility: "hidden" }}
                          >
                            Add Support User
                          </button>
                        )}
                      </div>
                      {sudata.length > 0 ? (
                        sudata.map((userList, index) => (
                          <span key={index}>
                            <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between gap-2 fs-14 manage-users-card">
                              <div className="d-flex align-items-center gap-2">
                                <img
                                  src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                  alt="Profile Avatar"
                                  style={{
                                    width: "2.5rem",
                                    height: "2.5rem",
                                  }}
                                />
                                <div className="text-break">
                                  <div>
                                    <div className="PU-dsn-txt">
                                      <span>
                                        {userList.User.firstName === "null" ? (
                                          <span></span>
                                        ) : (
                                          userList.User.firstName
                                        )}{" "}
                                        {userList.User.lastName === "null" ? (
                                          <span></span>
                                        ) : (
                                          userList.User.lastName
                                        )}
                                      </span>
                                      <span>
                                        {userList.User.firstName !== "null" ? (
                                          <div className="PU-dsn-txt-1">
                                            {userList.User.email}
                                          </div>
                                        ) : (
                                          <div className="PU-dsn-txt-1">
                                            {userList.User.email}
                                          </div>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex align-items-center gap-2 ms-7 ms-sm-0">
                                {userList.inviteStatus ===
                                  "Acceptance Pending" && (
                                  <div className="text-break fs-12">
                                    {userList.inviteStatus}
                                  </div>
                                )}
                                <div className="d-flex align-items-center gap-2">
                                  {usertype !== "AO" &&
                                    userList.inviteStatus === "Onboarded" && (
                                      <img
                                        src={EditIcon}
                                        alt="EditIcon"
                                        onClick={() =>
                                          supportUserDtls(userList)
                                        }
                                        style={{
                                          cursor: "pointer",
                                          padding: "0.25rem",
                                        }}
                                      />
                                    )}
                                  {(usertype === "AU" ||
                                    usertype === "AU + SU") &&
                                    checkSU.length > 0 &&
                                    checkSU.map(
                                      (list, index) =>
                                        list.FamilyUserId === userList.id && (
                                          <img
                                            src={DeleteIcon}
                                            alt="DeleteIcon"
                                            onClick={() =>
                                              userList.inviteStatus ===
                                              "Onboarded"
                                                ? handleShowRemoveSuUserShow(
                                                    userList,
                                                    "Remove this user as Support"
                                                  )
                                                : handleShowRemoveSuUserShow(
                                                    userList,
                                                    "Revoke Support"
                                                  )
                                            }
                                            style={{
                                              cursor: "pointer",
                                              padding: "0.25rem",
                                            }}
                                          />
                                        )
                                    )}
                                  {(usertype === "AO + AU" ||
                                    usertype === "AO + AU + SU") &&
                                    userList.inviteStatus === "Onboarded" && (
                                      <Dropdown className="profile-dropdwn">
                                        <Dropdown.Toggle
                                          variant=""
                                          className="m-0 inviteTxt user-dropdown pl-0"
                                          id=""
                                        >
                                          <Image
                                            className=""
                                            src={DeleteIcon}
                                            alt="DeleteIcon"
                                          />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleShowRemoveSuUserShow(
                                                userList,
                                                "Remove Support"
                                              )
                                            }
                                          >
                                            Remove this User
                                          </Dropdown.Item>
                                          {checkSU.map(
                                            (list, index) =>
                                              list.FamilyUserId ===
                                                userList.id && (
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handleShowRemoveSuUserShow(
                                                      userList,
                                                      "Remove this user as Support"
                                                    )
                                                  }
                                                >
                                                  Remove this user as SU
                                                </Dropdown.Item>
                                              )
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    )}
                                  {(usertype === "AO + AU" ||
                                    usertype === "AO + AU + SU") &&
                                    userList.inviteStatus !== "Onboarded" && (
                                      <img
                                        src={DeleteIcon}
                                        alt="DeleteIcon"
                                        onClick={() =>
                                          handleShowRemoveSuUserShow(
                                            userList,
                                            "Revoke Support"
                                          )
                                        }
                                        style={{
                                          cursor: "pointer",
                                          padding: "0.25rem",
                                        }}
                                      />
                                    )}
                                  {(usertype === "AO" ||
                                    usertype === "AO + SU") &&
                                    userList.inviteStatus !== "Onboarded" && (
                                      <img
                                        src={DeleteIcon}
                                        alt="DeleteIcon"
                                        onClick={() =>
                                          handleShowRemoveSuUserShow(
                                            userList,
                                            "Revoke Support"
                                          )
                                        }
                                        style={{
                                          cursor: "pointer",
                                          padding: "0.25rem",
                                        }}
                                      />
                                    )}
                                  {(usertype === "AO" ||
                                    usertype === "AO + SU") &&
                                    userList.inviteStatus === "Onboarded" && (
                                      <img
                                        src={DeleteIcon}
                                        alt="DeleteIcon"
                                        onClick={() =>
                                          handleShowRemoveSuUserShow(
                                            userList,
                                            "Remove Support"
                                          )
                                        }
                                        style={{
                                          cursor: "pointer",
                                          padding: "0.25rem",
                                        }}
                                      />
                                    )}
                                </div>
                              </div>
                            </div>
                          </span>
                        ))
                      ) : (
                        <div className="new-Txt-Chng">
                          No Support Users available.
                        </div>
                      )}
                    </Col>
                  </Row>
                </section>
              </Col>
            </Row>

            {/* Modal  */}
            {/* main user new dsgn */}
            <Modal
              show={mainUser}
              onHide={mainUserClose}
              className=""
              size="lg"
            >
              <Modal.Body className="mx-1 my-1">
                {mainLoading ? (
                  <div className="mb-5">
                    <Loading />
                  </div>
                ) : (
                  <Row>
                    <Col lg="12" className="custom-line pb-4 mb-4">
                      <div className="">
                        <h3 className="mb-3">Main User</h3>
                        <div className="famDash-modal1-box">
                          <div className="">
                            <span>
                              {mainLeft.User &&
                                (mainLeft.User.firstName === "null"
                                  ? mainLeft.User.email
                                  : mainLeft.User.firstName)}
                            </span>
                            <span className="mx-2">
                              {mainLeft.User &&
                                (mainLeft.User.lastName === "null"
                                  ? ""
                                  : mainLeft.User.lastName)}
                            </span>
                          </div>
                          <div className="mb-2">
                            {mainLeft.User && mainLeft.User.email}
                          </div>
                          <div>
                            <span>Joined:</span>{" "}
                            <span>
                              {moment(
                                mainLeft.User && mainLeft.createdAt
                              ).format("DD MMM YYYY")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg="12" className="">
                      <div className="">
                        <h3 className="mb-3">Support User</h3>
                        {mainRight &&
                          mainRight.map((list, index) => (
                            <div className="famDash-modal1-box">
                              <div className="d-flex justify-content-between align-items-center">
                                <div>
                                  <div>
                                    <span>
                                      {list.Family_User &&
                                        (list.Family_User.User.firstName ===
                                        "null"
                                          ? list.Family_User.User.email
                                          : list.Family_User.User.firstName)}
                                    </span>
                                    <span className="mx-2">
                                      {list.Family_User &&
                                      list.Family_User.User.lastName === "null"
                                        ? ""
                                        : list.Family_User.User.lastName}
                                    </span>
                                  </div>
                                  <div className="mb-2">
                                    {list.Family_User.User.email}
                                  </div>
                                </div>

                                <div className="delete-iconmodal">
                                  <Image
                                    className=""
                                    src={DeleteIcon}
                                    alt="DeleteIcon"
                                    onClick={() =>
                                      list.Family_User.inviteStatus ===
                                      "Onboarded"
                                        ? handleShowRemoveSuUserShow1(
                                            list,
                                            "Remove Support"
                                          )
                                        : handleShowRemoveSuUserShow1(
                                            list,
                                            "Revoke Support"
                                          )
                                    }
                                  />
                                </div>
                              </div>
                              <div>
                                <span>Started Supporting from:</span>{" "}
                                <span>
                                  {moment(list.User && list.createdAt).format(
                                    "DD MMM YYYY"
                                  )}
                                </span>
                              </div>
                            </div>
                          ))}
                      </div>

                      <div className="my-5">
                        {(usertype === "AO + AU" ||
                          usertype === "AO + AU + SU") &&
                        mainLeft.User &&
                        UserDetails.email === mainLeft.User.email ? (
                          familyDetails.Family.Family_Subscription
                            .Account_Subscription_Plan.availableS >
                          sudata.length ? (
                            newValue.length > 0 ? (
                              <Accordion className="family-dashbrd d-flex justify-content-center">
                                <Accordion.Item
                                  style={{ width: "14rem" }}
                                  eventKey="0"
                                >
                                  <Accordion.Header>
                                    Add Support User
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <ul>
                                      <li
                                        onClick={handleShowChooseSu}
                                        style={{ cursor: "pointer" }}
                                      >
                                        Choose existing Support Users
                                      </li>

                                      <li
                                        onClick={handleShowInviteSuShow}
                                        style={{ cursor: "pointer" }}
                                      >
                                        Invite a new Support User
                                      </li>
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            ) : (
                              <div
                                className="manageuser-btn"
                                onClick={handleShowInviteSuShow}
                                style={{ cursor: "pointer", margin: "auto" }}
                              >
                                Add Support User
                              </div>
                            )
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
              </Modal.Body>
            </Modal>

            {/* support user new dsgn */}
            <Modal show={supportUser} onHide={supportUserClose} size="lg">
              <Modal.Body className="mx-1 my-1">
                {suppLoading ? (
                  <div className="mb-5">
                    <Loading />
                  </div>
                ) : (
                  <Row>
                    <Col lg="12" className="custom-line pb-4 mb-4">
                      <div className="">
                        <h3 className="mb-3">Support User</h3>
                        <div className="famDash-modal1-box">
                          <div>
                            <span>
                              {suppLeft.User
                                ? suppLeft.User.firstName === "null"
                                  ? suppLeft.User.email
                                  : suppLeft.User.firstName
                                : ""}
                            </span>
                            <span className="mx-2">
                              {suppLeft.User
                                ? suppLeft.User.lastName === "null"
                                  ? ""
                                  : suppLeft.User.lastName
                                : ""}
                            </span>
                          </div>
                          <div className="mb-2">
                            {suppLeft.User && suppLeft.User.email}
                          </div>
                          <div>
                            <span>Joined:</span>{" "}
                            <span>
                              {moment(
                                suppLeft.User && suppLeft.createdAt
                              ).format("DD MMM YYYY")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="">
                        <h3 className="mb-3">Users you are supporting</h3>
                        {usertype === "AO + SU" || usertype === "AO + AU + SU"
                          ? suppRight &&
                            suppRight.map((list, index) => (
                              <div className="famDash-modal1-box" key={index}>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <div>
                                      <span>
                                        {list.supportingUserId &&
                                        list.supportingUserId.User.firstName ===
                                          "null"
                                          ? list.supportingUserId.User.email
                                          : list.supportingUserId.User
                                              .firstName}
                                      </span>
                                      <span className="mx-2">
                                        {list.supportingUserId &&
                                        list.supportingUserId.User.lastName ===
                                          "null"
                                          ? ""
                                          : list.supportingUserId.User.lastName}
                                      </span>
                                    </div>
                                    <div className="mb-2">
                                      {list.supportingUserId.User.email}
                                    </div>
                                  </div>
                                  {usertype !== "AU" &&
                                    usertype !== "SU" &&
                                    suppLeft.User.email ===
                                      UserDetails.email && (
                                      <div className="delete-iconmodal">
                                        <Dropdown className="profile-dropdwn">
                                          <Dropdown.Toggle
                                            variant=""
                                            className="m-0 inviteTxt user-dropdown pl-0"
                                            id=""
                                          >
                                            <Image
                                              className=""
                                              src={DeleteIcon}
                                              alt="DeleteIcon"
                                            />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              onClick={() =>
                                                handleShowRemoveAuUserShow(
                                                  list,
                                                  "Remove Main"
                                                )
                                              }
                                            >
                                              Remove User
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              onClick={() =>
                                                handleShowRemoveAuUserShow(
                                                  list,
                                                  "Stop Supporting this"
                                                )
                                              }
                                            >
                                              Stop Supporting this user
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    )}
                                </div>
                                <div>
                                  <span>Started Supporting from:</span>{" "}
                                  <span>
                                    {moment(
                                      list.supportingUserId &&
                                        list.supportingUserId.createdAt
                                    ).format("DD MMM YYYY")}
                                  </span>
                                </div>
                              </div>
                            ))
                          : suppRight &&
                            suppRight.map((list, index) => (
                              <div className="famDash-modal1-box" key={index}>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    <span>
                                      {list.supportingUserId &&
                                      list.supportingUserId.User.firstName ===
                                        "null"
                                        ? list.supportingUserId.User.email
                                        : list.supportingUserId.User.firstName}
                                    </span>
                                    <span className="mx-2">
                                      {list.supportingUserId &&
                                      list.supportingUserId.User.lastName ===
                                        "null"
                                        ? ""
                                        : list.supportingUserId.User.lastName}
                                    </span>
                                  </div>
                                  {usertype !== "AU" &&
                                    usertype !== "SU" &&
                                    suppLeft.User.email ===
                                      UserDetails.email && (
                                      <div className="delete-iconmodal">
                                        <Image
                                          className=""
                                          src={DeleteIcon}
                                          alt="DeleteIcon"
                                          onClick={() =>
                                            handleShowRemoveSUUser(
                                              list,
                                              "Remove Support"
                                            )
                                          }
                                        />
                                        <Image className="" />
                                      </div>
                                    )}
                                </div>
                                <div>
                                  <span>Started Supporting from:</span>{" "}
                                  <span>
                                    {moment(
                                      list.supportingUserId &&
                                        list.supportingUserId.createdAt
                                    ).format("DD MMM YYYY")}
                                  </span>
                                </div>
                              </div>
                            ))}
                      </div>
                      {(usertype === "AO + SU" ||
                        usertype === "AO + AU + SU") &&
                      suppLeft.User &&
                      suppLeft.User.email === UserDetails.email ? (
                        supportMainUser.length > 0 ? (
                          <>
                            <div
                              className="famDash-modal1"
                              onClick={handleShowChooseSuShow}
                              style={{ cursor: "pointer" }}
                            >
                              Support other Main User
                            </div>
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                )}
              </Modal.Body>
            </Modal>

            {/* invite Main User modal */}
            <Modal
              show={showInviteAu}
              onHide={handleShowInviteAuClose}
              className=""
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <h3>Invite a Main user</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="mb-1 subtext">
                  Enter the email address of the user whom you want to add as an
                  Main Users to your family.
                </p>
                <form className="invite-form" onSubmit={handleSubmit}>
                  <label
                    className={
                      formErrors.firstname
                        ? "invalid-text form-label mt-3 mb-0 bold-colour"
                        : "form-label mt-3 mb-0 bold-colour"
                    }
                  >
                    Firstname
                  </label>
                  <Small
                    type="test"
                    errorClass={formErrors.firstname ? "invalid-input" : ""}
                    placeholder="John"
                    size="medium"
                    name="firstname"
                    value={formValues.firstname}
                    onChange={handleChange}
                    className="form-control"
                  />
                  <label
                    className={
                      formErrors.lastname
                        ? "invalid-text form-label mt-3 mb-0 bold-colour"
                        : "form-label mt-3 mb-0 bold-colour"
                    }
                  >
                    Lastname
                  </label>
                  <Small
                    type="test"
                    errorClass={formErrors.lastname ? "invalid-input" : ""}
                    placeholder="Walker"
                    size="medium"
                    name="lastname"
                    value={formValues.lastname}
                    onChange={handleChange}
                    className="form-control"
                  />

                  <label
                    className={
                      formErrors.lastname
                        ? "invalid-text form-label mt-3 mb-0 bold-colour"
                        : "form-label mt-3 mb-0 bold-colour"
                    }
                  >
                    Email
                  </label>
                  <Small
                    type="email"
                    errorClass={formErrors.email ? "invalid-input" : ""}
                    placeholder="jwalker@gmail.com"
                    size="medium"
                    name="email"
                    value={formValues.email}
                    onChange={handleChange}
                    className="form-control"
                  />

                  <div className="text-center mt-4 ">
                    <Primary
                      children="Send Invite"
                      variant="primary"
                      size="mediumbtn"
                      type="submit"
                      className="btn btn-primary"
                      isLoading={formLoading ? "true" : "false"}
                      isDisabled={formLoading ? "true" : "false"}
                    />
                    {/* {formLoading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )} */}
                  </div>
                </form>
                <div className="text-center mb-3">
                  <div className="fw-bold my-4 bold-colour">OR</div>
                  <Info
                    children="Add myself as a PU"
                    variant="info"
                    size="mediumbtn"
                    onClick={handleInviteMyselfAsAU}
                    className="btn btn-primary"
                  />
                </div>
              </Modal.Body>
            </Modal>

            {/* invite SU modal */}
            <Modal
              show={showInviteSu}
              onHide={handleShowInviteSuClose}
              className="invite_au_modal"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <h3>Invite Support User </h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="mb-1 subtext">
                  Enter the email address of the user whom you want to add as a
                  Support User for you
                </p>
                <form className="invite-form" onSubmit={handleSuInviteSubmit}>
                  <label
                    className={
                      formErrors.firstname
                        ? "invalid-text form-label mt-3 mb-0 bold-colour"
                        : "form-label mt-3 mb-0 bold-colour"
                    }
                  >
                    Firstname
                  </label>
                  <Small
                    type="test"
                    errorClass={formErrors.firstname ? "invalid-input" : ""}
                    placeholder="John"
                    size="medium"
                    name="firstname"
                    value={formValues.firstname}
                    onChange={handleChange}
                    className="form-control"
                  />
                  <label
                    className={
                      formErrors.lastname
                        ? "invalid-text form-label mt-3 mb-0 bold-colour"
                        : "form-label mt-3 mb-0 bold-colour"
                    }
                  >
                    Lastname
                  </label>
                  <Small
                    type="test"
                    errorClass={formErrors.lastname ? "invalid-input" : ""}
                    placeholder="Walker"
                    size="medium"
                    name="lastname"
                    value={formValues.lastname}
                    onChange={handleChange}
                    className="form-control"
                  />

                  <label
                    className={
                      formErrors.lastname
                        ? "invalid-text form-label mt-3 mb-0 bold-colour"
                        : "form-label mt-3 mb-0 bold-colour"
                    }
                  >
                    Email
                  </label>
                  <Small
                    type="email"
                    errorClass={formErrors.email ? "invalid-input" : ""}
                    placeholder="jwalker@gmail.com"
                    size="medium"
                    name="email"
                    value={formValues.email}
                    onChange={handleChange}
                    className="form-control"
                  />

                  <div className="text-center my-5">
                    <Primary
                      children="Send Invite"
                      variant="primary"
                      size="mediumbtn"
                      type="submit"
                      className="btn btn-primary"
                      isLoading={formLoading ? "true" : "false"}
                      isDisabled={formLoading ? "true" : "false"}
                    />
                  </div>
                </form>
              </Modal.Body>
            </Modal>

            {/* Remove main user */}
            <Modal
              show={showRemoveAuUser}
              onHide={handleShowRemoveAuUserClose}
              className="invite_au_modal"
            >
              <Modal.Header closeButton>
                <Modal.Title className="mx-1">
                  <h3 className="mb-0">{removeUserModelTitle} User</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="mx-1">
                <SeatOccupied
                  rightImg=""
                  leftImg=""
                  isEmpty="true"
                  ProfileAvatar={auInviteImage}
                  memberHeading={auInviteFirstName + " " + auInviteLastName}
                  memberSubheading={auInviteEmail}
                />
                {removeUserModelTitle === "Remove Main" ? (
                  <p className="my-3 subtext">
                    You are removing the above user from your Family.
                  </p>
                ) : removeUserModelTitle === "Revoke Main" ? (
                  <p className="my-3 subtext">
                    You are revoking the above invite user from your Family.
                  </p>
                ) : (
                  <p className="my-3 subtext">
                    You are stop supporting the above user from your Family.
                  </p>
                )}
                {removeUserModelTitle === "Remove Main" ? (
                  <p className="mb-4 subtext">
                    <strong>
                      Once the user is removed, they will not be able to access
                      your details in this family.
                    </strong>
                  </p>
                ) : removeUserModelTitle === "Revoke Main" ? (
                  <p className="mb-4 subtext">
                    <strong>
                      Once you revoke the invite, User will not going to be
                      Onboarded in to the family.
                    </strong>
                  </p>
                ) : (
                  <p className="mb-4 subtext">
                    <strong>
                      Once you stop supporting, you will not be able to access
                      the user's details in this family.
                    </strong>
                  </p>
                )}
                <p>Do you want to continue?</p>
                <div className="d-flex align-items-center gap-2">
                  <span>
                    <Info
                      children="Cancel"
                      variant="info"
                      size="mediumbtn"
                      type=""
                      className="btn btn-white"
                      onClick={handleShowRemoveAuUserClose}
                    />
                  </span>
                  <span className="ml-4">
                    <Primary
                      children="Yes"
                      variant="primary"
                      size="mediumbtn"
                      type=""
                      className="btn btn-primary"
                      onClick={() =>
                        handleCancelAuInvite(auInviteId, removeUserModelTitle)
                      }
                    />
                  </span>
                </div>
              </Modal.Body>
            </Modal>

            {/* Remove support user */}
            <Modal
              show={showRemoveSuUser}
              onHide={handleShowRemoveSuUserClose}
              className="invite_au_modal"
            >
              <Modal.Header closeButton>
                <Modal.Title className="mx-1">
                  <h3 className="mb-0">{removeUserModelTitleSu} User</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="mx-1">
                <SeatOccupied
                  rightImg=""
                  leftImg=""
                  isEmpty="true"
                  ProfileAvatar={suInviteImage}
                  memberHeading={suInviteFirstName + " " + suInviteLastName}
                  memberSubheading={suInviteEmail}
                />
                {removeUserModelTitleSu === "Remove Support" ? (
                  <p className="my-3 subtext">
                    You are removing the above user from your Family.
                  </p>
                ) : removeUserModelTitleSu === "Revoke Support" ? (
                  <p className="my-3 subtext">
                    You are revoking the above invite user from your Family.
                  </p>
                ) : (
                  <p className="my-3 subtext">
                    You are removing the above user as support user from the
                    family.
                  </p>
                )}
                {removeUserModelTitleSu === "Remove Support" ? (
                  <p className="mb-4 subtext">
                    <strong>
                      Once the user is removed, they will not be able to access
                      your details in this family.
                    </strong>
                  </p>
                ) : removeUserModelTitleSu === "Revoke Support" ? (
                  <p className="mb-4 subtext">
                    <strong>
                      Once you revoke the invite, User will not going to be
                      Onboarded in to the family.
                    </strong>
                  </p>
                ) : (
                  <p className="mb-4 subtext">
                    <strong>
                      Once you remove the user as SU,User will not be able to
                      access the user's details in this family.
                    </strong>
                  </p>
                )}
                <p>Do you want to continue?</p>
                <div className="d-flex align-items-center gap-2">
                  <span>
                    <Info
                      children="Cancel"
                      variant="info"
                      size="mediumbtn"
                      type=""
                      className="btn btn-white"
                      onClick={handleShowRemoveSuUserClose}
                    />
                  </span>
                  <span className="ml-4">
                    <Primary
                      children="Yes"
                      variant="primary"
                      size="mediumbtn"
                      type=""
                      className="btn btn-primary"
                      onClick={() =>
                        handleCancelSuInvite(suInviteId, removeUserModelTitleSu)
                      }
                    />
                  </span>
                </div>
              </Modal.Body>
            </Modal>

            {/* choose main user */}
            <Modal
              show={showChooseSu}
              onHide={handleShowChooseSuClose}
              className="invite_au_modal"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <h3>Support other Main User list</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="mb-1 subtext">
                  Select Main user from the list whom you want to have
                  permission to access your details
                </p>
                <form className="invite-form" onSubmit={handleChooseSuSubmit}>
                  {supportMainUser.length !== 0 ? (
                    supportMainUser.map((suList, index) => (
                      <AddSupportUserBasic
                        userName={
                          suList.User.firstName + " " + suList.User.lastName
                        }
                        userEmail={suList.User.email}
                        variant="basic"
                        key={index}
                        value={suList.id}
                        onChange={handleChooseSuChange}
                      />
                    ))
                  ) : (
                    <div className="text-center mt-4 mb-4">
                      {" "}
                      <p>No data found</p>{" "}
                    </div>
                  )}

                  <div className="text-center mt-4 mb-4">
                    <Primary
                      children="Invite User"
                      variant="primary"
                      size="mediumbtn"
                      type="submit"
                      buttonWidth="150px"
                      disabled={isChooseInviteDisabled ? "disabled" : ""}
                      isLoading={chooseSuFormLoading ? "true" : "false"}
                      isDisabled={chooseSuFormLoading ? "true" : "false"}
                    />
                  </div>
                </form>
              </Modal.Body>
            </Modal>

            {/* choose existing support user */}
            <Modal
              show={showChooseexistSU}
              onHide={handleCloseExisting}
              className="invite_au_modal"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <h3>Add Support Users</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="mb-1 subtext">
                  Select all the support users whom you want to have permission
                  to access your details
                </p>
                <p className="my-3">Support Users in your Family</p>
                <form
                  className="invite-form"
                  onSubmit={handleChooseExistSuSubmit}
                >
                  {newValue.length !== 0 ? (
                    newValue.map((suList, index) => (
                      <AddSupportUserBasic
                        userName={
                          suList.User.firstName + " " + suList.User.lastName
                        }
                        userEmail={suList.User.email}
                        variant="basic"
                        key={index}
                        value={suList.User.id}
                        onChange={handleChooseSuChange}
                      />
                    ))
                  ) : (
                    <div className="text-center mt-4 mb-4">
                      {" "}
                      <p>No data found</p>{" "}
                    </div>
                  )}

                  <div className="text-center mt-4 mb-4">
                    <Primary
                      children="Send Invite"
                      variant="primary"
                      size="mediumbtn"
                      type="submit"
                      buttonWidth="150px"
                      disabled={isChooseInviteDisabled ? "disabled" : ""}
                      isLoading={chooseSuFormLoading ? "true" : "false"}
                      isDisabled={chooseSuFormLoading ? "true" : "false"}
                    />
                    {/* {chooseSuFormLoading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )} */}
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </>
        )}
      </Container>
    </>
  );
};

export default ManageUsers;
