import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import InputGroup from "react-bootstrap/InputGroup";
import moment from "moment";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import Loading from "../../common/Loading";
import imageX from "../../assets/images/x.svg";

const WarrantyAdd = (props) => {
  let history = useHistory();
  const myRef = useRef(null);
  const [familyDetails, setFamilyDetails] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [browserErrMsg, setBrowserErrMsg] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const [userCreateUpdate, setUserCreateUpdate] = useState();
  const [userCreateUpdateFor, setUserCreateUpdateFor] = useState();
  const [warrantiesUrl, setWarrantiesUrl] = useState("");
  const [successToast, setSuccessToast] = useState(false);
  const [backText, setBackText] = useState("Back to my warranties");
  const [fileLoader, setFileLoader] = useState(false);
  const executeScroll = () => myRef.current.scrollIntoView();
  const [addUpdate, setaddUpdate] = useState(false);
  const [Loadingdata, setLoadingdata] = useState(true);
  const [otherModal, setOtherModal] = useState(false);

  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const initialValues = {
    product_type: "",
    supplier: "",
    additional_details: "",
    price: "",
    date: "",
    warranty_tenure: "",
    item_code: "",
    serial_number: "",
    invoice_doc: "",
    uploaded_doc: "",
  };

  const [formValues, setFormValues] = useState(initialValues);

  // select error style
  const customStylesError = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      backgroundColor: "#ecf6ff 0% 0% no-repeat padding-box",
      fontSize: "16px",
      borderRadius: " 0.75rem",
      borderStyle: "none",
      cursor: "pointer",
      padding: "2px 0",
      border: "0.0625rem solid red",
      marginBottom: "4px",
      color: "#475569",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "0.75rem",
      backgroundColor: "#fff",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      zIndex: 10,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "4px 0",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "0.5rem",
      fontSize: "16px",
      width: "100%",
      cursor: "pointer",
      color: "#475569",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#475569",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#9ca3af",
      fontSize: "16px",
      fontStyle: "italic",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#007bff" : "#9ca3af",
      "&:hover": {
        color: "#007bff",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "#dfe8f1",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: "#9ca3af",
      "&:hover": {
        color: "#007bff",
      },
    }),
  };

  // select style
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      backgroundColor: "#ecf6ff 0% 0% no-repeat padding-box",
      fontSize: "16px",
      borderRadius: " 0.75rem",
      borderStyle: "none",
      cursor: "pointer",
      padding: "2px 0",
      border: "0.0625rem solid #aabccf",
      marginBottom: "4px",
      color: "#475569",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "0.75rem",
      backgroundColor: "#fff",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      zIndex: 10,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "4px 0",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "0.5rem",
      fontSize: "16px",
      width: "100%",
      cursor: "pointer",
      color: "#475569",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#475569",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#9ca3af",
      fontSize: "16px",
      fontStyle: "italic",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#007bff" : "#9ca3af",
      "&:hover": {
        color: "#007bff",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "#dfe8f1",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: "#9ca3af",
      "&:hover": {
        color: "#007bff",
      },
    }),
  };

  const initialSupplierOption = [{ value: "", label: "Select a supplier" }];
  const [supplierOption, setSupplierOption] = useState(initialSupplierOption);
  const initialWarrantyOption = [
    { value: "6M", label: "6 months" },
    { value: "12M", label: "12 months" },
    { value: "24M", label: "24 months" },
    { value: "36M", label: "36 months" },
    { value: "48M", label: "48 months" },
  ];

  const [warrantyOption, setWarrantyOption] = useState(initialWarrantyOption);
  const [selectedSupplier, setSelectedSupplier] = useState();
  const [selectedTenure, setSelectedTenure] = useState();
  let { uuid, userUUID } = useParams();

  const handleChange = (e, action) => {
    let targetData = {};

    // Handle action-specific updates
    if (action) {
      targetData = {
        name: action.name,
        value: e.value,
      };

      switch (action.name) {
        case "supplier":
          if (e.label === "Others") {
            setOtherModal(true); // Open the modal
          }
          setSelectedSupplier(e);
          setFormErrors({ supplier: "" });
          break;

        case "warranty_tenure":
          setSelectedTenure(e);
          setFormErrors({ warranty_tenure: "" });
          break;

        default:
          break;
      }
    }
    // Handle price formatting
    else if (e.target.name === "price") {
      const value = e.target.value.includes(".")
        ? e.target.value.substring(0, e.target.value.indexOf(".")) +
          e.target.value.substring(
            e.target.value.indexOf("."),
            e.target.value.indexOf(".") + 3
          )
        : e.target.value;

      targetData = {
        name: e.target.name,
        value,
      };

      setFormErrors({ price: "" });
    }
    // General case for other inputs
    else {
      targetData = {
        name: e.target.name,
        value: e.target.value,
      };

      if (e.target.name === "date") {
        setFormErrors({ date: "" });
      }
    }

    // Update form values
    const { name, value } = targetData;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));

    // Handle file upload
    if (name === "uploaded_doc") {
      setFileLoader(true);
      setaddUpdate(true);
      setBrowserErrMsg("");

      const documentFile = e.target.files[0];
      if (!documentFile) {
        setFileLoader(false);
        return;
      }

      const reader = new FileReader();
      reader.onload = function () {
        const MAX_FILE_SIZE_MB = 2;
        const allowedTypes = [
          "image/png",
          "image/jpg",
          "image/jpeg",
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
          "application/msword", // .doc
        ];

        if (!allowedTypes.includes(documentFile.type)) {
          setBrowserErrMsg("OOPS! The file format doesn't support");
          setFileLoader(false);
          return;
        }

        const fileSizeMB = documentFile.size / 1024 / 1024;
        if (fileSizeMB > MAX_FILE_SIZE_MB) {
          setBrowserErrMsg("OOPS! File size is above 2MB.");
          setFileLoader(false);
          return;
        }

        uploadFile(documentFile);
      };

      reader.onerror = function () {
        setBrowserErrMsg("Error reading file");
        setFileLoader(false);
      };

      reader.readAsDataURL(documentFile);
    }
  };

  // State to manage uploaded files
  const [uploadedDocArray, setUploadedDocArray] = useState([]);

  // File upload logic
  const uploadFile = (data) => {
    setBrowserErrMsg("");
    const formData = new FormData();
    formData.append("file", data, data.name.replace(/ /g, ""));

    UserService.uploadUserDocuments(formData)
      .then((response) => {
        setUploadedDocArray((prevArray) => [...prevArray, response.link[0]]); // Push to the array
        setaddUpdate(false);
        setFileLoader(false);
      })
      .catch(() => {
        setFileLoader(false);
        setBrowserErrMsg("Something went wrong");
        setaddUpdate(false);
      });
  };

  // Function to delete a file
  const deleteFile = (fileUrl) => {
    setUploadedDocArray((prevFiles) =>
      prevFiles.filter((file) => file !== fileUrl)
    );
  };

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    setLoading(true);
  };

  // validation
  const validate = (values) => {
    const errors = {};
    setLoading(false);
    if (!values.warranty_tenure) {
      errors.msg = "Please enter the warranty tenure";
      errors.warranty_tenure = "Yes";
    }
    if (!values.price || values.price === 0 || values.price < 0) {
      errors.msg = "Please enter the amount";
      errors.price = "Yes";
    }
    if (!values.product_type) {
      errors.msg = "Please enter the product type";
      errors.product_type = "Yes";
    }
    if (!values.supplier) {
      errors.msg = "Please enter the supplier";
      errors.supplier = "Yes";
    }
    if (!values.date) {
      errors.msg = "Please enter the start date";
      errors.date = "Yes";
    }
    return errors;
  };

  const getFamilyDetails = (userId) => {
    // get family details
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);
        setUserCreateUpdateFor(response.FamilyData.id);
        // SU Family details
        UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
          (response) => {
            setUserCreateUpdate(response.FamilyData.id);
          },
          (error) => {}
        );
      },
      (error) => {}
    );
  };

  const getAUFamilyDetails = (userId) => {
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);
        setUserCreateUpdate(response.FamilyData.UserId);
        setUserCreateUpdateFor(response.FamilyData.id);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    if (userUUID) {
      UserService.userProfile(userUUID).then((response) => {
        getFamilyDetails(response.userData.id);
        setWarrantiesUrl("/user_warranties/" + uuid + "/" + userUUID);
        setBackText(
          response.userData.firstName === "null"
            ? response.userData.email
            : response.userData.firstName + " " + response.userData.lastName ===
              "null"
            ? ""
            : response.userData.firstName
        );
      });
    } else {
      getAUFamilyDetails(UserDetails.id);
      setWarrantiesUrl("/warranties/" + uuid);
    }

    // get supplier data
    UserService.getSupplier({
      moduleName: "Warranties",
    }).then((resp) => {
      setSupplierOption(resp.data.data);
      setTimeout(() => {
        setLoadingdata(false);
      }, 250);
    });
  }, []);

  useEffect(() => {
    // add subscription
    if (
      Object.keys(formErrors).length === 0 &&
      formValues.price > 0 &&
      isSubmit
    ) {
      setLoading(true);

      // get cat from sup
      UserService.getCatfromSup({
        supplierId: formValues.supplier,
      }).then((resp) => {
        var tenureMonth = "";
        if (formValues.warranty_tenure === "6M") {
          tenureMonth = "6";
        } else if (formValues.warranty_tenure === "12M") {
          tenureMonth = "12";
        } else if (formValues.warranty_tenure === "24M") {
          tenureMonth = "24";
        } else if (formValues.warranty_tenure === "36M") {
          tenureMonth = "36";
        } else if (formValues.warranty_tenure === "48M") {
          tenureMonth = "48";
        }

        // Convert to string
        // Convert array to a single string (comma-separated)
        const urlsAsString =
          uploadedDocArray.length > 0 ? JSON.stringify(uploadedDocArray) : "";
        let data = {
          CategoryId: resp.data.data[0].CategoryId,
          SubCategoryId: resp.data.data[0].sub_category_id,
          productName: formValues.product_type,
          SupplierId: formValues.supplier,
          additionalDetails: formValues.additional_details,
          otherSupplierName: formValues.otherSupplierName,
          price: formValues.price,
          purchaseDate: formValues.date,
          warrantyTenure: formValues.warranty_tenure,
          itemCode: formValues.item_code,
          serialNumber: formValues.serial_number,
          invoiceDocument: urlsAsString,
          reminder: isSwitchOn ? 1 : 0,
          FamilyId: familyDetails.FamilyData.FamilyId,
          FamilyUserId: familyDetails.FamilyData.id,
          expiryDate: addMonth(formValues.date, tenureMonth),
          updatedBy: userCreateUpdate,
          createdUpdatedFor: userCreateUpdateFor,
        };
        const date = new Date();
        if (moment(date).format("YYYY-MM-DD") >= data.expiryDate) {
          setFormErrors({
            warranty_tenure: "warranty_tenure",
            newErr: "newErr",
          });
        } else {
          UserService.createWarranty(data).then(
            (response) => {
              if (response.status === 200 || response.status === 201) {
                setLoading(false);
                setSuccessToast(true);
                setShowToast(true);
                executeScroll();
                window.scrollTo(0, 0);
                setToastMessage("Warranty created successfully");
                setTimeout(() => {
                  history.push(warrantiesUrl);
                  setFormValues(initialValues);
                }, 2000);
              } else {
                setLoading(false);
                setSuccessToast(false);
                setShowToast(true);
                window.scrollTo(0, 0);
                setToastMessage("Error !");
              }
            },
            (error) => {}
          );

          setSupplierOption(initialSupplierOption);
          setWarrantyOption(initialWarrantyOption);
          setIsSwitchOn(true);
        }
      });
    } else {
      setLoading(false);
    }
  }, [formErrors]);

  // d - is a moment() call
  const addMonth = (d, n) => {
    let fm = moment(d).add(n, "M");
    return fm.format("YYYY-MM-DD");
  };

  // Reset dropdown when modal closes
  const handleCloseModal = () => {
    setOtherModal(false); // Close the modal
    setSelectedSupplier(initialSupplierOption); // Reset the selected supplier
    setFormValues((prevValues) => ({
      ...prevValues,
      supplier: "",
      otherSupplierName: "",
    }));
    setOtherSupErr("");
  };

  const [otherSupErr, setOtherSupErr] = useState("");
  const [otherSupplier, setOtherSupplier] = useState("");

  const submitModal = () => {
    // Check if the input field is empty
    if (!formValues.otherSupplierName) {
      setOtherSupErr("otherSupErr");
      return; // Do not proceed if the input field is empty
    }
    setOtherSupErr("");
    setOtherSupplier(formValues.otherSupplierName);
    setSelectedSupplier(initialSupplierOption); // Reset the selected supplier
    setTimeout(() => {
      setOtherModal(false); // Close the modal
    }, 100);
  };

  return (
    <Container ref={myRef}>
      <ToastBlockContainer
        successToast={successToast}
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      {Loadingdata ? (
        <>
          <div className="d-flex" style={{ minHeight: "calc(100vh - 17rem)" }}>
            <Loading />
          </div>
        </>
      ) : (
        <Row className="justify-content-center gy-0 mb-6rem">
          <Col xl="21" xxl="18">
            <h1 className="lh-base mb-4">Add a new warranty</h1>
            <section className="bg-white p-4 p-md-6 p-lg-8 shadow rounded-4">
              <Form onSubmit={handleSubmit} encType="multipart/form-data">
                <section>
                  <h2 className="mb-1 mb-md-2">Product</h2>
                  <p className="mb-4">
                    Add all your warranties and service plans here – anything
                    covered by a guarantee​
                  </p>
                  <Row className="gy-0 mb-4">
                    <Col xs="18" sm="14" md="12" lg="10">
                      <Form.Label
                        className={
                          formErrors.product_type ? "invalid-text" : " "
                        }
                      >
                        Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={255}
                        placeholder="e.g. My new iPhone"
                        name="product_type"
                        className={formErrors.product_type ? "is-invalid" : ""}
                        value={formValues.product_type}
                        onChange={handleChange}
                      />
                      <Form.Text
                        className={
                          formErrors.product_type ? "invalid-text" : " "
                        }
                      >
                        Please enter the product name
                      </Form.Text>
                    </Col>
                  </Row>

                  <Row className="gy-0 mb-4">
                    <Col xs="18" sm="14" md="12" lg="10">
                      <Form.Label
                        className={formErrors.supplier ? "invalid-text" : " "}
                      >
                        Supplier
                      </Form.Label>
                      {otherSupplier ? (
                        <div className="form-control d-flex align-items-center gap-3 justify-content-between">
                          {otherSupplier}{" "}
                          <button
                            className="rounded-circle bg-white border-1 p-1 border-black d-flex align-items-center justify-content-center"
                            type="button"
                            onClick={() => {
                              setOtherSupplier("");
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                otherSupplierName: "",
                              }));
                              setSelectedSupplier(initialSupplierOption); // Reset the selected supplier
                            }}
                          >
                            <img
                              src={imageX}
                              alt=""
                              style={{ width: "16px", height: "16px" }}
                            />
                          </button>
                        </div>
                      ) : (
                        <Select
                          styles={
                            formErrors.supplier
                              ? customStylesError
                              : customStyles
                          }
                          options={supplierOption}
                          defaultValue={{
                            label: "Select a supplier",
                            value: "",
                          }}
                          value={selectedSupplier}
                          onChange={handleChange}
                          name="supplier"
                        />
                      )}
                      <Form.Text
                        className={formErrors.supplier ? "invalid-text" : " "}
                      >
                        Please select your supplier
                      </Form.Text>
                    </Col>
                  </Row>
                  <Row className="gy-4 gx-md-4 gy-md-0 mb-4">
                    <Col xs="18" sm="14" md="12" lg="10">
                      <Form.Label>
                        Item code
                        <span className="small text-muted fw-normal ms-1">
                          Optional
                        </span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        maxlength="30" // Item Code Usual Max Length: 20–30 characters
                        placeholder="e.g. AB1234-UK"
                        name="item_code"
                        value={formValues.item_code}
                        onChange={handleChange}
                      />
                      <Form.Text>Please enter the item code</Form.Text>
                    </Col>
                    <Col xs="18" sm="14" md="12" lg="10">
                      <Form.Label>
                        Serial number
                        <span className="small text-muted fw-normal ms-1">
                          Optional
                        </span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        maxlength="50" //Serial number Usual Max Length: 30-50 characters
                        placeholder="e.g. 20230522 98765"
                        name="serial_number"
                        value={formValues.serial_number}
                        onChange={handleChange}
                      />
                      <Form.Text>Please enter the serial number</Form.Text>
                    </Col>
                  </Row>
                  <Row className="gy-0">
                    <Col xs="24">
                      <Form.Label>
                        Comments
                        <span className="small text-muted fw-normal ms-1">
                          Optional
                        </span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        cols={5}
                        name="additional_details"
                        placeholder="Add any extra details or special notes here​"
                        value={formValues.additional_details}
                        onChange={handleChange}
                        maxLength={255}
                      />
                      <Form.Text>
                        Provide any extra information or specific notes about
                        your warranty.
                      </Form.Text>
                    </Col>
                  </Row>
                </section>
                <hr className="my-6 my-md-7" />
                <section>
                  <h3 className="mb-1 mb-md-2">Warranty</h3>
                  <p className="mb-4">
                    Add the warranty or service plan's price, purchase date, and
                    term.​
                  </p>
                  <Row className="gy-0 mb-4">
                    <Col xs="18" sm="14" md="12" lg="8">
                      <Form.Label
                        className={formErrors.price ? "text-danger" : " "}
                      >
                        Price
                      </Form.Label>
                      <InputGroup
                        className={formErrors.price ? "is-invalid" : ""}
                      >
                        <InputGroup.Text>
                          <i className="fa-solid fa-pound-sign fa-sm"></i>
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          className={
                            formErrors.price
                              ? "is-invalid invalid-border-right"
                              : ""
                          }
                          onWheel={() => document.activeElement.blur()}
                          name="price"
                          value={formValues.price < 0 ? "" : formValues.price}
                          onBlur={() => {
                            if (formValues.price && formValues.price > 0) {
                              const formattedValue = parseFloat(
                                formValues.price
                              ).toFixed(2); // Format to 2 decimal places
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                price: formattedValue, // Update the price with the formatted value
                              }));
                            }
                          }}
                          onChange={handleChange}
                        />
                      </InputGroup>
                      <Form.Text
                        className={formErrors.price ? "text-danger" : " "}
                      >
                        Please enter the price
                      </Form.Text>
                    </Col>
                  </Row>
                  <Row className="gy-0 mb-4">
                    <Col xs="18" sm="14" md="12" lg="8">
                      <Form.Label
                        className={formErrors.date ? "text-danger" : " "}
                      >
                        Purchase date
                      </Form.Label>
                      <InputGroup
                        className={formErrors.date ? "is-invalid" : " "}
                      >
                        <InputGroup.Text id="basic-addon1">
                          <i className="fa-solid fa-calendar fa-sm"></i>
                        </InputGroup.Text>
                        <Form.Control
                          className={
                            formErrors.date
                              ? "is-invalid invalid-border-right"
                              : " "
                          }
                          type="date"
                          placeholder="e.g. 20/10/2022"
                          name="date"
                          value={formValues.date}
                          onChange={handleChange}
                        />
                      </InputGroup>
                      <Form.Text
                        className={formErrors.date ? "text-danger" : " "}
                      >
                        Please enter the purchase date
                      </Form.Text>
                    </Col>
                  </Row>
                  <Row className="gy-0 mb-4">
                    <Col xs="18" sm="14" md="12" lg="8">
                      <Form.Label
                        className={
                          formErrors.warranty_tenure ? "text-danger" : " "
                        }
                      >
                        Warranty term
                      </Form.Label>

                      <Select
                        styles={
                          formErrors.warranty_tenure
                            ? customStylesError
                            : customStyles
                        }
                        options={warrantyOption}
                        defaultValue={{
                          label: "Select a warranty term",
                          value: "",
                        }}
                        value={selectedTenure}
                        onChange={handleChange}
                        name="warranty_tenure"
                      />
                      <Form.Text
                        className={
                          formErrors.warranty_tenure ? "text-danger" : " "
                        }
                      >
                        {formErrors.warranty_tenure && !formErrors.newErr
                          ? "Please select the warranty term"
                          : formErrors.warranty_tenure && formErrors.newErr
                          ? "Expiry date must be greater than Today's date"
                          : "Please select the warranty term"}
                      </Form.Text>
                    </Col>
                  </Row>
                  <Row className="gy-0">
                    <Col md="24">
                      <Form.Label className="mb-0">Reminder</Form.Label>
                      <p className="mb-2">
                        Standard reminder settings will be applied to this
                        warranty.
                      </p>
                      <Form.Switch
                        onChange={onSwitchAction}
                        id="custom-switch"
                        label=""
                        checked={isSwitchOn}
                      />
                    </Col>
                  </Row>
                </section>
                <hr className="my-6 my-md-7" />
                <section class="mb-4">
                  <h3 className="mb-1 mb-md-2">
                    Upload attachment
                    <span className="small text-muted fw-normal ff-body ms-2">
                      Optional
                    </span>
                  </h3>
                  <p className="mb-4">
                    Upload any key documents that support your warranties or
                    service plans, such as receipts or guarantee certificates​
                  </p>
                  <Row className="gy-0">
                    <Col xl="21" xxl="24" className="mb-3">
                      <Form.Control
                        className="d-none"
                        type="file"
                        id="upload"
                        name="uploaded_doc"
                        onClick={(e) => {
                          e.target.value = null;
                        }}
                        value={formValues.uploaded_doc}
                        onChange={handleChange}
                        accept="image/png, image/jpg, image/jpeg, application/pdf,  application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"
                      />
                      {fileLoader ? (
                        <div
                          className=" btn-upload mb-0 form-label"
                          style={{ cursor: "auto" }}
                        >
                          <Loading />
                        </div>
                      ) : (
                        <Form.Label
                          className="btn btn-upload mb-0"
                          for="upload"
                        >
                          <i className="fa-solid fa-upload fa-2xl lh-1"></i>
                          <div className="h6 lh-base mt-4 mb-1">
                            Upload an attachment
                          </div>
                          <p className="small fw-normal text-muted mb-0">
                            We accept PNG, JPG, JPEG, PDF & DOC. (Maximum file
                            size 2MB)
                          </p>
                          <p className="mt-3 text-danger">{browserErrMsg}</p>
                        </Form.Label>
                      )}
                    </Col>

                    {/* uploaded files  */}
                    {uploadedDocArray && uploadedDocArray.length > 0 && (
                      <Col xl="21" xxl="24">
                        <div className="fs-18 bold-colour mb-2 fw-medium">
                          Files selected for upload
                        </div>
                        <div>
                          {uploadedDocArray.map((file, index) => (
                            <div
                              key={index}
                              className="file-item p-3 mb-3 d-flex align-items-center justify-content-between"
                              style={{
                                boxShadow:
                                  "rgba(0, 123, 255, 0.35) 0px 5px 15px",
                                borderRadius: "12px",
                              }}
                            >
                              <div>{file.split("/").pop()}</div>
                              <button
                                className="btn btn-danger py-1 px-2"
                                onClick={() => deleteFile(file)} // This function will handle image removal
                              >
                                <i className="fa-solid fa-trash text-white"></i>
                              </button>
                            </div>
                          ))}
                        </div>
                      </Col>
                    )}
                  </Row>
                </section>
                <div className="d-flex justify-content-end">
                  <Button
                    className="d-flex align-items-center"
                    variant="primary"
                    type="submit"
                    isLoading={loading ? "true" : "false"}
                    isDisabled={loading ? "true" : "false"}
                  >
                    <i className="fa-solid fa-sm fa-add lh-1 align-middle me-2"></i>
                    Add warranty
                  </Button>
                </div>
              </Form>
            </section>
          </Col>
        </Row>
      )}

      {/* uploaded document   */}
      <Modal show={otherModal} onHide={() => handleCloseModal()} className="">
        <Modal.Header closeButton>
          <Modal.Title className="mx-1">
            <h3 className="mb-0">Add other Supplier name</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-5">
            <Form.Label className={otherSupErr ? "text-danger" : ""}>
              Supplier name
            </Form.Label>
            <Form.Control
              className={`mb-2 ${
                otherSupErr ? "is-invalid invalid-border-right" : ""
              }`}
              type="text"
              maxlength="100"
              placeholder="e.g. Starbucks"
              name="otherSupplierName"
              value={formValues.otherSupplierName}
              onChange={handleChange}
            />
            <Form.Text className={otherSupErr ? "text-danger mb-4" : "mb-4"}>
              Please enter the name of the supplier if you haven't found their
              name in the dropdown
            </Form.Text>
          </div>
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => submitModal()}
          >
            + Add
          </button>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default WarrantyAdd;
