import React from "react";
import { Image, Card, OverlayTrigger, Tooltip } from "react-bootstrap";

function CategoryCard(props) {
  const { variant, heading, value, cardImage, ...rest } = props;
  return (
    <div {...rest}>
      <Card className={`story-card ${variant}-cards`}>
        <Card.Body className="p-3">
          <div className="d-flex justify-content-center justify-content-xxl-between flex-column flex-xxl-row">
            <span className="default-plan-img">
              <Image
                src={cardImage}
                style={{ width: "3rem", height: "3rem" }}
              />
            </span>
            {value ? <p className="action-right  number-txt ">{value}</p> : ""}
          </div>

          <p
            className="mt-3 mb-0 count-heading overflow-y-auto"
            style={{ minHeight: "4rem" }}
          >
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="button-tooltip" {...props}>
                  {heading}
                </Tooltip>
              }
            >
              <div className="w-min">{heading}</div>
            </OverlayTrigger>
          </p>
        </Card.Body>
      </Card>
    </div>
  );
}

export default CategoryCard;
