import Cookies from "universal-cookie";

export default function authHeader() {
  const cookies = new Cookies();
  const userToken = cookies.get("accessToken");
  if (userToken) {
    return { "x-access-token": userToken };
  } else {
    return {};
  }
}
