import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import moment from "moment";
import Loading from "../../common/Loading";
import ToastBlockContainer from "../../common/ToastBlockContainer";

const Tasks = (props) => {
  let history = useHistory();
  let { uuid, userUUID } = useParams();
  var parentUserUUId = localStorage.getItem("parentUser.uuid");
  const [addTaskUrl, setAddTaskUrl] = useState("");
  const [loadingspinner, setLoadingspinner] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [successToast, setSuccessToast] = useState(false);

  useEffect(() => {
    // get family details
    if (userUUID) {
      setAddTaskUrl("/add_user_todos/" + uuid + "/" + userUUID);
    } else {
      setAddTaskUrl("/add_todos/" + uuid);
    }
  }, []);

  useEffect(() => {
    if (props.allData) {
      setLoadingspinner(false);
    }
  }, [props.allData]);

  const viewDetailsRoute = (ParentId, TaskId) => {
    localStorage.setItem("taskID", TaskId);
    if (userUUID) {
      history.push(`/view_user_todos_details/${uuid}/${TaskId}/${userUUID}`);
    } else {
      history.push(`/view_todos_details/${uuid}/${TaskId}`);
    }
  };

  const validateDate = (date) => {
    // Get today's date
    const today = new Date();

    // Create the target date from the ISO string
    const targetDate = new Date(date);

    // Calculate the time difference in milliseconds
    const timeDifference = targetDate - today;

    // Convert the time difference from milliseconds to days
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    //
    return daysDifference;
  };

  const [showMarkTodo, setShowMarkTodo] = useState(false);
  const [markTodoObj, setMarkTodoObj] = useState({});
  // show the mark to as completed modal
  const showTodo = (aaa) => {
    setMarkTodoObj(aaa);
    setShowMarkTodo(true);
  };

  // hide mark to do as completed modal
  const handleMarkTodoHide = () => {
    setShowMarkTodo(false);
  };

  // update task status to complete
  const updateTaskStatus = () => {
    let data = {
      status: "Completed",
    };
    UserService.updateTaskAsComplete(data, markTodoObj.id).then(
      (response) => {
        setShowMarkTodo(false);
        window.scrollTo(0, 0);
        if (response.status === 400) {
          setSuccessToast(false);
          setShowToast(true);
          setToastMessage(response.data.message);
        } else {
          setShowToast(true);
          setSuccessToast(true);
          setToastMessage("To Do mark as complete successfully");
          setTimeout(() => {
            window.location.reload();
          }, 2500);
        }
      },
      (error) => {}
    );
  };

  return (
    <>
      <div className="position-absolute" style={{ top: "5rem" }}>
        <ToastBlockContainer
          successToast={successToast}
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />
      </div>
      <div className="d-flex mb-3">
        <h2 className="me-auto mb-0">To-do</h2>
        <Link
          className="btn btn-white btn-sm btn-circle flex-shrink-0"
          to={addTaskUrl}
        >
          <i className="fa-solid fa-sm fa-plus text-dark"></i>
        </Link>
        <Link
          className="btn btn-white btn-sm btn-circle flex-shrink-0 ms-2"
          to={
            uuid
              ? !parentUserUUId
                ? `/todos/${uuid}`
                : `/user_todos/${uuid}/${parentUserUUId}`
              : "#"
          }
        >
          <i className="fa-solid fa-sm fa-angle-right text-dark"></i>
        </Link>
      </div>
      {loadingspinner ? (
        <>
          <div className="loadind-page">
            <Loading />
          </div>
        </>
      ) : (
        <>
          {props.allData.toDoData.length === 0 ? (
            <>
              <p className="small mb-0">You currently have no To Dos set up!</p>
            </>
          ) : (
            <>
              {props.allData.toDoData.map((taskcard, index) => (
                <>
                  <hr className={index === 0 ? "mb-3" : "my-3"} />
                  <Row
                    className="align-items-start gx-3 gy-0 gx-md-4"
                    onClick={() =>
                      viewDetailsRoute(taskcard.ParentId, taskcard.id)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <Col xs="auto">
                      <button
                        className="btn btn-white btn-sm btn-circle flex-shrink-0"
                        onClick={(event) => {
                          event.stopPropagation(); // Prevents event from bubbling up
                          showTodo(taskcard);
                        }}
                      ></button>
                    </Col>
                    <Col style={{ marginTop: "2px" }}>
                      <div className="small fw-semibold text-dark">
                        {taskcard.name}
                      </div>
                      <div className="small fw-medium text-truncate">
                        {taskcard.details}
                      </div>
                    </Col>
                    <Col className="text-end align-self-center col">
                      {/* <div className="small fw-semibold text-success">
                            {moment(taskcard.eta).format("MM/DD/YYYY")}
                          </div> */}
                      <div
                        className={`small ${
                          validateDate(taskcard.eta) <= 30
                            ? "text-danger"
                            : validateDate(taskcard.eta) <= 60 &&
                              validateDate(taskcard.eta) > 30
                            ? "text-orange"
                            : validateDate(taskcard.eta) <= 90 &&
                              validateDate(taskcard.eta) > 60
                            ? "text-lightgreen"
                            : "text-success"
                        } fw-semibold text-truncate`}
                      >
                        {moment(taskcard.eta).format("Do MMM, YYYY")}
                      </div>
                    </Col>
                  </Row>
                </>
              ))}
            </>
          )}
        </>
      )}

      {/* // Mark Todo as completed */}
      <Modal show={showMarkTodo} onHide={handleMarkTodoHide} className="">
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="h3 mb-0">Mark Todo as completed</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Mark the Todo to be completed from the family</p>
          <p className="mb-5">Do you want to continue?</p>
          <Button variant="primary" onClick={handleMarkTodoHide}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => updateTaskStatus()}
            className="ms-4"
          >
            Yes
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Tasks;
