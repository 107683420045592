import React, { useState, useEffect } from "react";
import { Container, Alert, Row, Col, Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Large } from "../../stories/Button.stories";
import AuthService from "../../services/auth.service";
import InputGroup from "react-bootstrap/InputGroup";
import UserService from "../../services/user.service";
import ToastBlockContainer from "../../common/ToastBlockContainer";

const SignIn = () => {
  let history = useHistory();
  const initialValues = { email: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inviteExist, setinviteExist] = useState([]);
  // Initialize a boolean state
  const [passwordShown, setPasswordShown] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    setLoading(true);
  };

  useEffect(() => {
    // redirect to dashboard if already logged in
    let items = localStorage.getItem("UserDetails");
    if (items) {
      var stringify = JSON.parse(items);
      if (stringify) {
        //redirect to Login

        history.push("/family_selection");
      }
    }

    if (Object.keys(formErrors).length === 0 && isSubmit) {
      let data = {
        email: formValues.email,
        password: formValues.password,
      };
      // api call
      AuthService.login(data).then(
        (res) => {
          // profile api call
          UserService.userProfile(res.data.uuid).then(
            (response) => {
              var entry = {
                avatar: response.userData.avatar,
                countryCode: response.userData.countryCode,
                contactNumber: response.userData.contactNumber,
                createdAt: response.userData.createdAt,
                email: response.userData.email,
                firstname: response.userData.firstName,
                id: response.userData.id,
                isActive: response.userData.isActive,
                isContactVerified: response.userData.isContactVerified,
                isEmailVerified: response.userData.isEmailVerified,
                lastname: response.userData.lastName,
                updatedAt: response.userData.updatedAt,
                uuid: res.data.uuid,
                displayId: response.userData.displayId,
                //'accessToken': UserDetails.accessToken,
              };

              setSuccessToast(true);
              setShowToast(true);
              setToastMessage(
                "Signin successful. Please wait while we load your dashboard"
              );

              localStorage.setItem("UserDetails", JSON.stringify(entry));

              setTimeout(() => {
                var array = [];
                UserService.getUserFamilyInvite(res.data.uuid).then(
                  (response) => {
                    setinviteExist(response.InviteData);
                    array.push(...response.InviteData);
                  }
                );

                UserService.getUserFamily(res.data.uuid).then((response) => {
                  let activeFam = [];

                  for (let i = 0; i < response.usersFamilyData.length; i++) {
                    if (
                      response.usersFamilyData[i].inviteStatus ===
                        "Onboarded" &&
                      response.usersFamilyData[i].Family.status === "Active"
                    ) {
                      activeFam.push(response.usersFamilyData[i]);
                    }
                  }

                  let acceptencePendingFam = [];
                  for (let i = 0; i < response.usersFamilyData.length; i++) {
                    if (
                      response.usersFamilyData[i].inviteStatus ===
                        "Acceptance Pending" &&
                      response.usersFamilyData[i].status === "Active"
                    ) {
                      acceptencePendingFam.push(response.usersFamilyData[i]);
                    }
                  }
                  console.log(acceptencePendingFam);
                  if (
                    activeFam.length === 0 &&
                    acceptencePendingFam.length === 0
                  ) {
                    history.push({
                      pathname: "/subscription",
                      familyList: "",
                      inviteList: "",
                    });
                  } else if (activeFam.length === 1) {
                    history.push({
                      pathname: "/dashboard/" + activeFam[0].Family.uuid,
                      familyList: "",
                      inviteList: "",
                    });
                  } else if (activeFam.length > 1) {
                    history.push({
                      pathname: "/family_selection",
                      familyList: "",
                      inviteList: "",
                    });
                  } else if (
                    activeFam.length === 0 &&
                    acceptencePendingFam.length > 0
                  ) {
                    history.push({
                      pathname: `/myInvites`,
                      familyList: "",
                      inviteList: "",
                    });
                  }
                });
              }, 2500);
            },
            () => {}
          );
        },
        (error) => {
          const errors = {};

          setSuccessToast(false);
          setShowToast(true);
          setToastMessage("Invalid email or password/Account is not Active");

          if (error.response === undefined) {
            errors.msg = "Connection timed out. Please try again later";
            setFormErrors(errors);
          } else {
            errors.msg = error.response.data.error;
            if (errors.msg) {
              setFormErrors(errors);
            }
          }
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email && !values.password) {
      errors.msg = "Please enter a valid email address and password";
      errors.email = "Yes";
      errors.password = "Yes";
    } else {
      if (!values.email) {
        errors.msg = "Please enter a valid email address";
        errors.email = "Yes";
      } else if (!regex.test(values.email)) {
        errors.msg = "This is not a valid email format !";
        errors.email = "Yes";
      }

      if (!values.password) {
        errors.msg = "Please enter a valid password";
        errors.password = "Yes";
      } else {
        var alphanumerics = /^.{8,40}$/; // /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        if (!values.password.match(alphanumerics)) {
          errors.msg = "Please enter a valid password";
          errors.password = "Yes";
        }
      }
    }
    return errors;
  };

  return (
    <Container>
      <ToastBlockContainer
        successToast={successToast}
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <Row className="justify-content-center gy-0 mb-6rem">
        <Col md="18" lg="14" xl="12" xxl="10">
          <section className="bg-white p-4 p-md-5 p-lg-6 shadow rounded-4 mb-4 mb-md-6">
            <h1 className="mb-2">Sign into Elderly Life Account</h1>
            <p className="mb-4">
              Don’t have an Account yet?{" "}
              <a href="/signup" className="">
                Sign Up
              </a>
            </p>
            <Form className="login-form" onSubmit={handleSubmit}>
              {/* {Object.keys(formErrors).length !== 0 && isSubmit ? (
                <Alert className="mb-4" key="danger" variant="danger">
                  {formErrors.msg}
                </Alert>
              ) : (
                ""
              )} */}

              <Form.Group className="mb-4">
                <Form.Label className={formErrors.email ? "invalid-text" : ""}>
                  Email
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="John@gmail.com"
                  defaultValue="Mark"
                  name="email"
                  value={formValues.email}
                  onChange={handleChange}
                  className={`${formErrors.email ? `invalid-border` : ""}`}
                />
                <Form.Text className={formErrors.email ? "invalid-text" : ""}>
                  Enter your registered email address
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-4">
                <div className="d-flex align-items-end mb-1">
                  <Form.Label
                    className={formErrors.password ? "invalid-text" : ""}
                  >
                    Password
                  </Form.Label>
                  <div className="ms-auto small">
                    <Link
                      to="/forgetPassword"
                      tabindex="-1"
                      className="text-body fw-medium"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                </div>
                <InputGroup
                  className={formErrors.password ? "invalid-border br-12" : ""}
                >
                  <Form.Control
                    type={passwordShown ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    value={formValues.password}
                    onChange={handleChange}
                  />
                  <InputGroup.Text onClick={togglePassword}>
                    <i
                      className={`fa ${
                        passwordShown ? "fa-eye" : "fa-eye-slash"
                      } cursor-pointer`}
                    />
                  </InputGroup.Text>
                </InputGroup>
                <Form.Text
                  className={formErrors.password ? "invalid-text" : ""}
                >
                  Enter your password
                </Form.Text>
              </Form.Group>
              <Large
                className="btn btn-primary w-100"
                children="Sign In"
                size="mediumbtn"
                variant="primary"
                type="submit"
                buttonWidth="50%"
                isLoading={loading ? "true" : "false"}
                isDisabled={loading ? "true" : "false"}
              />
            </Form>
          </section>
        </Col>
      </Row>
    </Container>
  );
};

export default SignIn;
