// common button block
import React from "react";

import arrowdown from "../../assets/images/arrow_down.svg";

function Button(props) {
  const {
    variant,
    size,
    buttonWidth,
    font_Weight,
    children,
    icon,
    isLoading,
    isDisabled,
    ...rest
  } = props;
  return (
    <button
      className={`button ${variant} ${size}`}
      style={{ width: buttonWidth, fontWeight: font_Weight }}
      disabled={isDisabled === "true" ? "disabled" : ""}
      {...rest}
    >
      {children}
      {isLoading === "true" ? (
        <span className="ms-2 spinner-border spinner-border-sm"></span>
      ) : (
        <span className="ms-2 spinner-border spinner-border-sm d-none"></span>
      )}
      {icon === "arrowdown" ? (
        <img src={arrowdown} className="ml-2" alt="arrowdown"></img>
      ) : icon === "arrowup" ? (
        <img
          src={arrowdown}
          style={{ transform: "rotate(180deg)" }}
          className="ml-2"
          alt="arrowdown"
        ></img>
      ) : null}
    </button>
  );
}

export default Button;
