import React, { useState, useEffect } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import UserService from "../../services/user.service";
import { useHistory } from "react-router-dom";
import { SavedDebitCardBasic } from "../../stories/SavedDebitCard.stories";
import { Primary } from "../../stories/Button.stories";
import SuccessCheck from "../../assets/images/successCheck.svg";

const SavedCardCheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const [savedCardNumber, setSavedCardNumber] = useState([]);
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const initialValues = { paymentMethod_id: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [DetailsSave, setDetailsSave] = useState({});
  const [cardNo, setCardNo] = useState([]);
  const [planDet, setplanDet] = useState([]);
  var splitUrl = window.location.href.split("/");
  const [trialCheck, settrialCheck] = useState([]);
  const [trialCheck1, settrialCheck1] = useState([]);
  const [toastSucc, settoastSucc] = useState(false);
  useEffect(() => {
    if (splitUrl[3] !== "subscription") {
      var dateCheck = new Date().getFullYear();
      let st = new Date("01-01-" + dateCheck).getTime();
      // get family details
      UserService.getUserSingleFamilyDetails(splitUrl[4], UserDetails.id).then(
        (response) => {
          // Get Customer stripe details
          UserService.getSubsciptionStripe(
            response.FamilyData.Family.Family_Subscription.stripeSubscriptionId
          ).then((res) => {
            settrialCheck(res);
            settrialCheck1(res.items.data[0]);
          });
        },
        (error) => {}
      );
    }
  }, []);

  useEffect(() => {
    var newData = [];
    for (var i = 0; i < props.paymentList.length; i++) {
      newData.push(props.paymentList[i].card.last4);
    }
    setCardNo(newData);
  }, [props.paymentList]);

  if (splitUrl[3] !== "downgradeSubscription") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      var newArr = [];

      for (var i = 0; i < props.planType.length; i++) {
        if (props.planType[i].isAo === true) {
          newArr.push(props.planType[i]);
        }
      }
      setplanDet(newArr);
    }, [props.planType]);
  }

  useEffect(() => {
    var optionArr = [];
    for (var i = 0; i < props.paymentList.length; i++) {
      optionArr.push({
        cardNo: props.paymentList[i].card.last4,
      });
    }

    optionArr.forEach((row, ind) => (row.id = ind));
    setSavedCardNumber(optionArr);
  }, [props]);

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setIsDisabled(false);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  // 7 days from today
  var date = new Date();
  date.setDate(date.getDate() + 7);
  const unixTimestamp = Math.floor(date.getTime() / 1000);

  //15 minutes from today
  // var date = new Date();
  // date.setDate(date.getDate());
  // const unixTimeVal = Math.floor(date.getTime() / 1000);
  // const unixTimestamp = unixTimeVal + 900;
  //

  const validate = (values) => {
    const errors = {};
    if (!values.paymentMethod_id) {
      errors.msg = "Please enter family name";
      errors.paymentMethod_id = "Yes";
    } else {
    }
    return errors;
  };

  useEffect(() => {
    // handle confirm submit
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setIsDisabled(false);
      setLoading(true);

      // update payment method id as default source on stripe customer
      let updateData = {
        "invoice_settings[default_payment_method]": formValues.paymentMethod_id,
      };
      UserService.updateCustomerStripe(updateData, props.customerId).then(
        (response) => {
          if (splitUrl[3] === "downgradeSubscription") {
            if (props.planId === 1 || props.planId === 4) {
              if (
                props.planDetails.availableS === 2 ||
                props.planDetails.availableS === 5
              ) {
                history.push({
                  pathname: `/downgrade_Subscription/${splitUrl[4]}`,
                  state: {
                    planId: props,
                    stripeCusID: props.SubscriptionId,
                  },
                });
              } else {
                UserService.upgradeSubscription(
                  splitUrl[4],
                  props.planId,
                  props.SubscriptionId
                ).then((response) => {
                  let subsciptionData = {
                    trial_end: "now",
                    proration_behavior: "always_invoice",
                    billing_cycle_anchor: "now",
                    "items[0][plan]": props.priceId,
                    "items[0][id]": trialCheck1.id,
                  };
                  UserService.updateSubsciptionStripe(
                    subsciptionData,
                    trialCheck.id
                  ).then((response) => {
                    if (response.status !== 400 && response.status !== 404) {
                      settoastSucc(true);
                      setTimeout(() => {
                        settoastSucc(false);
                        history.push({
                          pathname: `/dashboard/${splitUrl[4]}`,
                        });
                      }, 2000);
                    }

                    // let data = {
                    //   price: props.priceDetails,
                    // };
                    // UserService.updateSubItemStripe(
                    //   data,
                    //   respo.items.data[0].id
                    // ).then((resp) => {
                    //
                    //
                    //   UserService.updateInvoiceStripe(
                    //     respo.latest_invoice
                    //   ).then((resp) => {
                    //
                    //
                    //     if (
                    //       resp.status !== 400 &&
                    //       resp.status !== 404
                    //     ) {
                    //       history.push({
                    //         pathname: `/dashboard/${splitUrl[4]}`,
                    //       });
                    //     }
                    //   });
                    // });
                  });
                });
              }
            } else if (props.planId === 2 || props.planId === 5) {
              if (props.planDetails.availableS === 5) {
                history.push({
                  pathname: `/downgrade_Subscription/${splitUrl[4]}`,
                  state: {
                    planId: props,
                    stripeCusID: props.SubscriptionId,
                  },
                });
              } else {
                UserService.upgradeSubscription(
                  splitUrl[4],
                  props.planId,
                  props.SubscriptionId
                ).then((response) => {
                  let subsciptionData = {
                    trial_end: "now",
                    proration_behavior: "always_invoice",
                    billing_cycle_anchor: "now",
                    "items[0][plan]": props.priceId,
                    "items[0][id]": trialCheck1.id,
                  };
                  UserService.updateSubsciptionStripe(
                    subsciptionData,
                    trialCheck.id
                  ).then((response) => {
                    if (response.status !== 400 && response.status !== 404) {
                      settoastSucc(true);
                      setTimeout(() => {
                        settoastSucc(false);
                        history.push({
                          pathname: `/dashboard/${splitUrl[4]}`,
                        });
                      }, 2000);
                    }

                    // let data = {
                    //   price: props.priceDetails,
                    // };
                    // UserService.updateSubItemStripe(
                    //   data,
                    //   respo.items.data[0].id
                    // ).then((resp) => {
                    //
                    //
                    //   UserService.updateInvoiceStripe(
                    //     respo.latest_invoice
                    //   ).then((resp) => {
                    //
                    //
                    //     if (
                    //       resp.status !== 400 &&
                    //       resp.status !== 404
                    //     ) {
                    //       history.push({
                    //         pathname: `/dashboard/${splitUrl[4]}`,
                    //       });
                    //     }
                    //   });
                    // });
                  });
                });
              }
            } else {
              UserService.upgradeSubscription(
                splitUrl[4],
                props.planId,
                props.SubscriptionId
              ).then((response) => {
                let subsciptionData = {
                  trial_end: "now",
                  proration_behavior: "always_invoice",
                  billing_cycle_anchor: "now",
                  "items[0][plan]": props.priceId,
                  "items[0][id]": trialCheck1.id,
                };
                UserService.updateSubsciptionStripe(
                  subsciptionData,
                  trialCheck.id
                ).then((response) => {
                  if (response.status !== 400 && response.status !== 404) {
                    settoastSucc(true);
                    setTimeout(() => {
                      settoastSucc(false);
                      history.push({
                        pathname: `/dashboard/${splitUrl[4]}`,
                      });
                    }, 2000);
                  }

                  // let data = {
                  //   price: props.priceDetails,
                  // };
                  // UserService.updateSubItemStripe(
                  //   data,
                  //   respo.items.data[0].id
                  // ).then((resp) => {
                  //
                  //
                  //   UserService.updateInvoiceStripe(
                  //     respo.latest_invoice
                  //   ).then((resp) => {
                  //
                  //
                  //     if (
                  //       resp.status !== 400 &&
                  //       resp.status !== 404
                  //     ) {
                  //       history.push({
                  //         pathname: `/dashboard/${splitUrl[4]}`,
                  //       });
                  //     }
                  //   });
                  // });
                });
              });
            }
          } else {
            // create subscription
            let subsciptionData = {
              customer: props.customerId,
              "items[0][price]": props.priceId,
            };
            if (planDet.length === 0) {
              subsciptionData.trial_end = unixTimestamp;
            }
            UserService.createSubsciptionStripe(subsciptionData).then(
              (response) => {
                if (response.status === "active") {
                  // call family creation api
                  let createFamilyData = {
                    stripePaymentId: response.latest_invoice,
                    stripeSubscriptionId: response.id,
                    stripeCustomerId: response.customer,
                    familyName: props.familyName, //UserDetails.lastname,
                    planId: props.planId, // response.plan.product,
                    aoUser: UserDetails.uuid,
                  };

                  localStorage.setItem(
                    "UserA",
                    JSON.stringify(createFamilyData.planId)
                  );
                  UserService.createFamily(createFamilyData).then((res) => {
                    setDetailsSave(res);
                    localStorage.setItem("UserDA", res.FamilyData.id);
                    window.history.pushState(
                      null,
                      "",
                      history.push({
                        pathname: "/confirmation",
                        familyUUID: res.FamilyData.uuid,
                        fromPaymentSuccsess: true,
                      })
                    );
                    window.onpopstate = function () {
                      window.history.pushState(
                        null,
                        "",
                        history.push({
                          pathname: "/confirmation",
                          familyUUID: res.FamilyData.uuid,
                          fromPaymentSuccsess: true,
                        })
                      );
                    };
                    localStorage.setItem("UserB", res.FamilyData.uuid);
                  });
                }
                if (response.status === "trialing") {
                  // call family creation api
                  let createFamilyData = {
                    stripePaymentId: response.latest_invoice,
                    stripeSubscriptionId: response.id,
                    stripeCustomerId: response.customer,
                    familyName: props.familyName, //UserDetails.lastname,
                    planId: props.planId, // response.plan.product,
                    aoUser: UserDetails.uuid,
                  };

                  localStorage.setItem(
                    "UserA",
                    JSON.stringify(createFamilyData.planId)
                  );
                  UserService.createFamily(createFamilyData).then((res) => {
                    setDetailsSave(res);
                    localStorage.setItem("UserDA", res.FamilyData.id);
                    window.history.pushState(
                      null,
                      "",
                      history.push({
                        pathname: "/confirmation",
                        familyUUID: res.FamilyData.uuid,
                        fromPaymentSuccsess: true,
                      })
                    );
                    window.onpopstate = function () {
                      window.history.pushState(
                        null,
                        "",
                        history.push({
                          pathname: "/confirmation",
                          familyUUID: res.FamilyData.uuid,
                          fromPaymentSuccsess: true,
                        })
                      );
                    };
                    localStorage.setItem("UserB", res.FamilyData.uuid);
                  });
                }
              },
              (error) => {
                setLoading(false);
              }
            );
          }
        },
        (error) => {
          const errors = {};

          setLoading(false);
        }
      );
    }
  }, [formErrors]);

  return (
    <form className="saved-card-payment-form" onSubmit={handleSubmit}>
      <div className="bold-colour fs-16 fw-medium">
        Saved cards <strong>( {props.paymentList.length} )</strong>
      </div>
      {toastSucc && (
        <div className="upgrade-subscription">
          <img
            src={SuccessCheck}
            className=""
            alt=""
            style={{ marginRight: "1rem" }}
          />
          Subscription Upgraded successfully
        </div>
      )}
      <div className="saved-card-element card-scroll-element mt-2">
        {props.paymentList.map((list, index) => (
          // <li key={index}>{list.id}</li>
          <div key={index} className="mb-3">
            <SavedDebitCardBasic
              last4digit={list.card.last4}
              userName={Capitalize(list.billing_details.name)}
              exp_month={list.card.exp_month}
              exp_year={list.card.exp_year}
              brand={list.card.brand}
              variant="basic"
              value={list.id}
              onChange={handleChange}
            />
          </div>
        ))}
      </div>
      <div className="text-center mt-5">
        <Primary
          children="Proceed to Payment"
          variant="primary"
          size="mediumbtn"
          type="submit"
          className="btn btn-primary"
          disabled={isDisabled ? "disabled" : ""}
          isLoading={loading ? "true" : "false"}
          isDisabled={loading ? "true" : "false"}
        />
        {/* {loading && <span className="spinner-border spinner-border-sm"></span>} */}
      </div>
    </form>
  );
};

export default SavedCardCheckoutForm;
