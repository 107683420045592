import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import CheckoutForm from "./CheckoutForm";
import Loading from "../../common/Loading";
import { ChoosePlanCard } from "../../stories/Subscription.stories";

const NewBillingPage = () => {
  const location = useLocation();
  const { customerId, planData, paymentList, accountPlanList } =
    location.state || {};

  const [isLoad, setIsLoad] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoad(false);
    }, 500);
  }, []);

  console.log(customerId, planData, paymentList, accountPlanList);

  return (
    <div>
      {isLoad ? (
        <div
          style={{ minHeight: "calc(100vh - 150px)" }}
          className="d-flex flex-column"
        >
          <Loading />
        </div>
      ) : (
        <>
          <div className="container-fluid px-0">
            {/* Main Container  */}
            <Row className="mx-auto">
              {/* Left Container */}
              <Col lg="12" className="bg-white">
                <div className="my-5 my-lg-0 d-lg-flex align-items-center justify-content-center text-white flex-column text-center cont mx-1666">
                  <div className="fs-36 fw-bold bold-colour mb-3">
                    Add your billing details
                  </div>

                  <div className="d-flex align-items-center justify-content-between fs-14 mb-2 w-100">
                    <p className="fw-semibold bold-colour ">
                      <span class="text-success me-2">&#10004;</span>
                      <span>Cancel any time</span>
                    </p>
                    <p className="fw-semibold bold-colour ">
                      <span class="text-success me-2">&#10004;</span>
                      <span>No hidden costs</span>
                    </p>
                  </div>

                  <CheckoutForm
                    customerId={customerId}
                    priceId={planData.stripePriceId}
                    planId={planData.id}
                    // familyName={familyName}
                    paymentList={paymentList}
                    planType={accountPlanList}
                  />
                </div>
              </Col>

              {/* Right Container */}
              <Col lg="12" className="p-0">
                <div className="d-flex align-items-center justify-content-center cont mx-1666">
                  <div className="w-75 mtb-6rem">
                    <ChoosePlanCard
                      planName={planData.name}
                      planPrice={planData.price}
                      priceSymbol={planData.Country.symbol}
                      planDuration={planData.renewalType}
                      benefits={planData.availableSeats}
                      planImage={planData.image}
                      buttonShow={false}
                    />

                    <div className="text-center">
                      <i
                        className="fas fa-smile"
                        style={{ color: "#d8d838" }}
                      ></i>
                      <div className="text-info fw-bold">
                        We want you happy - cancel any time with no stress
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  );
};

export default NewBillingPage;
