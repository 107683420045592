import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, Card, Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Large } from "../../stories/Button.stories";
import { Secondary } from "../../stories/Button.stories";
import { Info } from "../../stories/Button.stories";
import { Medium } from "../../stories/Input.stories";
import AuthService from "../../services/auth.service";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import ReactCodeInput from "react-verification-code-input";
import Modal from "react-bootstrap/Modal";
import SuccessCheck from "../../assets/images/successCheck.svg";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import UserService from "../../services/user.service";
import "react-phone-input-2/lib/style.css";

const SignUp = () => {
  let history = useHistory();
  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
  };
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [formCodeValues, setFormCodeValues] = useState("");
  const [regUserUUID, setRegUserUUID] = useState("");
  const [formCodeErrors, setFormCodeErrors] = useState({});
  const [isCodeSubmit, setIsCodeSubmit] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [successToast, setSuccessToast] = useState(false);

  // code verification model
  const [showVerificationModel, setShowVerificationModel] = useState(false);
  const handleShowVerificationModelClose = () =>
    setShowVerificationModel(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  // Password toggle handler
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    setLoading(true);
  };

  const handleVerificationChange = (e) => {
    let codeValue = e;
    setFormCodeValues(codeValue);
  };
  const handleVerificationSubmit = (e) => {
    e.preventDefault();
    setFormCodeErrors(validateEmailCode(formCodeValues));
    setIsCodeSubmit(true);
    setLoadingCode(true);
  };

  const validateEmailCode = (values) => {
    setLoadingCode(false);
    const c_errors = {};
    if (values.length !== "6") {
      c_errors.msg = "Please enter valid verification code";
      c_errors.code = "Yes";
      window.scrollTo(0, 0);
      setSuccessToast(false);
      setShowToast(true);
      setToastMessage(c_errors.msg);
    }
    return c_errors;
  };
  useEffect(() => {
    // redirect to dashboard if already logged in
    let items = localStorage.getItem("UserDetails");
    if (items) {
      var stringify = JSON.parse(items);
      if (stringify) {
        //redirect to Login
        history.push("/family_selection");
      }
    }
    // reg. form submit
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      let data = {
        firstName: formValues.firstname,
        lastName: formValues.lastname,
        email: formValues.email,
        password: formValues.password,
      };

      // api call
      AuthService.register(data).then(
        (res) => {
          if (res) {
            if (res.status === 400) {
              const errors = {};
              errors.msg = res.data.error;

              setFormErrors(errors);
              window.scrollTo(0, 0);
              setSuccessToast(false);
              setShowToast(true);
              setToastMessage(errors.msg);
            } else {
              setRegUserUUID(res.user_id);
              setIsSubmit(false);

              //direct login after user successfully registered
              let dataVal = {
                email: formValues.email,
                password: formValues.password,
              };
              AuthService.login(dataVal).then(
                (res) => {
                  // profile api call
                  UserService.userProfile(res.data.uuid).then(
                    (response) => {
                      var entry = {
                        avatar: response.userData.avatar,
                        countryCode: response.userData.countryCode,
                        contactNumber: response.userData.contactNumber,
                        createdAt: response.userData.createdAt,
                        email: response.userData.email,
                        firstname: response.userData.firstName,
                        id: response.userData.id,
                        isActive: response.userData.isActive,
                        isContactVerified: response.userData.isContactVerified,
                        isEmailVerified: response.userData.isEmailVerified,
                        lastname: response.userData.lastName,
                        updatedAt: response.userData.updatedAt,
                        uuid: res.data.uuid,
                        displayId: response.userData.displayId,
                        //'accessToken': UserDetails.accessToken,
                      };

                      localStorage.setItem(
                        "UserDetails",
                        JSON.stringify(entry)
                      );
                      UserService.getUserFamily(res.data.uuid).then(
                        (response) => {
                          // eslint-disable-next-line no-lone-blocks
                          {
                            response.usersFamilyData.length === 0
                              ? history.push({
                                  pathname: "/subscription",
                                  familyList: "",
                                  inviteList: "",
                                })
                              : response.usersFamilyData.length === 1 &&
                                response.usersFamilyData[0].inviteStatus ===
                                  "Onboarded"
                              ? history.push({
                                  pathname:
                                    "/dashboard/" +
                                    response.usersFamilyData[0].Family.uuid,
                                  familyList: "",
                                  inviteList: "",
                                })
                              : response.usersFamilyData[0].inviteStatus ===
                                "Acceptance Pending"
                              ? history.push({
                                  pathname: "/family_selection",
                                  familyList: "",
                                  inviteList: "",
                                })
                              : history.push({
                                  pathname: "/family_selection",
                                  familyList: "",
                                  inviteList: "",
                                });
                          }
                          setLoading(false);
                        }
                      );
                    },
                    () => {}
                  );
                },
                (error) => {
                  const errors = {};
                  if (error.response === undefined) {
                    errors.msg = "Connection timed out. Please try again later";
                    setFormErrors(errors);
                    window.scrollTo(0, 0);
                    setSuccessToast(false);
                    setShowToast(true);
                    setToastMessage(errors.msg);
                  } else {
                    errors.msg = error.response.data.error;

                    window.scrollTo(0, 0);
                    setSuccessToast(false);
                    setShowToast(true);
                    setToastMessage(errors.msg);
                    if (errors.msg) {
                      setFormErrors(errors);
                    }
                  }
                  setLoading(false);
                }
              );
            }
          } else {
          }
        },
        (error) => {
          const errors = {};
          errors.msg = error.response;

          window.scrollTo(0, 0);
          setSuccessToast(false);
          setShowToast(true);
          setToastMessage(errors.msg);
          if (errors.msg) {
            setFormErrors(errors.msg);
          }
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
    }
    // email verification form submit
    if (Object.keys(formCodeErrors).length === 0 && isCodeSubmit) {
      let data = {
        code: formCodeValues,
      };
      // api call
      AuthService.verifyUserEmail(data, regUserUUID).then(
        (res) => {
          if (res.status === 400) {
            setLoadingCode(false);
            setShowVerificationModel(true);
            window.scrollTo(0, 0);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(res.data.error);
          } else {
            setLoadingCode(false);
            setShowVerificationModel(false);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("Email verified successfully");
            setTimeout(() => {
              history.push("/");
            }, 2000);
          }
        },
        (error) => {
          const errors = {};
          errors.msg = error.response;

          window.scrollTo(0, 0);
          setSuccessToast(false);
          setShowToast(true);
          setToastMessage(errors.msg);

          if (errors.msg) {
            setFormErrors(errors);
          }
          setLoadingCode(false);
        }
      );
    } else {
      setLoadingCode(false);
    }
  }, [formErrors, formCodeErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.firstname) {
      errors.msg = "Please enter the required fields";
      errors.firstname = "Yes";
    }
    if (!values.lastname) {
      errors.msg = "Please enter the required fields";
      errors.lastname = "Yes";
    }
    if (!values.email) {
      errors.msg = "Please enter the required fields";
      errors.email = "Yes";
    } else if (!regex.test(values.email)) {
      errors.msg = "This is not a valid email format !";
      errors.email = "Yes";
    }

    if (!values.password) {
      errors.msg = "Please enter the required fields";
      errors.password = "Yes";
    } else {
      var alphanumerics = /^.{8,40}$/;
      // // regex for mandatory letter and number
      // /^(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9]{8,20})$/;
      // // regex for mandatory letter, one uppercase , number and special character
      // /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
      if (!values.password.match(alphanumerics)) {
        errors.msg = "Please enter a valid password";
        errors.password = "Yes";
      }
    }
    return errors;
  };

  return (
    <Container>
      <Row className="justify-content-center gy-0 mb-6rem">
        <Col md="18" lg="14" xl="12" xxl="10">
          <ToastBlockContainer
            successToast={successToast}
            showToast={showToast}
            setShowToast={setShowToast}
            toastMessage={toastMessage}
          />
          <Card className="prelogin-card signup">
            <Card.Body className="">
              <Form className="login-form" onSubmit={handleSubmit}>
                {/* 1st row */}
                <Row className="mb-0">
                  <h1 className="mb-1 title-heading">
                    Create an account on Elderly Life
                  </h1>
                  <span className="mb-1 login-boarder"></span>

                  <p className="align-right">
                    <Link to="/" className="link-brd">
                      <span className="centre-link">
                        Already have an account? Login here
                      </span>
                    </Link>
                  </p>
                </Row>
                <Row>
                  <Col xxl="24">
                    {/* First Name  */}
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Label
                        className={formErrors.firstname ? "invalid-text" : ""}
                      >
                        First name
                      </Form.Label>
                      <Medium
                        type="text"
                        errorClass={
                          formErrors.firstname ? "invalid-border" : ""
                        }
                        placeholder="John"
                        size="medium"
                        name="firstname"
                        value={formValues.firstname}
                        className={`form-control ${
                          formErrors.firstname ? "invalid-border" : ""
                        }`}
                        onChange={handleChange}
                        //(event) => this.handleUserInput(event)
                      />
                      <Form.Text
                        className={formErrors.firstname ? "text-danger" : " "}
                      >
                        This will be used as First name
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col xxl="24">
                    {/* Last Name  */}
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Label
                        className={formErrors.lastname ? "invalid-text" : ""}
                      >
                        Last name
                      </Form.Label>
                      <Medium
                        type="text"
                        errorClass={formErrors.lastname ? "invalid-input" : ""}
                        placeholder="Walker"
                        size="medium"
                        name="lastname"
                        className={`form-control  ${
                          formErrors.lastname ? "invalid-border" : ""
                        }`}
                        value={formValues.lastname}
                        onChange={handleChange}
                      />
                      <Form.Text
                        className={formErrors.lastname ? "text-danger" : " "}
                      >
                        This will be used as Last name
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xxl="24">
                    {/* Email  */}
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                      <Form.Label
                        className={formErrors.email ? "invalid-text" : ""}
                      >
                        Email
                      </Form.Label>
                      {/* <Form.Control type="email" placeholder="John@gmail.com" /> */}
                      <Medium
                        type="email"
                        errorClass={formErrors.email ? "invalid-input" : ""}
                        placeholder="John@gmail.com"
                        size="medium"
                        className={`form-control ${
                          formErrors.email ? "invalid-border" : ""
                        }`}
                        name="email"
                        value={formValues.email}
                        onChange={handleChange}
                      />
                      <Form.Text
                        className={formErrors.email ? "text-danger" : " "}
                      >
                        This will be used as Email
                      </Form.Text>
                    </Form.Group>
                  </Col>

                  <Col xxl="24">
                    {/* create password  */}
                    <Form.Group className="mb-4" controlId="formBasicPassword">
                      <Form.Label
                        className={formErrors.password ? "invalid-text" : ""}
                      >
                        Create Password
                      </Form.Label>
                      <InputGroup
                        className={
                          formErrors.password ? "invalid-border br-12" : ""
                        }
                      >
                        <FormControl
                          type={passwordShown ? "text" : "password"}
                          className={formErrors.password ? "invalid-input" : ""}
                          placeholder="Password"
                          size="medium"
                          name="password"
                          value={formValues.password}
                          onChange={handleChange}
                        />
                        <InputGroup.Text
                          className={
                            formErrors.password ? "invalid-input" : "pwd-icon"
                          }
                        >
                          <i
                            className={`fa ${
                              passwordShown ? "fa-eye" : "fa-eye-slash"
                            } password-icon cursor-pointer`}
                            onClick={togglePassword}
                          />
                        </InputGroup.Text>
                      </InputGroup>
                      <Form.Text
                        className={formErrors.password ? "text-danger" : " "}
                      >
                        Your password should be of minimum 8 characters and can
                        contain alphanumerics
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>

                {/* contactNumber */}
                {/* <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label
                    className={formErrors.contactNumber ? "invalid-text" : ""}
                  >
                    Contact Number
                  </Form.Label>
                  <PhoneInput
                    country="gb"
                    value={phoneNum}
                    onChange={handleChangePhone}
                    countryCodeEditable={false}
                  />
                  <Form.Text
                    className={formErrors.contactNumber ? "invalid-text" : " "}
                  >
                    If you are a main or support user, reminders will be sent to
                    this number.
                  </Form.Text>
                </Form.Group> */}

                {/* confirm password */}
                {/* <Form.Group className="mb-4" controlId="formBasicPassword">
                  <Form.Label
                    className={formErrors.confirmPassword ? "invalid-text" : ""}
                  >
                    Confirm Password
                  </Form.Label>
                  <InputGroup className="">
                    <FormControl
                      type={cmPasswordShown ? "text" : "password"}
                      className={
                        formErrors.confirmPassword ? "invalid-input" : ""
                      }
                      placeholder="Confirm Password"
                      size="medium"
                      name="confirmPassword"
                      value={formValues.confirmPassword}
                      onChange={handleChange}
                    />
                    <InputGroup.Text
                      className={
                        formErrors.confirmPassword
                          ? "invalid-input"
                          : "pwd-icon"
                      }
                    >
                      <i
                        className={`fa ${
                          cmPasswordShown ? "fa-eye" : "fa-eye-slash"
                        } password-icon`}
                        onClick={toggleCmPassword}
                      />
                    </InputGroup.Text>
                  </InputGroup>
                  <Form.Text
                    className={
                      formErrors.confirmPassword ? "invalid-text" : "text-muted"
                    }
                  >
                    Please retype the same password you've entered before
                  </Form.Text>
                </Form.Group> */}

                <div>
                  <p className="text-small">
                    By proceeding further you accept our
                    <a
                      href="https://elderlylife.com/terms-and-conditions"
                      target="_blank"
                      style={{ margin: "0 0.25rem" }}
                      rel="noreferrer"
                    >
                      Terms and conditions
                    </a>
                    and
                    <a
                      href="https://elderlylife.com/privacy-policy"
                      target="_blank"
                      style={{ margin: "0 0.25rem" }}
                      rel="noreferrer"
                    >
                      Privacy policy
                    </a>
                  </p>

                  <Large
                    className="btn btn-primary w-100"
                    children="Sign Up"
                    size="largebtn"
                    variant="primary"
                    type=""
                    buttonWidth="80%"
                    font_Weight="600"
                    isLoading={loading ? "true" : "false"}
                    isDisabled={loading ? "true" : "false"}
                  />
                  {/* {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )} */}
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* verify email model */}
      <Modal
        show={showVerificationModel}
        onHide={handleShowVerificationModelClose}
        className="verification_modal"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="verifictaion-success-msg">
            <Row>
              <Col md="1" lg="1">
                <Image
                  className="hello-text-img my-0"
                  src={SuccessCheck}
                  alt="Check mark"
                  width="40px"
                />
              </Col>
              <Col md="10" lg="10" className="ml-3">
                <span>
                  Hurray!! Your Elderly Life account has been successfully
                  created
                </span>
              </Col>
            </Row>
          </div>
          <p className="my-4">
            <strong>
              We have sent a 6 digit verification code to your email.
            </strong>
          </p>
          <Form
            className="verification-form"
            onSubmit={handleVerificationSubmit}
          >
            <p className="mb-2">Verification Code</p>
            <ReactCodeInput
              value={formCodeValues}
              name="code"
              fields={6}
              onChange={handleVerificationChange}
              className={formCodeErrors.code ? "invalid-text" : ""}
            />
            <input type="hidden" name="regUser" value={regUserUUID} />

            <div className="align-center pt-4">
              <Secondary
                children="Submit Code"
                size="mediumbtn"
                variant="secondary"
                type=""
                buttonWidth="260px"
                font_Weight="600"
                isLoading={loadingCode ? "true" : "false"}
                isDisabled={loadingCode ? "true" : "false"}
              />
            </div>
          </Form>
          <div className="align-center py-3">
            <Link to="/">
              <Info
                children="Skip and Login"
                size="mediumbtn"
                variant="info"
                type=""
                buttonWidth="260px"
                font_Weight="600"
              />
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default SignUp;
