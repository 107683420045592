// common toast messages block
import React, { useRef } from "react";
import Toast from "react-bootstrap/Toast";

const ToastBlockContainer = ({
  successToast,
  showToast,
  setShowToast,
  toastMessage,
}) => {
  const myRef = useRef(null);
  return (
    <>
      <div
        className={`${
          successToast ? "toast-div" : "toast-div error"
        } mb-4 mb-md-6`}
        ref={myRef}
      >
        <Toast
          className="w-100"
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
        >
          <Toast.Body className="d-flex align-items-center">
            <i
              className={`fa-solid ${
                successToast
                  ? "fa-circle-check text-success"
                  : "fa-circle-check text-danger"
              } fa-2xl`}
            ></i>
            <div className="ms-3">
              <div className="h6 mb-1">
                {successToast ? "Success!" : "Error!"}
              </div>
              <p className="mb-0">{toastMessage}</p>
            </div>
          </Toast.Body>
        </Toast>
      </div>
    </>
  );
};

export default ToastBlockContainer;
