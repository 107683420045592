import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { BenefitsCardVal } from "../../stories/BenefitsCard.stories";
import Loading from "../../common/Loading";

const FamilyDashboardBenefits = (props) => {
  let { uuid } = useParams();
  const [loadingspinner, setLoadingspinner] = useState(true);

  useEffect(() => {
    if (props.allData) {
      setLoadingspinner(false);
    }
  }, [props.allData]);

  return (
    <>
      <div className="mb-3 d-flex">
        <h2 className="me-auto mb-0">Discounts</h2>
        <Link
          className="btn btn-white btn-sm btn-circle flex-shrink-0"
          to={uuid ? `/discounts/${uuid}` : "#"}
        >
          <i className="fa-solid fa-sm fa-angle-right text-dark"></i>
        </Link>
      </div>
      {loadingspinner ? (
        <>
          <div className="loadind-page">
            <Loading />
          </div>
        </>
      ) : (
        <>
          {props.allData.discountsData.length === 0 ? (
            <p className="small mb-0">You currently have no discounts!</p>
          ) : (
            <>
              {/* Temp showing one - need to review */}
              {props.allData.discountsData.map(
                ({
                  benefitText,
                  id,
                  benefitUrl,
                  logoImg,
                  bannerImg,
                  ctaText,
                }) => (
                  <>
                    <hr className="my-3" />
                    <BenefitsCardVal
                      key={id}
                      variant="basic"
                      benefitTitle={`${benefitText}`}
                      logo={`${logoImg}`}
                      banner={`${bannerImg}`}
                      link={`${benefitUrl}`}
                      linkText={`${ctaText}`}
                      propClassName="card-unstyled"
                    />
                  </>
                )
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default FamilyDashboardBenefits;
