// (Authentication service) - signup / signin etc
import axios from "axios";
import Cookies from "universal-cookie";
// const API_URL = "https://devapi.elderlylife.co.uk/"; // new development api
const API_URL = "https://developmentapi.elderlylife.com/"; // development api
const cookies = new Cookies();

class AuthService {
  // user login
  login(data) {
    return axios.post(API_URL + "login", data).then((response) => {
      if (response.data.accessToken) {
        var entry = {
          message: response.data.message,
          uuid: response.data.uuid,
        };
        localStorage.setItem("UserDetails", JSON.stringify(entry));
        // set access token in cookies
        cookies.set("accessToken", response.data.accessToken, {
          path: "/",
          // secure: true,
        });
      }
      return response;
    });
  }
  // user logout
  logout() {
    localStorage.removeItem("UserDetails");
    localStorage.removeItem("RedFamDash");
    localStorage.removeItem("userType");

    window.location.href = "/";
  }
  // user register
  register(data) {
    return axios
      .post(API_URL + "register", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }
  // verify user email code
  verifyUserEmail(data, userUUID) {
    return axios
      .put(API_URL + `verify/email/${userUUID}`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }
  // Check user email - forget password
  checkEmail(data) {
    return axios
      .post(API_URL + "profile/checkEmail", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }
  // verify user 6 digit code - forget password
  verifySixDigitCode(data, userUUID) {
    return axios
      .post(API_URL + `profile/verifyCode/${userUUID}`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }
  // update user password - forget password
  updateUserPassword(data, userUUID) {
    return axios
      .patch(API_URL + `profile/resetPassword/${userUUID}`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }
  getCurrentUser() {
    return JSON.parse(localStorage.getItem("UserDetails"));
  }
}

export default new AuthService();
