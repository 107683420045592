import React from "react";
import { Card } from "react-bootstrap";

function InvitationCards(props) {
  const {
    userRole,
    variant,
    familyName,
    invitedBy,
    acceptClick,
    rejectClick,
    ...rest
  } = props;
  return (
    <div {...rest}>
      <Card
        className={`listing-cards ${variant}-cards`}
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <Card.Body className="p-3">
          <div className="">
            <div>
              <p className="card-title fw-bold mb-1">{familyName}</p>
              {/* check  */}
              <div className="d-flex flex-column mb-4">
                <p className="card-subtitle">
                  as a <strong>{userRole}</strong>
                </p>
                <p className="card-smallText mb-2">
                  Invited by :{" "}
                  <strong>
                    {invitedBy === "null null" ? "Dear User" : invitedBy}
                  </strong>
                </p>
              </div>
            </div>

            <div className="d-flex align-items-center gap-2">
              <span className="action-right">
                <button
                  className="btn btn-primary"
                  name="accept"
                  onClick={acceptClick}
                  style={{ width: "9rem" }}
                >
                  Accept
                </button>
              </span>
              <span className="action-right">
                <button
                  className="btn btn-danger"
                  name="reject"
                  onClick={rejectClick}
                  style={{ width: "9rem" }}
                >
                  Reject
                </button>
              </span>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default InvitationCards;
