import React from "react";
import { Card } from "react-bootstrap";

function SavedDebitCard(props) {
  const {
    last4digit,
    userName,
    exp_month,
    exp_year,
    brand,
    variant,
    inputValue,
    buttonClick,
    ...rest
  } = props;
  return (
    <div>
      <Card
        className={`story-card debit-card ${variant}-cards p-3`}
        style={{ boxShadow: "rgba(0, 123, 255, 0.35) 0px 5px 15px" }}
      >
        <Card.Body className="p-1">
          <div>
            <div className="d-flex align-items-center gap-2">
              <span>
                <input
                  type="radio"
                  name="paymentMethod_id"
                  {...rest}
                  className="card-input-element"
                  style={{ cursor: "pointer" }}
                />
              </span>
              <h3>{brand}</h3>
            </div>

            <h3>**** **** **** {last4digit}</h3>
            <div className="d-flex align-items-center justify-content-between">
              <h4>{userName}</h4>
              <h4>
                Expires on {exp_month}/{exp_year}
              </h4>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
export default SavedDebitCard;
