import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Loading from "../../common/Loading";
import userService from "../../services/user.service";
import moment from "moment";
import { useHistory } from "react-router-dom";

const FamilyDashboardSup = (props) => {
  const UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  let { uuid } = useParams();
  let history = useHistory();
  let splitUrl = window.location.href.split("/");
  const [loading, setLoading] = useState(true);
  const [allData, setallData] = useState();
  const [mainData, setmainData] = useState([]);
  const [suppData, setsuppData] = useState([]);

  useEffect(() => {
    // get family details
    userService.relationData(uuid).then(
      (response) => {
        console.log(response);
        setallData(response.data);

        // main user checking
        const mainUser = response.data.au.find(
          (obj) => obj.User.email === UserDetails.email
        );
        console.log(mainUser);
        const mainArr = [];
        if (mainUser) {
          mainArr.push(mainUser);
          setmainData(mainArr);
        }

        // support user checking
        const suppUser = response.data.au.find((obj) =>
          obj.su.find(
            (family) =>
              family.Family_User &&
              family.Family_User.User.email === UserDetails.email
          )
        );
        console.log(suppUser);
        const suppArr = [];
        if (suppUser) {
          suppArr.push(suppUser);
          setsuppData(suppArr);
        }

        console.log(mainArr.length, suppArr.length);

        if (mainArr.length === 0 && suppArr.length === 0) {
          history.push(`/dashboard/${uuid}`);
        }
        setTimeout(() => {
          setLoading(false);
        }, 250);
      },
      (error) => {
        setTimeout(() => {
          setLoading(false);
        }, 250);
      }
    );
  }, []);

  console.log(allData);
  console.log(mainData);
  console.log(suppData);

  return (
    <Container
      fluid
      style={{ minHeight: "calc(100vh - 17rem)", marginBottom: "6rem" }}
      className="d-flex flex-column"
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          <Row>
            <div className="mb-5">
              <div className="family-invitation-leftSection mb-4">
                <h2 className="title-heading">
                  Users you are supporting in this group
                </h2>
              </div>
              <Row
                className="pl-0 pr-4 fmly-div card-scroll-element mb-4"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {suppData.length !== 0 ? (
                  suppData &&
                  suppData.map((data, index) => (
                    <Col xxl="8" xl="12" lg="12" key={index}>
                      <section className="bg-white p-4  shadow rounded-4">
                        <div class="d-flex align-items-center gap-2 justify-content-between">
                          <div class="d-flex align-items-center">
                            <div class="float-left mx-2">
                              <img
                                src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                alt="Profile Avatar"
                                width="50px"
                                height="50px"
                              />
                            </div>
                            <div class="word-break">
                              <div>
                                {data && data.User.firstName}{" "}
                                {data && data.User.lastName}
                              </div>
                              <div>
                                <div class="PU-dsn-txt-1">
                                  {data && data.User.email}
                                </div>
                              </div>
                            </div>
                          </div>

                          <Link
                            to={`/supporting_user_dashboard/${splitUrl[4]}/${
                              data && data.User.uuid
                            }`}
                            className="btn btn-primary w-fit"
                          >
                            <span className="">Login</span>
                          </Link>
                        </div>
                        <div
                          className="mt-3 fs-14"
                          style={{ marginLeft: "4.4rem" }}
                        >
                          Supporting since{" "}
                          <strong>
                            {moment(
                              data &&
                                data.su.find(
                                  (obj) =>
                                    obj.Family_User.User.email ===
                                    UserDetails.email
                                ).createdAt
                            ).format("Do MMM, YYYY")}
                          </strong>
                        </div>
                      </section>
                    </Col>
                  ))
                ) : (
                  <div>There is no data available</div>
                )}
              </Row>
            </div>
          </Row>

          {mainData.length !== 0 && (
            <Row>
              <div className="">
                <div className="family-invitation-leftSection mb-4">
                  <h2 className="title-heading">
                    Access this group as a Main user
                  </h2>
                </div>

                <Row
                  className="pl-0 pr-4 fmly-div card-scroll-element mb-4"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  {mainData &&
                    mainData.map((data, index) => (
                      <Col xxl="8" xl="12" lg="12" key={index}>
                        <section className="bg-white p-4  shadow rounded-4">
                          <div class="d-flex align-items-center gap-2 justify-content-between">
                            <div class="d-flex align-items-center">
                              <div class="float-left mx-2">
                                <img
                                  src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                                  alt="Profile Avatar"
                                  width="50px"
                                  height="50px"
                                />
                              </div>
                              <div class="word-break">
                                <div>
                                  {data && data.User.firstName}{" "}
                                  {data && data.User.lastName}
                                </div>
                                <div>
                                  <div class="PU-dsn-txt-1">
                                    {data && data.User.email}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <Link
                              to={`/supporting_user_dashboard/${splitUrl[4]}/${
                                data && data.User.uuid
                              }`}
                              className="btn btn-primary w-fit"
                            >
                              <span className="">Login</span>
                            </Link>
                          </div>
                        </section>
                      </Col>
                    ))}
                </Row>
              </div>
            </Row>
          )}
        </>
      )}
    </Container>
  );
};

export default FamilyDashboardSup;
