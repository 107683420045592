import React from "react";
import { Image } from "react-bootstrap";
import DefaultProfileAvatar from "../../assets/images/avatar.svg";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteIcon from "../../assets/images/Delete.svg";

function SeatCard(props) {
  const {
    heading,
    subheading,
    ProfileAvatar,
    rightImg,
    leftImg,
    isEmpty,
    memberHeading,
    memberSubheading,
    memberStatus,
    variant,
    dropdownItem1,
    dropdownItem2,
    dropdownItem3,
    onClickItem1,
    onClickItem2,
    onClickItem3,
    onClickEdit,
    ...rest
  } = props;

  return (
    <div className="d-flex align-items-center gap-2">
      <div className="">
        <Image
          src={ProfileAvatar ? ProfileAvatar : DefaultProfileAvatar}
          alt="Profile Avatar"
          style={{
            width: "2.5rem",
            height: "2.5rem",
          }}
        />
      </div>

      <div className="flex-2 word-break">
        <div className="PU-dsn-txt d-flex flex-column">
          <span>{memberHeading}</span>
          <span>{memberSubheading}</span>
        </div>
      </div>

      <div className="text-dsgn ms-auto d-flex justify-content-end align-items-center ">
        {isEmpty === "false" ? (
          <small className="status-txt">{memberStatus}</small>
        ) : (
          ""
        )}
        <div className="famDash-editDlt">
          {/* {isEmpty === "false" ? (
              <img
                src={EditIcon}
                alt="EditIcon"
                onClick={onClickEdit}
                style={{ cursor: "pointer" }}
              />
            ) : (
              ""
            )} */}

          {isEmpty === "false" ? (
            <span>
              <Dropdown className="profile-dropdwn">
                <Dropdown.Toggle
                  variant=""
                  className="m-0 user-dropdown"
                  id=""
                  style={{ paddingLeft: "0" }}
                >
                  {props.data && props.data === "primary" ? (
                    ""
                  ) : (
                    <Image
                      className="m-1 vertical-dot-icon"
                      src={DeleteIcon}
                      alt="DeleteIcon"
                    />
                  )}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {dropdownItem1 ? (
                    <Dropdown.Item onClick={onClickItem1}>
                      {dropdownItem1}
                    </Dropdown.Item>
                  ) : (
                    ""
                  )}
                  {dropdownItem2 ? (
                    <Dropdown.Item onClick={onClickItem2}>
                      {dropdownItem2}
                    </Dropdown.Item>
                  ) : (
                    ""
                  )}
                  {dropdownItem3 ? (
                    <Dropdown.Item onClick={onClickItem3}>
                      {dropdownItem3}
                    </Dropdown.Item>
                  ) : (
                    ""
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default SeatCard;
export const clickFn = () => {};
