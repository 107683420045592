import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Primary } from "../../stories/Button.stories";
import notFoundIcon from "../../assets/images/404.png";
import Loading from "../../common/Loading";

const NotFound = () => {
  const [loader, setloader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setloader(false);
    }, 500);
  }, []);

  return (
    <div className="page-content view-warranty-pg">
      <Container
        className="d-block d-md-flex align-items-center margin-bottom-15"
        style={{ height: "calc(100vh - 200px)", width: "fit-content" }}
      >
        {loader ? (
          <Loading />
        ) : (
          <Row className="align-items-center not-found">
            {/* Left Column for Image */}
            <Col md={12} className="text-center d-none d-md-block">
              <Image
                src={notFoundIcon}
                alt="Not found"
                fluid
                style={{ maxHeight: "27.5rem" }}
              />
            </Col>

            {/* Right Column for Text and Button */}
            <Col md={12} className="text-center">
              <div className="">
                <Image
                  src={notFoundIcon}
                  alt="Not found"
                  fluid
                  className="d-flex d-md-none m-auto mb-3"
                  style={{ width: "12.5rem" }}
                />
                <h1
                  style={{
                    fontSize: "2.5rem",
                    fontWeight: "700",
                    color: "#333",
                  }}
                >
                  Oops! Page Not Found
                </h1>
                <p
                  style={{
                    fontSize: "1.2rem",
                    color: "#666",
                    margin: "20px 0",
                  }}
                >
                  The page you are looking for doesn’t exist or an error
                  occurred.
                </p>
                <Link to="/family_selection">
                  <Primary
                    children="Go to Dashboard"
                    size="largebtn"
                    variant="primary"
                    type=""
                    font_Weight="500"
                    className="btn btn-primary mb-7"
                  />
                </Link>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};
export default NotFound;
