import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import moment from "moment";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import Select from "react-select";

// select error style
const customStylesError = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    backgroundColor: "#ecf6ff 0% 0% no-repeat padding-box",
    fontSize: "16px",
    borderRadius: " 0.75rem",
    borderStyle: "none",
    cursor: "pointer",
    padding: "2px 0",
    border: "0.0625rem solid red",
    marginBottom: "4px",
    color: "#475569",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "0.75rem",
    backgroundColor: "#fff",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    zIndex: 10,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "4px 0",
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "0.5rem",
    fontSize: "16px",
    width: "100%",
    cursor: "pointer",
    color: "#475569",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#475569",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#9ca3af",
    fontSize: "16px",
    fontStyle: "italic",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "#007bff" : "#9ca3af",
    "&:hover": {
      color: "#007bff",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "#dfe8f1",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: "#9ca3af",
    "&:hover": {
      color: "#007bff",
    },
  }),
};

// select style
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    backgroundColor: "#ecf6ff 0% 0% no-repeat padding-box",
    fontSize: "16px",
    borderRadius: " 0.75rem",
    borderStyle: "none",
    cursor: "pointer",
    padding: "2px 0",
    border: "0.0625rem solid #aabccf",
    marginBottom: "4px",
    color: "#475569",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "0.75rem",
    backgroundColor: "#fff",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    zIndex: 10,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "4px 0",
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "0.5rem",
    fontSize: "16px",
    width: "100%",
    cursor: "pointer",
    color: "#475569",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#475569",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#9ca3af",
    fontSize: "16px",
    fontStyle: "italic",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "#007bff" : "#9ca3af",
    "&:hover": {
      color: "#007bff",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "#dfe8f1",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: "#9ca3af",
    "&:hover": {
      color: "#007bff",
    },
  }),
};

const TasksAdd = (props) => {
  let history = useHistory();
  const myRef = useRef(null);
  const [familyDetails, setFamilyDetails] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [userCreateUpdate, setUserCreateUpdate] = useState();
  const [userCreateUpdateFor, setUserCreateUpdateFor] = useState();
  const [selectedTenure, setSelectedTenure] = useState();
  const [taskUrl, setTaskUrl] = useState("");
  const [backText, setBackText] = useState("Back to My To Do's");
  const executeScroll = () => myRef.current.scrollIntoView();
  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };
  const UserDetails = JSON.parse(localStorage.getItem("UserDetails"));

  const initialValues = {
    task_name: "",
    task_details: "",
    eta_date: "",
    task_frequency: "",
    assignee: "",
    endDate: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [selectedAssignee, setSelectedAssignee] = useState({
    value: "",
    label: "Select an assignee",
  });

  const initialFrequencyOption = [
    { value: "Daily", label: "Daily" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "Quarterly", label: "Quarterly" },
    { value: "Bi-Annually", label: "Bi-Annually" },
    { value: "Yearly", label: "Yearly" },
  ];
  const [frequencyOption, setFrequencyOption] = useState(
    initialFrequencyOption
  );

  const initialAssigneeOption = [{ value: "", label: "Select an assignee" }];
  const [assigneeOption, setAssigneeOption] = useState(initialAssigneeOption);
  let { uuid, userUUID } = useParams();

  var userType = localStorage.getItem("userType");

  const handleChange = (e, action) => {
    let targetData = {};
    if (action) {
      if (action.name === "assignee") {
        setSelectedAssignee(e);
      }
      if (action.name === "task_frequency") {
        setSelectedTenure(e);
      }
      targetData = {
        name: action.name,
        value: e.value,
      };
    } else {
      targetData = {
        name: e.target.name,
        value: e.target.value,
      };
    }
    const { name, value } = targetData;
    setFormValues({ ...formValues, [name]: value });
  };

  // handle submit
  const handleSubmit = (e) => {
    e.preventDefault();

    setFormErrors(validate(formValues));
    if (Object.keys(formErrors).length !== 0) {
    }
    setIsSubmit(true);
    setLoading(true);
  };
  // validation
  const validate = (values) => {
    const errors = {};
    if (!values.task_name) {
      errors.msg = "Please enter the to do name";
      errors.task_name = "Yes";
    }
    if (!values.eta_date) {
      errors.msg = "Please enter the date";
      errors.eta_date = "Yes";
    }
    if (!selectedAssignee.value) {
      errors.msg = "Please enter the assignee";
      errors.assignee = "Yes";
    }
    if (isSwitchOn && !values.task_frequency) {
      errors.msg = "Please enter the activity frequency";
      errors.task_frequency = "Yes";
    }
    if (isSwitchOn && !values.endDate) {
      errors.msg = "Please enter the number of occurrences";
      errors.endDate = "Yes";
    }
    return errors;
  };

  const getFamilyDetails = (userId) => {
    // get family details
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);
        setUserCreateUpdateFor(response.FamilyData.id);

        // SU Family details
        UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
          (suResponse) => {
            setUserCreateUpdate(suResponse.FamilyData.id);
            // get list of Su user
            UserService.getOnBoardedFamilyUserList(uuid, userId, "AU").then(
              (userlistres) => {
                if (
                  userType === "AO + AU" ||
                  userType === "AO + AU + SU" ||
                  userType === "AU" ||
                  userType === "AU + SU"
                ) {
                  const optionArr = [
                    { value: suResponse.FamilyData.id, label: "Self" },
                  ];

                  if (userlistres.usersData.length !== 0) {
                    for (var i = 0; i < userlistres.usersData.length; i++) {
                      if (userlistres.usersData[i].Family_User.isAu === true) {
                        optionArr.push({
                          value: response.FamilyData.id,
                          label: "To Main user",
                        });
                      }
                    }

                    setAssigneeOption(optionArr);
                  } else {
                    setAssigneeOption(optionArr);
                  }
                } else if (userType === "SU" || userType === "AO + SU") {
                  const optionArr = [
                    { value: suResponse.FamilyData.id, label: "Self" },
                    { value: response.FamilyData.id, label: "To Main user" },
                  ];
                  setAssigneeOption(optionArr);
                }
              }
            );
          },
          (error) => {}
        );
      },
      (error) => {}
    );
  };

  const getAUFamilyDetails = (userId) => {
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);
        setUserCreateUpdate(response.FamilyData.id);
        setUserCreateUpdateFor(response.FamilyData.id);

        // get list of Su user
        UserService.getOnBoardedFamilyUserList(uuid, userId, "AU").then(
          (userlistres) => {
            if (
              userType === "AO + AU" ||
              userType === "AO + AU + SU" ||
              userType === "AU" ||
              userType === "AU + SU"
            ) {
              var arr1 = [{ value: response.FamilyData.id, label: "Self" }];
              var arr2 = [];

              if (userlistres.usersData.length != 0) {
                userlistres.usersData.map((v, index) =>
                  v.Family_User.inviteStatus != "Removed"
                    ? arr2.push({
                        value: v.FamilyUserId,
                        label:
                          v.Family_User.User.firstName === "null"
                            ? v.Family_User.User.email
                            : v.Family_User.User.firstName +
                              " " +
                              v.Family_User.User.lastName,
                      })
                    : ""
                );
                var optionArr = [...arr1, ...arr2];

                setAssigneeOption(optionArr);
              } else {
                setAssigneeOption(arr1);
              }
            }
          }
        );
      },
      (error) => {}
    );
  };

  useEffect(() => {
    if (userUUID) {
      UserService.userProfile(userUUID).then((response) => {
        getFamilyDetails(response.userData.id);
        setTaskUrl("/user_todos/" + uuid + "/" + userUUID);
        setBackText(
          response.userData.firstName === "null"
            ? response.userData.email
            : response.userData.firstName + " " + response.userData.lastName ===
              "null"
            ? ""
            : response.userData.firstName
        );
      });
    } else {
      getAUFamilyDetails(UserDetails.id);
      setTaskUrl("/todos/" + uuid);
    }

    // add task
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setLoading(true);

      let data = {
        name: formValues.task_name,
        details: formValues.task_details,
        eta: formValues.eta_date ? formValues.eta_date : null,
        taskRecurrence: isSwitchOn ? true : false,
        taskFrequency: formValues.task_frequency
          ? formValues.task_frequency
          : null,
        endDate: formValues.endDate ? formValues.endDate : null,
        assigneeId: selectedAssignee.value, //FamilyUser's Id give
        createdBy: userCreateUpdate, //FamilyUser's Id give
        updatedBy: userCreateUpdate, //FamilyUser's Id give
        FamilyId: familyDetails.FamilyData.FamilyId,
        FamilyUserId: familyDetails.FamilyData.id,
      };

      if (
        isSwitchOn === true &&
        (formValues.task_frequency === "" || formValues.endDate === "")
      ) {
        setLoading(false);
        setSuccessToast(false);
        setShowToast(true);
        executeScroll();
        window.scrollTo(0, 0);
        setToastMessage("Please fill Activity Reccurency field");
      } else {
        // api call
        UserService.createTask(data).then(
          (response) => {
            setLoading(false);
            setSuccessToast(true);
            setShowToast(true);
            executeScroll();
            window.scrollTo(0, 0);
            setToastMessage("To Do created successfully");
            setTimeout(() => {
              history.push(taskUrl);
            }, 2000);
          },
          (error) => {}
        );

        // end api call
        setFrequencyOption(initialFrequencyOption);
        // setIsSwitchOn(true);
      }
    } else {
      setLoading(false);
    }
  }, [formErrors]);

  return (
    <Container ref={myRef}>
      <ToastBlockContainer
        successToast={successToast}
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <Row className="justify-content-center gy-0 mb-6rem row">
        <Col xl="21" xxl="18">
          <h1 className="lh-base mb-4">Add a new to-do</h1>
          <section className="bg-white p-4 p-md-6 p-lg-8 shadow rounded-4">
            <Form onSubmit={handleSubmit} encType="multipart/form-data">
              <h2 className="mb-1 mb-md-2">Details</h2>
              <p className="mb-4">
                Add your To-Dos - anything that needs doing, schedule when and
                how often, and assign tasks to anyone in your group​
              </p>
              <Row className="gy-0 mb-4">
                <Col md="12" lg="12">
                  <Form.Label
                    className={formErrors.task_name ? "text-danger" : " "}
                  >
                    Name
                  </Form.Label>
                  <Form.Control
                    className={formErrors.task_name ? "is-invalid" : ""}
                    type="text"
                    placeholder="e.g. Pick up my prescriptions​"
                    name="task_name"
                    value={formValues.task_name}
                    onChange={handleChange}
                    maxLength={255}
                  />
                  <Form.Text
                    className={formErrors.task_name ? "text-danger" : " "}
                  >
                    Please enter the todo name
                  </Form.Text>
                </Col>
              </Row>
              <Row className="gy-0 mb-4">
                <Col xs="24">
                  <Form.Label>
                    Description
                    <span className="small text-muted fw-normal ms-1">
                      Optional
                    </span>
                  </Form.Label>
                  <Form.Control
                    className={formErrors.task_details ? "is-invalid" : ""}
                    as="textarea"
                    rows={4}
                    cols={5}
                    name="task_details"
                    value={formValues.task_details}
                    onChange={handleChange}
                    placeholder="e.g. Remember to pick up my prescriptions​"
                    maxLength={255}
                  />
                  <Form.Text>
                    What you intend to do or what you need a support user to do
                    for you.
                  </Form.Text>
                </Col>
              </Row>
              <Row className="gy-0 mb-4">
                <Col xs="16" md="10" lg="8">
                  <Form.Label
                    className={formErrors.eta_date ? "text-danger" : " "}
                  >
                    Date
                  </Form.Label>
                  <InputGroup
                    className={formErrors.eta_date ? "is-invalid" : ""}
                  >
                    <InputGroup.Text id="basic-addon1">
                      <i className="fa-solid fa-calendar fa-sm"></i>
                    </InputGroup.Text>
                    <Form.Control
                      className={
                        formErrors.eta_date
                          ? "is-invalid invalid-border-right"
                          : ""
                      }
                      type="date"
                      placeholder="e.g. 20/10/2022"
                      name="eta_date"
                      min={moment(new Date()).format("YYYY-MM-DD")}
                      value={formValues.eta_date}
                      onChange={handleChange}
                    />
                  </InputGroup>
                  <Form.Text
                    className={formErrors.eta_date ? "text-danger" : " "}
                  >
                    Please select the date
                  </Form.Text>
                </Col>
              </Row>
              <Row className="gy-0 mb-4">
                <Col md="24">
                  <Form.Label className="mb-0">Recurring</Form.Label>
                  <p className="mb-2">
                    Do you wish for this to-do to be recurring?
                  </p>
                  <Form.Switch
                    className={formErrors.date ? "is-invalid" : ""}
                    onChange={onSwitchAction}
                    checked={isSwitchOn}
                  />
                </Col>
              </Row>
              {isSwitchOn && (
                <Row className="gy-4 gx-md-4 gy-md-4 mb-4">
                  <Col xs="16" md="10" lg="8">
                    <Form.Label
                      className={
                        formErrors.task_frequency ? "text-danger" : " "
                      }
                    >
                      Frequency
                    </Form.Label>

                    <Select
                      styles={
                        formErrors.task_frequency
                          ? customStylesError
                          : customStyles
                      }
                      options={frequencyOption}
                      defaultValue={{
                        label: "Select a frequency",
                        value: "",
                      }}
                      value={selectedTenure}
                      onChange={handleChange}
                      name="task_frequency"
                    />
                    <Form.Text
                      className={
                        formErrors.task_frequency ? "text-danger" : " "
                      }
                    >
                      Please select the frequency
                    </Form.Text>
                  </Col>
                  <Col xs="16" md="10" lg="8">
                    <Form.Label>Enddate </Form.Label>
                    <InputGroup
                      className={formErrors.endDate ? "is-invalid" : ""}
                    >
                      <InputGroup.Text id="basic-addon1">
                        <i className="fa-solid fa-calendar fa-sm"></i>
                      </InputGroup.Text>
                      <Form.Control
                        className={
                          formErrors.endDate
                            ? "is-invalid invalid-border-right"
                            : ""
                        }
                        type="date"
                        placeholder="e.g. 20/10/2022"
                        name="endDate"
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        value={formValues.endDate}
                        onChange={handleChange}
                      />
                    </InputGroup>
                    <Form.Text
                      className={formErrors.endDate ? "text-danger" : " "}
                    >
                      Please select the end date
                    </Form.Text>
                  </Col>
                </Row>
              )}
              <Row className="gy-0 mb-4">
                <Col md="16" lg="12">
                  <Form.Label
                    className={formErrors.assignee ? "text-danger" : " "}
                  >
                    Assignee
                  </Form.Label>

                  <Select
                    styles={
                      formErrors.assignee ? customStylesError : customStyles
                    }
                    options={assigneeOption}
                    defaultValue={{
                      label: "select an assignee",
                      value: "",
                    }}
                    value={selectedAssignee}
                    onChange={handleChange}
                    name="assignee"
                  />
                  <Form.Text
                    className={formErrors.assignee ? "text-danger" : " "}
                  >
                    Select an assignee – choose yourself or any member of your
                    group​
                  </Form.Text>
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <Button
                  className="d-flex align-items-center"
                  variant="primary"
                  type="submit"
                  isLoading={loading ? "true" : "false"}
                  isDisabled={loading ? "true" : "false"}
                >
                  <i className="fa-solid fa-sm fa-add lh-1 align-middle me-2"></i>
                  Add to-do
                </Button>
              </div>
            </Form>
          </section>
        </Col>
      </Row>
    </Container>
  );
};

export default TasksAdd;
