import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import "../src/Application.scss";
import routes from "./router";
import AuthService from "../src/services/auth.service";
import AuthVerify from "./common/auth-verify";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51JtZX7HPjiF3hUjm1aOz86cvckp5G25TAMYVNd7PhllJNmATW3YOFgaqWXFlozmosvwoynpajxsZwihjgYPqH2T700d1Z4vn2x"
);
const clientSecret =
  "sk_test_51JtZX7HPjiF3hUjmP2pqbDVpLtPlbBfyQdHn7DbppYUgoPTVqqF1X3zYxXvk5sMDwQFHISeZIP5nUHODaLiyGZlJ00uab37x9J";

function App() {
  let history = useHistory();
  const UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const logOut = () => {
    AuthService.logout();
    history.push("/");
  };

  useEffect(() => {
    if (UserDetails) {
      // Ensure the script is only loaded once
      const appId = "nedui962"; // Intercom app ID
      if (typeof window.Intercom === "function") {
        // Intercom already initialized
        window.Intercom("reattach_activator");
        window.Intercom("update", window.intercomSettings);
      } else {
        // Intercom not initialized yet, so load the script and initialize
        window.intercomSettings = {
          api_base: "https://api-iam.intercom.io",
          app_id: appId,
        };

        // Create and append the script to load Intercom
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src = `https://widget.intercom.io/widget/${appId}`;
        document.head.appendChild(script);

        // Once the script loads, initialize Intercom
        script.onload = () => {
          window.Intercom("boot", window.intercomSettings);
        };
      }

      // Cleanup on unmount
      return () => {
        const intercomScript = document.querySelector(
          `script[src="https://widget.intercom.io/widget/${appId}"]`
        );
        if (intercomScript) {
          document.head.removeChild(intercomScript);
        }
      };
    }
  }, [UserDetails]); // Run only once when the component mounts

  return (
    <>
      <Router>
        <Elements stripe={stripePromise}>
          <Switch>
            {routes.map((route) => (
              <Route
                exact
                key={route.path}
                path={route.path}
                render={(props) => (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                )}
              />
            ))}
          </Switch>
        </Elements>
        <AuthVerify logOut={logOut} />
      </Router>
    </>
  );
}

export default App;
