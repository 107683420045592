import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function AccountPlanCard({ uuid, cardDetails, currentPlanLoader }) {
  return (
    <Row className="m-0">
      <Col
        md="18"
        lg="14"
        xl="10"
        xxl="10"
        className="p-4 border rounded bg-white mb-5"
        style={{ boxShadow: "rgba(0, 123, 255, 0.35) 0px 5px 15px" }}
      >
        {!currentPlanLoader && (
          <div className="p-3">
            <div className="">
              <div className="mb-5">
                <h1 className="mb-3">{cardDetails.name}</h1>
                <div className="text-info fs-36 fw-bold">
                  {" "}
                  £{cardDetails.price}
                </div>
                <div className="fw-semibold bold-colour">
                  {cardDetails.renewalTyp}
                </div>
              </div>
              <p className="fw-semibold bold-colour mb-2">
                <span class="text-success me-2">&#10004;</span>
                {cardDetails.availableS === 1 ? (
                  <span>{cardDetails.availableS} Main user</span>
                ) : (
                  <span>{cardDetails.availableS} Main users</span>
                )}
              </p>
              <p className="fw-semibold bold-colour mb-2">
                <span class="text-success me-2">&#10004;</span>
                {cardDetails.availableS === 1 ? (
                  <span>{cardDetails.availableS} support user</span>
                ) : (
                  <span>{cardDetails.availableS} support users</span>
                )}
              </p>
              <p className="fw-semibold bold-colour mb-2">
                <span class="text-success me-2">&#10004;</span>
                Cancel any time
              </p>
              <p className="fw-semibold bold-colour mb-2">
                <span class="text-success me-2">&#10004;</span>
                No hidden costs
              </p>
            </div>
            <div className="mt-5">
              <Link to={`/downgradeSubscription/${uuid}`}>
                <button className="btn btn-primary">Change Plan</button>
              </Link>
            </div>
          </div>
        )}
        {currentPlanLoader && (
          <div>
            <div className="text-center">
              <button className="btn loading-btn p-0" type="button">
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                ></span>
                Loading Your Plan..
              </button>
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
}

export default AccountPlanCard;
