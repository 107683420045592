import React from 'react';
import Header from "../Header";
import Footer from "../Footer";

const PublicLayout = (props) => {

  return (
    <>
      <main className="main public-layout" role="main">
        <Header/>
        {props.children}
        <Footer />
      </main>
    </>
  );
}

export default PublicLayout;
